import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import './styles.scss'
import PopconfirmDelete from '../../../../components/Global/PopconfirmDelete'

export const ContactsListItem = ({ contactData, handleRemove }) => {
  return (
    <div className='user-item-block'>
      <data className='contact-data-cover'>
        <p className='user-item title'>
          {contactData.company ||
            `${contactData.firstName || ''} ${contactData.lastName || ''}`}
        </p>
        <p className='user-item-text'>{contactData.email}</p>
        <p className='user-item-text'>{contactData.phone}</p>
      </data>
      <PopconfirmDelete
        icon='delete'
        value=''
        onConfirmAction={() => handleRemove(contactData)}
      />
    </div>
  )
}

ContactsListItem.propTypes = {
  contactData: PropTypes.object,
  handleRemove: PropTypes.object
}

export default injectIntl(ContactsListItem)
