import React from 'react'
import { injectIntl } from 'react-intl'
import { Form, Input, InputNumber, Select } from 'antd'
import PropTypes from 'prop-types'

const EditableContext = React.createContext()

class EditableCell extends React.Component {
  handleChangeValue = value => {
    if (this.props.inputType === 'TextInput') {
      this.props.onEditingValueChange(value.target.value, this.props.record)
    } else {
      this.props.onEditingValueChange(value, this.props.record)
    }
  }

  renderCell = () => {
    const {
      editing,
      record,
      editingValue,
      children,
      inputType,
      ...restProps
    } = this.props

    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item style={{ margin: 0 }}>
            {inputType !== 'TextInput' && inputType !== 'Dropdown' && (
              <InputNumber
                type='number'
                value={editingValue}
                onChange={this.handleChangeValue}
              />
            )}

            {inputType === 'TextInput' && (
              <Input value={editingValue} onChange={this.handleChangeValue} />
            )}
            {inputType === 'Dropdown' && (
              <Select
                style={{ width: 100 }}
                // placeholder={props.intl.formatMessage({ id: 'select a value'})}
                onChange={this.handleChangeValue}
                value={record.options[Number(editingValue) - 1].text}
              >
                {record.options.map(option => (
                  <Select.Option key={option._id} value={option.value}>
                    {option.text}
                  </Select.Option>
                ))}
              </Select>
            )}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    )
  }

  render () {
    return (
      <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>
    )
  }
}

EditableCell.propTypes = {
  inputType: PropTypes.string,
  onEditingValueChange: PropTypes.func.isRequired,
  record: PropTypes.object,
  editing: PropTypes.bool,
  children: PropTypes.element,
  editingValue: PropTypes.string
}

export default injectIntl(EditableCell)
