import React from 'react'

import moment from 'moment'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { injectIntl, FormattedMessage } from 'react-intl'
import { Helmet } from 'react-helmet'
import { Table, notification, Icon, Button, Form, Input } from 'antd'
import manufacturersService from 'services/manufacturers'
import projectService from 'services/project'
import { findIndexInArray } from 'helpers/utils'

import ManufacturerForm from 'components/Manufacturers/Form'
import DrawerComponent from 'components/Drawer'
import PageTitle from 'components/Global/PageTitle'
import PopconfirmDelete from 'components/Global/PopconfirmDelete'
import queryString from 'query-string'
import { setPickedProject } from '../../../ducks/project'

export class ManufacturersPage extends React.Component {
  state = {
    manufacturers: [],
    projects: [],
    drawer: false,
    selectedRowKeys: [],
    search: {
      name: '',
      aksNumber: ''
    },
    loading: false,
    formLoading: false,
    readOnlyNewProject: false,
    pagination: {
      defaultPageSize: 10,
      showSizeChanger: true,
      pageSizeOptions: ['10', '20', '30', '50', '100']
    },
    drawerOption: '',
    manufacturer: {},
    createdByFilters: [],
    archivedFilters: ['hideArchived'],
    params: {}
  }

  getParams = () => {
    const { history } = this.props
    this._isMounted = true
    const searchString = history.location.search
    let searchQuery
    if (searchString.length) {
      searchQuery = queryString.parse(searchString, { arrayFormat: 'bracket' })
      if (searchQuery.page) {
        searchQuery.offset = searchQuery.page
        delete searchQuery.page
      }

      if (!searchQuery.limit) {
        searchQuery.limit = 10
      }
      if (!searchQuery.sortField && !searchQuery.sortOrder) {
        searchQuery.sortField = 'createdAt'
        searchQuery.sortOrder = 'descend'
      }

      return searchQuery
    }
  }

  setParams = paramsObject => {
    const { path, history, selectedProject } = this.props
    if (selectedProject && selectedProject._id) {
      paramsObject.project = selectedProject._id
    }
    if (paramsObject.offset) {
      paramsObject.page = paramsObject.offset
      delete paramsObject.offset
    }
    if (paramsObject.limit === 10) {
      delete paramsObject.limit
    }
    if (
      (paramsObject.sortField === 'createdAt' &&
        paramsObject.sortOrder === 'descend') ||
      !paramsObject.sortOrder
    ) {
      delete paramsObject.sortField
      delete paramsObject.sortOrder
    }

    this.setState({ params: paramsObject })
    history.push({
      pathname: path,
      search: queryString.stringify(paramsObject, { arrayFormat: 'bracket' })
    })
  }

  componentDidMount () {
    this.fetchManufacturers()
  }

  componentDidUpdate (prevProps, prevState) {
    const {
      location: { search: prevSearch }
    } = prevProps
    const {
      location: { search: nextSearch },
      dispatch,
      project
    } = this.props
    // todo: this params check required for  all pages  on  which  link can  be shared
    const params = { ...this.getParams() }

    if (
      params.project &&
      !prevProps.project.projectList.length &&
      project.projectList.length
    ) {
      dispatch(
        setPickedProject(
          project.projectList.find(item => item._id === params.project)
        )
      )
    }

    if (project.pickedProject !== prevProps.project.pickedProject) {
      if (project && project.pickedProject) {
        this.setParams({ ...params, project: project.pickedProject._id })
      } else {
        delete params.project
        this.setParams(params)
      }
    }
    if (prevSearch !== nextSearch || this.props.project !== prevProps.project) {
      this.fetchManufacturers()
    }
  }

  updateManufacturer = (field, value) => {
    this.setState({
      manufacturer: {
        ...this.state.manufacturer,
        [field]: value
      }
    })
  }

  fetchManufacturers = async () => {
    const params = this.getParams()

    this.setState({
      loading: true
    })
    try {
      const manufacturers = await manufacturersService.listV2({
        ...params
      })

      const pagination = {
        ...this.state.pagination
      }

      pagination.total = manufacturers.totalDocs

      this.setState({
        manufacturers: manufacturers.docs,
        pagination: {
          ...pagination,
          pageSize: manufacturers.limit,
          current: manufacturers.page
        },
        createdByFilters: manufacturers.filterOptions.createdBy,
        loading: false,
        params: { ...params }
      })
    } catch (error) {
      notification.error({
        message: `${this.props.intl.formatMessage({
          id: 'manufacturer'
        })} - download error`
      })

      this.setState({
        drawer: false,
        loading: false
      })
    }
  }

  fetchProjects = async value => {
    this.setState({
      formLoading: true
    })
    const timer = setTimeout(async () => {
      try {
        const projects = await projectService.listV2({
          projectName: [value],
          limit: 100
        })

        this.setState({
          projects: projects.docs,
          formLoading: false
        })

        clearTimeout(timer)
      } catch (error) {
        this.setState({
          projects: [],
          formLoading: false
        })
        clearTimeout(timer)
      }
    })
  }

  handleAddManufacturer = async () => {
    const payload = this.state.manufacturer

    if (this.state.manufacturer.project) {
      payload.project =
        this.state.manufacturer.project._id ||
        this.state.manufacturer.project ||
        null
    }

    this.props.form.validateFields(async err => {
      if (!err) {
        try {
          const manufacturerResponse = await manufacturersService.handleAddManufacturer(
            payload
          )

          this.fetchManufacturers()
          notification.success({
            message: `${this.props.intl.formatMessage({
              id: 'created successfully'
            })}`
          })

          this.setState({
            drawer: false,
            manufacturer: {},
            manufacturers: [...this.state.manufacturers, manufacturerResponse]
          })
        } catch (error) {
          notification.error({
            message: this.props.intl.formatMessage({
              id: 'saving error'
            }),
            description: error.error
          })
        }
      }
    })
  }

  handleDeleteManufacturer = async () => {
    try {
      await manufacturersService.deleteManufacturer(this.state.manufacturer._id)

      notification.success({
        message: this.props.intl.formatMessage({
          id: 'deleted successfully'
        })
      })

      this.setState({
        drawer: false,
        manufacturer: {},
        manufacturers: [
          ...this.state.manufacturers.filter(
            manufacturer => manufacturer._id !== this.state.manufacturer._id
          )
        ]
      })
    } catch (error) {
      notification.error({
        message: this.props.intl.formatMessage({
          id: 'deleting error'
        }),
        description: error.message
      })
    }
  }

  handleSaveManufacturer = async () => {
    const { manufacturer, manufacturers } = this.state
    this.props.form.validateFields(async err => {
      if (!err) {
        try {
          const payload = {
            name: manufacturer.name,
            project: manufacturer.project || null,
            street: manufacturer.street,
            postcode: manufacturer.postcode,
            city: manufacturer.city,
            country: manufacturer.country,
            website: manufacturer.website,
            email: manufacturer.email,
            phone: manufacturer.phone
          }
          await manufacturersService.updateManufacturer(
            manufacturer._id,
            payload
          )

          this.fetchManufacturers()
          notification.success({
            message: `${this.props.intl.formatMessage({
              id: 'updated successfully'
            })}`
          })

          const updateIndex = findIndexInArray(manufacturers, manufacturer._id)
          const updatedArray = [...manufacturers]
          updatedArray[updateIndex] = manufacturer

          this.setState({
            drawer: false,
            manufacturer: {},
            manufacturers: [...updatedArray]
          })
        } catch (error) {
          notification.error({
            message: this.props.intl.formatMessage({
              id: 'saving error'
            }),
            description: error.error
          })
        }
      }
    })
  }

  handleOnTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination }
    pager.offset = pagination.current
    const params = {
      limit: pagination.pageSize,
      offset: pagination.current,
      sortField: sorter.field,
      sortOrder: sorter.order,
      ...filters
    }
    this.setParams(params)
  }

  showDrawer = async manufacturer => {
    this.setState({
      ...this.state.manufacturer,
      drawer: true,
      manufacturer,
      drawerOption: 'edit',
      readOnlyNewProject: false
    })
  }

  handleHideDrawer = () => {
    this.setState({
      drawer: false
    })
  }

  handleShowDrawerAdd = async () => {
    this.setState({
      drawer: true,
      manufacturer: {
        project: this.props.selectedProject ? this.props.selectedProject : null
      },
      drawerOption: 'add',
      readOnlyNewProject: !!this.props.selectedProject._id
    })
  }

  handleSearch = (selectedKeys, field, confirm) => {
    confirm()
    this.setState({
      search: {
        ...this.state.search,
        [field]: selectedKeys[0]
      }
    })
  }

  handleReset = (field, clearFilters) => {
    clearFilters()
    const { params } = this.state
    const outputObject = { ...params }
    delete outputObject[field]
    this.setParams(outputObject)
    this.setState({
      [field + 'Search']: undefined
    })
  }

  setSelectedKeys = (dataIndex, value, setSelectedKeys) => {
    setSelectedKeys(value ? [value] : [])
    this.setState({
      [dataIndex + 'Search']: value
    })
  }

  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this[`searchInput${dataIndex}`] = node
          }}
          placeholder={this.props.intl.formatMessage({ id: 'search' })}
          value={
            this.state[dataIndex + 'Search'] !== undefined
              ? this.state[dataIndex + 'Search']
              : this.state.params[dataIndex]
          }
          onChange={e =>
            this.setSelectedKeys(dataIndex, e.target.value, setSelectedKeys)
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, dataIndex, confirm)
          }
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type='primary'
          onClick={() => this.handleSearch(selectedKeys, dataIndex, confirm)}
          icon='search'
          size='small'
          disabled={!this.state[dataIndex + 'Search']}
          style={{ width: 90, marginRight: 8 }}
        >
          Suchen
        </Button>
        <Button
          onClick={() => this.handleReset(dataIndex, clearFilters)}
          size='small'
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon
        type='search'
        style={{
          color:
            this.state.params[dataIndex] || filtered ? '#1890ff' : undefined
        }}
      />
    ),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this[`searchInput${dataIndex}`].select())
      }
    }
  })

  render () {
    const columns = [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        sorter: true,
        ...this.getColumnSearchProps('name')
      },
      {
        title: this.props.intl.formatMessage({ id: 'created at' }),
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: createdAt => moment(createdAt).format('DD.MM.YYYY, HH:mm'),
        sorter: true,
        defaultSortOrder:
          this.state.params.sortField === 'createdAt'
            ? this.state.params.sortOrder
            : 'descend'
      },
      {
        title: this.props.intl.formatMessage({ id: 'created by' }),
        dataIndex: 'createdBy',
        key: 'createdBy',
        render: createdBy =>
          createdBy ? `${createdBy.firstName} ${createdBy.lastName}` : '',
        sorter: true,
        filters: this.state.createdByFilters.map(user => ({
          text: `${user.firstName} ${user.lastName}`,
          value: user._id
        })),
        filteredValue: this.state.params.createdBy
      }
    ]

    if (!this.props.project) {
      columns.push({
        title: this.props.intl.formatMessage({ id: 'project' }),
        key: 'project',
        dataIndex: 'project.projectName'
      })
    }

    columns.push({
      key: 'actions',
      render: (text, record) => (
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'flex-end'
          }}
        >
          <Button onClick={() => this.showDrawer(manufacturer)}>
            <Icon
              type={
                manufacturer.isVerified || manufacturer.isInvalid
                  ? 'info-circle'
                  : 'edit'
              }
            />
          </Button>
        </div>
      )
    })

    const drawerButtonsAdd = [
      <Button key='cancel' onClick={this.handleHideDrawer}>
        <FormattedMessage id='cancel' />
      </Button>,
      <Button key='save' type='primary' onClick={this.handleAddManufacturer}>
        <FormattedMessage id='save' />
      </Button>
    ]

    const drawerButtonsEdit = [
      <Button key='cancel' onClick={this.handleHideDrawer}>
        <FormattedMessage id='cancel' />
      </Button>,
      <Button
        key='save'
        type='primary'
        onClick={this.handleSaveManufacturer}
        // disabled={this.state.recording && this.state.recording.isInvalid}
      >
        <FormattedMessage id='save' />
      </Button>
    ]

    if (
      ['admin', 'superadmin'].includes(this.props.auth.authUser.scope[0]) &&
      this.state.manufacturer &&
      !this.state.manufacturer.isVerified
    ) {
      drawerButtonsEdit.unshift(
        <PopconfirmDelete
          key={3}
          onConfirmAction={this.handleDeleteManufacturer}
        />
      )
    }

    const {
      manufacturers,
      projects,
      readOnlyNewProject,
      drawer,
      drawerOption,
      manufacturer,
      loading,
      formLoading
    } = this.state

    const headerButtons = [
      <Button key={354389832} type='primary' onClick={this.handleShowDrawerAdd}>
        <FormattedMessage id='create manufacturer' />
      </Button>
    ]

    return (
      <div>
        <FormattedMessage id='head.title.manufacturers'>
          {title => (
            <Helmet>
              <title>{title}</title>
            </Helmet>
          )}
        </FormattedMessage>

        <PageTitle
          title={this.props.intl.formatMessage({
            id: 'manufacturers'
          })}
          buttons={headerButtons}
        />

        <Table
          // eslint-disable-next-line no-return-assign
          ref={ref => (this._table = ref)}
          rowKey={record => record._id}
          columns={columns}
          dataSource={manufacturers}
          loading={loading}
          onChange={this.handleOnTableChange}
          onRow={record => ({
            onDoubleClick: () => {
              this.showDrawer(record)
            }
          })}
          defaultPageSize={this.state.pagination.defaultPageSize}
          pagination={this.state.pagination}
        />
        <DrawerComponent
          title={
            drawerOption === 'edit'
              ? this.props.intl.formatMessage({
                  id: 'edit manufacturer'
                })
              : this.props.intl.formatMessage({
                  id: 'add manufacturer'
                })
          }
          onClose={this.handleHideDrawer}
          visible={!!drawer}
          footerButtons={
            drawerOption === 'edit' ? drawerButtonsEdit : drawerButtonsAdd
          }
        >
          <ManufacturerForm
            option={drawerOption}
            projects={projects}
            formLoading={formLoading}
            readOnlyNewProject={readOnlyNewProject}
            manufacturer={manufacturer}
            fetchProjects={this.fetchProjects}
            updateManufacturer={this.updateManufacturer}
            form={this.props.form}
          />
        </DrawerComponent>
      </div>
    )
  }
}

ManufacturersPage.propTypes = {
  auth: PropTypes.object,
  history: PropTypes.object.isRequired,
  path: PropTypes.string.isRequired,
  selectedProject: PropTypes.object,
  project: PropTypes.object,
  location: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  auth: state.auth,
  selectedProject: state.project.pickedProject,
  project: state.project
})

export default injectIntl(
  Form.create({ name: 'manufacturersPage_form' })(
    connect(mapStateToProps)(ManufacturersPage)
  )
)
