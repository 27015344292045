import { createAction, createReducer } from 'redux-act'
import { notification } from 'antd'
import { CategoryAPI } from '../services'

export const REDUCER = 'category'
const NS = `@@${REDUCER}/`

export const setCategoryList = createAction(`${NS}SET_CATEGORY_LIST`)

const CategoryApi = new CategoryAPI()

export const initialState = {
  categoryList: []
}

export const fetchCategoryList = param => (dispatch, getState) => {
  if (!!param) {
    return CategoryApi.listV2(param)
      .then(response => {
        dispatch(setCategoryList(response))
        return response
      })
      .catch(error => {
        notification.open({
          type: 'error',
          message: error.message
        })
      })
  } else {
    return CategoryApi.getList()
      .then(response => {
        dispatch(setCategoryList(response))
        return response
      })
      .catch(error => {
        notification.open({
          type: 'error',
          message: error.message
        })
        return error
      })
  }
}

export default createReducer(
  {
    [setCategoryList]: (state, categoryList) => ({ ...state, categoryList })
  },
  initialState
)
