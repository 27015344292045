export default {
  acceptances: 'Acceptances',
  activated: 'Activated',
  active: 'Active',
  archive: 'Archive',
  'actual status': 'Actual Status',
  'add product': 'Add product',
  'add workorder': 'Add workorder',
  'add category': 'Add category',
  after: 'after',
  approve: 'Approve',
  approved: 'Approved',
  archived: 'Archived',
  before: 'before',
  category: 'Category',
  categories: 'Categories',
  cancel: 'Cancel',
  'click to upload': 'Click to upload',
  comment: 'Comment',
  'company address': 'Company address',
  'company name': 'Company name',
  'company location': 'Company location',
  'confirm deleting': 'Confirm deleting',
  'confirm overwriting': 'Confirm overwriting',
  'contract section': 'Contract section',
  'contract sections': 'Contract sections',
  'created at': 'Created at',
  'created by': 'Created by',
  'create workorder': 'Create work order',
  'create scheduled workorder': 'Create scheduled workorder',
  'create a new scheduled Workorder': 'Create a new scheduled Workorder',
  'create manufacturer': 'Create manufacturer',
  'create category': 'Create category',
  'create product': 'Create product',
  'create special work': 'Create special work',
  'edit special work': 'Edit special work',
  date: 'Date',
  delete: 'Delete',
  deleted: 'Deleted',
  'deleting error': 'Deleting error',
  description: 'Description',
  'description is required': 'Description is required',
  depth: 'Depth',
  disabled: 'Disabled',
  'do you want to archive this project?':
    'Do you want to archive this project?',
  'do you want to delete this acceptance permanently?':
    'Do you want to delete this acceptance permanently?',
  'do you want to delete this recording permanently?':
    'Do you want to delete this recording permanently?',
  'do you want to unarchive this project?':
    'Do you want to unarchive this project?',
  'do you really want to remove this product?':
    'Do you really want to remove this product?',
  done: 'Done',
  'task removing': 'Task removing fail',
  'task save': 'Task saving fail',
  'edit product': 'Edit product',
  'edit manufacturer': 'Edit manufacturer',
  'edit workorder': 'Edit workorder',
  'edit request': 'Edit request',
  'edit category': 'Edit category',
  email: 'Email',
  'email and password are required': 'E-mail and Password are required',
  'email cannot be empty': 'Email cannot be empty',
  'email is not valid': 'E-mail is not valid',
  'email with further instructions has been sent':
    'Email with further instructions has been sent',
  'employee number': 'Employee number',
  enabled: 'Enabled',
  error: 'Error',
  'file has been imported successfully': 'File has been imported successfully',
  'first name': 'First name',
  'forgot password': 'Forgot Password',
  form: 'Form',
  forms: 'Forms',
  function: 'Function',
  general: 'General',
  'global settings': 'Global Settings',
  group: 'Group',
  groups: 'Groups',
  'hide archived': 'hide archived',
  height: 'Height',
  high: 'Hoch',
  identification: 'Identification',
  'image must smaller than': 'Image must smaller than',
  inactive: 'Inactive',
  'incorrect email or password': 'Incorrect email or password',
  informations: 'Informations',
  invalid: 'Invalid',
  'is required': 'is required',
  'in progress': 'in progress',
  'last login': 'Last login',
  'last name': 'Last name',
  location: 'Location',
  locations: 'Locations',
  'log in to your ddSuite instance': 'Log in to your ddSuite instance',
  login: 'Login',
  'login error': 'Login Error',
  logout: 'Logout',
  low: 'Low',
  length: 'Length',
  medium: 'Medium',
  manufacturer: 'Manufacturer',
  manufacturers: 'Manufacturers',
  modules: 'Modules',
  'Mark as undone': 'Mark as undone',
  'name is required': 'Name is required',
  new: 'New',
  no: 'No',
  number: 'Number',
  'number of': 'Number of',
  numeration: 'Numeration',
  objects: 'Objects',
  'object name': 'Object name',
  open: 'Open',
  opened: 'Opened',
  orders: 'Orders',
  ordernumber: 'Order number',
  'ordernumber is required': 'Ordernumber is required',
  overwrite: 'Overwrite',
  participant: 'Participant',
  password: 'Password',
  'password cannot be empty': 'Password cannot be empty',
  'passwords arent identical': `Passwords aren't identical`,
  phone: 'Phone',
  photo: 'Photo',
  'photo comment': 'Comment',
  photos: 'Photos',
  project: 'Project',
  projects: 'Projects',
  products: 'Products',
  price: 'Price',
  quantity: 'Quantity',
  'recording form': 'Recording Form',
  'recording images': 'Images',
  recordings: 'Recordings',
  recurring: 'Recurring',
  reject: 'Reject',
  rejected: 'Rejected',
  requested: 'Requested',
  requests: 'Requests',
  'reset password': 'Reset password',
  remove: 'Remove',
  'retype password': 'Retype password',
  rights: 'Rights',
  role: 'Role',
  save: 'Save',
  saved: 'Saved',
  'save special work': 'Save special work',
  'saving error': 'Saving error',
  search: 'Search',
  'select a project': 'Select a project',
  'select form': 'Select form',
  'select manager': 'Select manager',
  send: 'Send',
  settings: 'Settings',
  stats: 'Stats',
  status: 'Status',
  success: 'Success',
  'supplier number': 'Supplier number',
  'target status': 'Target Status',
  title: 'Title',
  unarchived: 'Unarchived',
  unverified: 'Unverified',
  'update special work': 'Update special work',
  'update account': 'Update account',
  'uploading error': 'Uploading Error',
  users: 'Users',
  verified: 'Verified',
  'verify selected': 'Verify Selected',
  'workorder created': 'Workorder successfully created',
  'work orders': 'Work orders',
  width: 'Width',
  yes: 'Yes',
  'you can only upload PNG file': 'You can only upload PNG file',
  'your password has been changed': 'Your password has been changed',
  zip: 'Zip',
  completeInfoBelow: 'Please complete the information below.',
  name: 'Name',
  objectNumber: 'Objectnumber',
  assignedTo: 'Assigned to',
  actualSituation: 'Actual Situation',
  targetSituation: 'Target Situation',
  skip: 'Skip',
  estimated_cost: 'Estimation cost',
  'bulk change button': 'Bulk change',
  'change assignment': 'Change assignment',
  'change status': 'Change status',
  protocolReference: 'Protocol Reference',
  protocolRefExt: 'Protocol Reference External',
  protocolExtResponsible: 'Protocol External Responsible',
  protocolResponsible: 'Protocol Responsible',
  images: 'Images',
  clickToUpload: 'Click or drag file to this area to upload',
  fileSupportInfo:
    'Support for a single. Strictly prohibit from uploading company data or other band files',
  'images upload': 'Images upload',
  'add comment': 'Add Comment',
  submit: 'Save',
  'upload csv': 'Upload CSV',
  'edit comment': 'Edit Comment',
  'recording details': 'Recording details',
  tasks: 'Tasks',
  defects: 'Defects',
  workorders: 'Workorders',
  'scheduled-workorders': 'Scheduled Workorders',
  protocol: 'Protocol',
  protocols: 'Protocols',
  specialwork: 'Special Work',
  contacts: 'Contacts',
  'update recording': 'Update recording',
  'reference number': 'Ref number',
  street: 'Street',
  city: 'City',
  company: 'Company',
  postcode: 'Postcode',
  event: 'Event',
  endpoint: 'Endpoint',
  files: 'Files',
  updates: 'Updates',
  'create defect': 'Create Defect',
  'csv import': 'CSV Import',
  'csv export': 'CSV Export',
  actions: 'Actions',
  assignee: 'Assignee',
  edit: 'Edit',
  view: 'View',
  'head.title.recordings': 'Recordings',
  'show archived': 'Show archived',
  'show unarchived': 'Show unarchived',
  'show all': 'Show all',
  defect: 'Defect',
  'edit recording': 'Edit recording',
  'defect creation': 'Defect creation',
  'csv product export': 'CSV product export',
  'updated successfully': 'Updated successfully',
  'created successfully': 'Created successfully',
  'open requests': 'Opened requests',
  'open workorders': 'Open workorders',
  'head.title.workorders': 'Workorders',
  'workorder planning': 'Workorder planing',
  'update workorder': 'Update workorder',
  'starts at': 'Starts at',
  'signature required': 'Signature required',
  'add tasks': 'Add tasks',
  'add tasks description': 'Add tasks description',
  'due date': 'Due date',
  'Upload files': 'Upload files',
  'special work required': 'Special work required',
  'on hold': 'On hold',
  none: 'None',
  priority: 'Priority',
  complete: 'Complete',
  incomplete: 'Incomplete',
  'save workorder': 'Save workorder',
  'head.title.requests': 'Requests',
  'head.title.acceptances': 'Acceptances',
  'head.title.products': 'Products',
  'head.title.manufacturers': 'Manufacturers',
  'head.title.categories': 'Categories',
  'head.title.tasks': 'Tasks',
  'head.title.defects': 'Defects',
  'head.title.specialwork': 'Special work',
  'head.title.protocol': 'Protocol',
  'head.title.objects': 'Objects',
  'head.title.contractSections': 'Contract sections',
  'head.title.contacts': 'Contacts',
  'head.title.groups': 'Groups',
  'head.title.modules': 'Modules',
  'head.title.forms': 'Forms',
  'head.title.settings': 'Settings',
  'head.title.projects': 'Projects',
  'create project': 'Create project',
  'acceptance form': 'Acceptance form',
  'recordings name': 'Recordings name',
  'add manufacturer': 'Add manufacturer',
  'select category form': 'Select category form',
  'category form': 'Category form',
  'assign to workorder': 'Assign to workorder',
  'interface-process-description':
    'The process cannot be undone and the recording cannot be edited after the upload. Do you want to start now?',
  'You are about to start sending': 'You are about to start sending',
  'external protocol reference': 'External protocol reference',
  'protocol reference': 'Protocol reference',
  'choose a new external protocol protocol reference':
    'Choose a new external protocol protocol reference',
  'protocol reference external': 'Protocol reference external',
  'choose protocol responsible': 'Choose protocol responsible',
  'protocol responsible': 'Protocol responsible',
  'choose a new protocol reference': 'Choose a new protocol reference',
  'choose a new status': 'Choose a new status',
  'choose a new contact': 'Choose a new contact',
  'assigned to': 'Assigned to',
  'bulk change': 'Bulk change',
  'Edit tasks': 'Edit tasks',
  'Add task': 'Add task',
  'title is required': 'Title is required',
  'create object': 'Create object',
  completed: 'Completed',
  request: 'Request',
  workorder: 'Workorder',
  acceptance: 'Acceptance',
  recording: 'Recording',
  categoryMapping: 'Category mapping',
  sapEnabled: 'SAP enabled',
  Remove: 'Remove',
  Description: 'Description',
  Objectnumber: 'Objectnumber',
  'head.title.locations': 'Locations',
  'create location': 'Create location',
  'create contract section': 'Create contract section',
  'head.title.users': 'Users',
  'create user': 'Create user',
  'create contact': 'Create contact',
  'company is required': 'Company is required',
  'create form': 'Create form',
  'defect created': 'Defect created',
  'create defects mainx': 'Create defects mainx',
  'generate pdf': 'Generate pdf',
  website: 'Website',
  zipcode: 'Zip code',
  people: 'People',
  'link to person': 'Link to person',
  'add person': 'Add person',
  'new person': 'New person',
  'email is required': 'Email is required',
  person: 'Person',
  'create a new workorder': 'Create new workorder',
  'choose special work': 'Choose special work',
  'select special work': 'Select special work',
  'select user': 'Select user',
  'create task': 'Create task',
  'tasks not assigned': 'Tasks not assigned',
  'defect created mainx': 'Defect created (MainX)',
  'protocol info': 'Protocol Info',
  'new password tooltip': 'Password must be at least 8 characters',
  'password compare tooltip': 'Two passwords are not equal',
  'common data': 'Common data',
  'specific data': 'specific data'
}
