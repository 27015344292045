import React from 'react'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { Form, Input, Select, Spin } from 'antd'
import PropTypes from 'prop-types'

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
  className: 'formItem'
}

class CategoryForm extends React.Component {
  render () {
    const {
      props,
      props: { formOptions }
    } = this
    const { getFieldDecorator } = props.form
    // eslint-disable-next-line camelcase
    let custom_form
    if (this.props.category.custom_form) {
      if (typeof this.props.category.custom_form === 'object') {
        // eslint-disable-next-line camelcase
        custom_form = this.props.category.custom_form.name
      } else {
        // eslint-disable-next-line camelcase
        custom_form = null
      }
    }

    return (
      <>
        <Form className='workOrdersForm'>
          <Form.Item {...formItemLayout} label='Name'>
            {getFieldDecorator('name', {
              initialValue: props.category.name,
              rules: [
                {
                  required: true,
                  message: this.props.intl.formatMessage({
                    id: 'name is required'
                  })
                }
              ]
            })(
              <Input
                onChange={event =>
                  props.updateCategory('name', event.target.value)
                }
              />
            )}
          </Form.Item>

          <Form.Item
            {...formItemLayout}
            label={this.props.intl.formatMessage({
              id: 'project'
            })}
          >
            <Select
              showSearch
              allowClear
              style={{ width: '100%' }}
              optionFilterProp='children'
              onFocus={() => props.fetchProjects('')}
              onSearch={value => props.fetchProjects(value)}
              onChange={projectID => props.updateCategory('project', projectID)}
              defaultValue={
                props.category.project ? props.category.project.projectName : ''
              }
              disabled={props.readOnlyNewProject}
            >
              {props.projects.map(project => (
                <Select.Option key={project._id} value={project._id}>
                  {project.projectName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            {...formItemLayout}
            label={this.props.intl.formatMessage({ id: 'category form' })}
          >
            <Select
              showSearch
              allowClear
              // disabled={this.props.project.archived}
              style={{ width: '100%' }}
              placeholder={this.props.intl.formatMessage({
                id: 'select category form'
              })}
              notFoundContent={
                this.props.formLoading ? (
                  <div className='categorie-spinner-container'>
                    <Spin size='small' />
                  </div>
                ) : null
              }
              optionFilterProp='children'
              onFocus={() => this.props.fetchForms('')}
              // filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              onSearch={value => this.props.fetchForms(value)}
              onChange={
                (value, e) => this.props.updateCategory('custom_form', value, e) // eslint-disable-line
              }
              defaultValue={custom_form} // eslint-disable-line
            >
              {formOptions.map(form => (
                <Select.Option key={form._id} data={form} value={form._id}>
                  {form.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </>
    )
  }
}

CategoryForm.propTypes = {
  form: PropTypes.object,
  category: PropTypes.object,
  option: PropTypes.string,
  intl: PropTypes.object.isRequired,
  fetchProjects: PropTypes.func.isRequired,
  updateCategory: PropTypes.func.isRequired,
  fetchForms: PropTypes.func.isRequired,
  formLoading: PropTypes.bool,
  readOnlyNewProject: PropTypes.bool,
  projects: PropTypes.array
}

export default injectIntl(connect()(CategoryForm))
