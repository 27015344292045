import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import { Form, Input, Select, Spin } from 'antd'

import { connect } from 'react-redux'
import contactService from '../../../../services/contact'

const { Option } = Select

class ProtocolInfo extends React.Component {
  state = {
    contacts: []
  }

  fetchData = async value => {
    this.setState({
      formLoading: true
    })

    const timer = setTimeout(async () => {
      try {
        const data = await contactService.listV2({
          company: [value],
          limit: 100
        })
        this.setState({
          contacts: data.docs || data,
          formLoading: false
        })

        clearTimeout(timer)
      } catch (error) {
        this.setState({
          contacts: [],
          formLoading: false
        })
        clearTimeout(timer)
      }
    })
  }

  render () {
    const {
      form: { getFieldDecorator },
      defect,
      defect: { protocolExtResponsible, protocolResponsible },
      intl,
      updateItem
    } = this.props
    const { contacts, formLoading } = this.state

    const formItemLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
      className: 'formItem'
    }

    return (
      <>
        <Form onSubmit={() => {}} className='defect-form'>
          <Form.Item
            {...formItemLayout}
            label={intl.formatMessage({
              id: 'protocolResponsible'
            })}
          >
            {getFieldDecorator('protocolResponsible', {
              initialValue:
                protocolResponsible &&
                protocolResponsible.contact_type &&
                protocolResponsible.contact_type === 'user'
                  ? `${protocolResponsible.firstName ||
                      ''} ${protocolResponsible.lastName || ''}`
                  : (protocolResponsible && protocolResponsible.company) || ''
            })(
              <Select
                showSearch
                allowClear
                style={{ width: '100%' }}
                optionFilterProp='children'
                notFoundContent={
                  formLoading ? (
                    <div className='objects-spinner-container'>
                      <Spin size='small' />
                    </div>
                  ) : null
                }
                onFocus={() => this.fetchData('', 'contacts')}
                onSearch={value => this.fetchData(value)}
                onChange={projectID =>
                  updateItem('protocolResponsible', projectID)
                }
              >
                {contacts.map(element => (
                  <Option key={element._id} value={element._id}>
                    {element.contact_type && element.contact_type === 'user'
                      ? `${element.firstName || ''} ${element.lastName || ''}`
                      : element.company}
                  </Option>
                ))}
              </Select>
            )}
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            label={intl.formatMessage({ id: 'protocolReference' })}
          >
            {getFieldDecorator('protocolReference', {
              initialValue: defect.protocolReference || ''
            })(
              <Input
                onChange={event =>
                  updateItem('protocolReference', event.target.value)
                }
              />
            )}
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            label={intl.formatMessage({ id: 'protocolRefExt' })}
          >
            {getFieldDecorator('protocolRefExt', {
              initialValue: defect.protocolRefExt || ''
            })(
              <Input
                onChange={event =>
                  updateItem('protocolRefExt', event.target.value)
                }
              />
            )}
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            label={intl.formatMessage({
              id: 'protocolExtResponsible'
            })}
          >
            {getFieldDecorator('protocolExtResponsible', {
              initialValue:
                protocolExtResponsible &&
                protocolExtResponsible.contact_type &&
                protocolExtResponsible.contact_type === 'user'
                  ? `${protocolExtResponsible.firstName ||
                      ''} ${protocolExtResponsible.lastName || ''}`
                  : (protocolExtResponsible &&
                      protocolExtResponsible.company) ||
                    ''
            })(
              <Select
                showSearch
                allowClear
                style={{ width: '100%' }}
                optionFilterProp='children'
                notFoundContent={
                  formLoading ? (
                    <div className='objects-spinner-container'>
                      <Spin size='small' />
                    </div>
                  ) : null
                }
                onFocus={() => this.fetchData('')}
                onSearch={value => this.fetchData(value)}
                onChange={projectID =>
                  updateItem('protocolExtResponsible', projectID)
                }
              >
                {contacts.map(element => (
                  <Option key={element._id} value={element._id}>
                    {element.contact_type && element.contact_type === 'user'
                      ? `${element.firstName || ''} ${element.lastName || ''}`
                      : element.company}
                  </Option>
                ))}
              </Select>
            )}
          </Form.Item>
        </Form>
      </>
    )
  }
}

ProtocolInfo.propTypes = {
  updateItem: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
  objectName: PropTypes.string,
  project: PropTypes.object,
  projects: PropTypes.array,
  defect: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  readOnlyNewProject: PropTypes.bool,
  handleRefClick: PropTypes.func.isRequired,
  contacts: PropTypes.array,
  formLoading: PropTypes.bool,
  fetchData: PropTypes.func.isRequired
}

const mapStateToProps = state => {
  return {
    objectName:
      (state.settings.data.buildx && state.settings.data.buildx.objectName) ||
      ''
  }
}

export default injectIntl(connect(mapStateToProps)(ProtocolInfo))
