import React from 'react'
import { Button, Menu, Dropdown, Icon, Select, Avatar } from 'antd'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { FormattedMessage, useIntl } from 'react-intl'
import { NavLink, useLocation, useHistory } from 'react-router-dom'

import { logout } from '../../../ducks/login'
import { setLanguage } from '../../../ducks/settings'
import { fetchProjectList, setPickedProject } from '../../../ducks/project'
import queryString from 'query-string'

const mapStateToProps = state => ({
  settings: state.settings,
  auth: state.auth,
  projects: state.project.projectList,
  project: state.project.pickedProject
})

const Header = () => {
  const { settings, auth, projects, project } = useSelector(mapStateToProps)

  const roles = auth.authUser.scope || []
  const location = useLocation()
  const dispatch = useDispatch()
  const intl = useIntl()
  const history = useHistory()
  const hiddenProjectSelection = [
    '/projects',
    '/settings',
    '/forms',
    '/modules',
    '/groups',
    '/users',
    '/contacts'
  ]

  const nonStandarUser = roles[0] === 'admin' || roles[0] === 'superadmin'

  const menu = (
    <Menu>
      {nonStandarUser && (
        <Menu.Item>
          <NavLink to='/settings' style={{ textDecoration: 'none' }}>
            <Icon type='setting' style={{ marginRight: 8 }} />
            <FormattedMessage id='settings' />
          </NavLink>
        </Menu.Item>
      )}

      <Menu.Divider />

      <Menu.Item onClick={() => dispatch(logout())}>
        <Icon type='poweroff' />
        <FormattedMessage id='logout' />
      </Menu.Item>
    </Menu>
  )

  const changeProject = (projectID, e) => {
    if (e && e.props && e.props.data) {
      dispatch(setPickedProject(e.props.data))
      history.push({
        pathname: history.location.pathname,
        search: queryString.stringify(
          { project: e.props.data._id },
          { arrayFormat: 'bracket' }
        )
      })
    } else {
      dispatch(setPickedProject({}))
      history.push({
        pathname: history.location.pathname
      })
    }
  }

  return (
    <>
      {projects.length > 0 &&
        !hiddenProjectSelection.includes(location.pathname) && (
          <Select
            showSearch
            allowClear
            style={{ width: 200 }}
            placeholder={intl.formatMessage({
              id: 'select a project'
            })}
            optionFilterProp='children'
            onSearch={value => fetchProjectList(value)}
            onChange={changeProject}
            value={
              project
                ? project.projectName
                : intl.formatMessage({
                    id: 'select a project'
                  })
            }
          >
            {projects.map(project => (
              <Select.Option
                key={project._id}
                data={project}
                value={project._id}
              >
                {project.projectName}
              </Select.Option>
            ))}
          </Select>
        )}

      <Dropdown overlay={menu} placement='bottomRight' trigger={['click']}>
        <Button style={{ border: 0, float: 'right', marginTop: 16 }}>
          <Avatar
            icon='user'
            style={{ marginLeft: 0, marginRight: 10, marginTop: -1 }}
          />{' '}
          <span style={{ marginTop: 5 }}>{auth.authUser.fullName}</span>
        </Button>
      </Dropdown>

      <Dropdown
        overlay={
          <Menu>
            <Menu.Item onClick={() => dispatch(setLanguage('en'))}>
              <span
                className='nav-text'
                style={{
                  fontWeight: settings.lang === 'en' ? 'bold' : 'normal'
                }}
              >
                EN
              </span>
            </Menu.Item>

            <Menu.Item onClick={() => dispatch(setLanguage('de'))}>
              <span
                className='nav-text'
                style={{
                  fontWeight: settings.lang === 'en' ? 'normal' : 'bold'
                }}
              >
                DE
              </span>
            </Menu.Item>
          </Menu>
        }
        placement='bottomCenter'
        trigger={['click']}
      >
        <Button
          shape='circle'
          icon='global'
          style={{ float: 'right', border: 0, marginTop: 15, marginLeft: 10 }}
        />
      </Dropdown>
    </>
  )
}

Header.propTypes = {
  dispatch: PropTypes.func.isRequired,
  auth: PropTypes.object,
  projects: PropTypes.array.isRequired,
  project: PropTypes.object,
  settings: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  fetchProjects: PropTypes.func.isRequired
}

export default Header
