import React from 'react'

import moment from 'moment'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { injectIntl, FormattedMessage } from 'react-intl'
import { Table, notification, Icon, Button, Form, Input } from 'antd'
import categoriesService from 'services/categories'
import projectService from 'services/project'
import { findIndexInArray } from 'helpers/utils'
import { Helmet } from 'react-helmet'
import formService from 'services/form'

import CategoryForm from 'components/Categories/Form'
import DrawerComponent from 'components/Drawer'
import PageTitle from 'components/Global/PageTitle'
import PopconfirmDelete from 'components/Global/PopconfirmDelete'
import queryString from 'query-string'
import { setPickedProject } from '../../../ducks/project'

export class CategoriesPage extends React.Component {
  state = {
    categories: [],
    projects: [],
    drawer: false,
    selectedRowKeys: [],
    forms: [],
    search: {
      name: '',
      aksNumber: ''
    },
    loading: false,
    formLoading: false,
    readOnlyNewProject: false,
    pagination: {
      defaultPageSize: 10,
      showSizeChanger: true,
      pageSizeOptions: ['10', '20', '30', '50', '100']
    },
    drawerOption: '',
    category: {},
    createdByFilters: [],
    archivedFilters: ['hideArchived'],
    params: {}
  }

  getParams = () => {
    const { history } = this.props
    this._isMounted = true
    const searchString = history.location.search
    let searchQuery
    if (searchString.length) {
      searchQuery = queryString.parse(searchString, { arrayFormat: 'bracket' })
      if (searchQuery.page) {
        searchQuery.offset = searchQuery.page
        delete searchQuery.page
      }
      if (!searchQuery.limit) {
        searchQuery.limit = 10
      }
      if (!searchQuery.sortField && !searchQuery.sortOrder) {
        searchQuery.sortField = 'createdAt'
        searchQuery.sortOrder = 'descend'
      }
      return searchQuery
    }
  }

  setParams = paramsObject => {
    const { path, history, selectedProject } = this.props
    if (selectedProject && selectedProject._id) {
      paramsObject.project = selectedProject._id
    }
    if (paramsObject.offset) {
      paramsObject.page = paramsObject.offset
      delete paramsObject.offset
    }
    if (paramsObject.limit === 10) {
      delete paramsObject.limit
    }
    if (
      (paramsObject.sortField === 'createdAt' &&
        paramsObject.sortOrder === 'descend') ||
      !paramsObject.sortOrder
    ) {
      delete paramsObject.sortField
      delete paramsObject.sortOrder
    }
    this.setState({ params: paramsObject })
    history.push({
      pathname: path,
      search: queryString.stringify(paramsObject, { arrayFormat: 'bracket' })
    })
  }

  componentDidUpdate (prevProps, prevState) {
    const {
      location: { search: prevSearch }
    } = prevProps
    const {
      location: { search: nextSearch },
      project,
      dispatch
    } = this.props
    const params = { ...this.getParams() }

    if (
      params.project &&
      !prevProps.project.projectList.length &&
      project.projectList.length
    ) {
      dispatch(
        setPickedProject(
          project.projectList.find(item => item._id === params.project)
        )
      )
    }

    if (project.pickedProject !== prevProps.project.pickedProject) {
      if (project && project.pickedProject) {
        this.setParams({ ...params, project: project.pickedProject._id })
      } else {
        delete params.project
        this.setParams(params)
      }
    }
    if (prevSearch !== nextSearch || this.props.project !== prevProps.project) {
      this.fetchCategories()
    }
  }

  componentDidMount () {
    this.fetchCategories()
  }

  fetchForms = async value => {
    this.setState({
      formLoading: true
    })
    try {
      const forms = await formService.listV2({
        name: [value],
        limit: 100
      })

      this.setState({
        forms: forms.docs,
        formLoading: false
      })
    } catch (error) {
      this.setState({
        formLoading: false
      })
      notification.error({
        message: `${this.props.intl.formatMessage({
          id: 'forms'
        })} - download error`
      })

      this.setState({
        forms: []
      })
    }
  }

  updateCategory = (field, value, e) => {
    if (field === 'custom_form') {
      this.setState({
        category: {
          ...this.state.category,
          custom_form: e ? e.props.data : null
        }
      })
    } else {
      this.setState({
        category: {
          ...this.state.category,
          [field]: value
        }
      })
    }
  }

  fetchCategories = async () => {
    const params = this.getParams()
    this.setState({
      loading: true
    })
    try {
      const categories = await categoriesService.listV2({
        limit: this.state.defaultPageSize,
        ...params
      })

      const pagination = {
        ...this.state.pagination
      }

      pagination.total = categories.totalDocs

      this.setState({
        categories: categories.docs,
        pagination: {
          ...pagination,
          pageSize: categories.limit,
          current: categories.page
        },
        params: { ...params },
        createdByFilters: categories.filterOptions.createdBy,
        loading: false
      })
    } catch (error) {
      this.setState({
        loading: false
      })
      notification.error({
        message: `${this.props.intl.formatMessage({
          id: 'categories'
        })} - download error`
      })

      this.setState({
        drawer: false
      })
    }
  }

  fetchProjects = async value => {
    this.setState({
      formLoading: true
    })
    const timer = setTimeout(async () => {
      try {
        const projects = await projectService.listV2({
          projectName: [value],
          limit: 100
        })

        this.setState({
          projects: projects.docs,
          formLoading: false
        })

        clearTimeout(timer)
      } catch (error) {
        this.setState({
          projects: [],
          formLoading: false
        })
        clearTimeout(timer)
      }
    })
  }

  handleAddCategory = async () => {
    const { category } = this.state
    const payload = {
      name: category.name,
      custom_form: category.custom_form ? category.custom_form._id : null,
      project:
        (category.project && category.project._id) || category.project || null
    }
    this.props.form.validateFields(async err => {
      if (!err) {
        try {
          const categoryResponse = await categoriesService.addCategory(payload)

          this.fetchCategories()
          notification.success({
            message: `${this.props.intl.formatMessage({
              id: 'created successfully'
            })}`
          })

          this.setState({
            drawer: false,
            category: {},
            pagination: {
              current: 1
            },
            categories: [...this.state.categories, categoryResponse]
          })

          this.fetchCategories()
        } catch (error) {
          notification.error({
            message: this.props.intl.formatMessage({
              id: 'saving error'
            }),
            description: error.error
          })
        }
      }
    })
  }

  handleDeleteCategory = async () => {
    try {
      await categoriesService.deleteCategory(this.state.category._id)

      notification.success({
        message: this.props.intl.formatMessage({
          id: 'deleted successfully'
        })
      })

      this.setState({
        drawer: false,
        category: {},
        categories: [
          ...this.state.categories.filter(
            category => category._id !== this.state.category._id
          )
        ]
      })
    } catch (error) {
      notification.error({
        message: this.props.intl.formatMessage({
          id: 'deleting error'
        }),
        description: error.message
      })
    }
  }

  handleSaveCategory = async () => {
    const { category, categories } = this.state
    this.props.form.validateFields(async err => {
      if (!err) {
        try {
          const payload = {
            name: category.name,
            custom_form: category.custom_form ? category.custom_form._id : null,
            project:
              (category.project && category.project._id) ||
              category.project ||
              null
          }

          await categoriesService.updateCategory(category._id, payload)

          this.fetchCategories()
          notification.success({
            message: `${this.props.intl.formatMessage({
              id: 'updated successfully'
            })}`
          })

          const updateIndex = findIndexInArray(categories, category._id)
          const updatedArrray = [...categories]
          updatedArrray[updateIndex] = category
          this.setState({
            drawer: false,
            category: {},
            categories: [...updatedArrray]
          })
        } catch (error) {
          notification.error({
            message: this.props.intl.formatMessage({
              id: 'saving error'
            }),
            description: error.error
          })
        }
      }
    })
  }

  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination }
    pager.offset = pagination.current
    const params = {
      limit: pagination.pageSize,
      offset: pagination.current,
      sortField: sorter.field,
      sortOrder: sorter.order,
      ...filters
    }
    this.setParams(params)
  }

  showDrawer = async category => {
    this.setState({
      ...this.state.category,
      drawer: true,
      category,
      drawerOption: 'edit',
      readOnlyNewProject: false
    })
  }

  handleHideDrawer = () => {
    this.setState({
      drawer: false
    })
  }

  handleShowDrawerAdd = async () => {
    this.setState({
      drawer: true,
      category: {
        project: this.props.selectedProject ? this.props.selectedProject : null
      },
      readOnlyNewProject: !!this.props.selectedProject._id,
      drawerOption: 'add'
    })
  }

  handleSearch = (selectedKeys, field, confirm) => {
    confirm()
    this.setState({
      search: {
        ...this.state.search,
        [field]: selectedKeys[0]
      }
    })
  }

  handleReset = (field, clearFilters) => {
    clearFilters()
    const { params } = this.state
    const outputObject = { ...params }
    delete outputObject[field]
    this.setParams(outputObject)
    this.setState({
      [field + 'Search']: undefined
    })
  }

  setSelectedKeys = (dataIndex, value, setSelectedKeys) => {
    setSelectedKeys(value ? [value] : [])
    this.setState({
      [dataIndex + 'Search']: value
    })
  }

  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this[`searchInput${dataIndex}`] = node
          }}
          placeholder={this.props.intl.formatMessage({ id: 'search' })}
          value={
            this.state[dataIndex + 'Search'] !== undefined
              ? this.state[dataIndex + 'Search']
              : this.state.params[dataIndex]
          }
          onChange={e =>
            this.setSelectedKeys(dataIndex, e.target.value, setSelectedKeys)
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, dataIndex, confirm)
          }
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type='primary'
          onClick={() => this.handleSearch(selectedKeys, dataIndex, confirm)}
          icon='search'
          disabled={!this.state[dataIndex + 'Search']}
          size='small'
          style={{ width: 90, marginRight: 8 }}
        >
          Suchen
        </Button>
        <Button
          onClick={() => this.handleReset(dataIndex, clearFilters)}
          size='small'
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon
        type='search'
        style={{
          color:
            this.state.params[dataIndex] || filtered ? '#1890ff' : undefined
        }}
      />
    ),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this[`searchInput${dataIndex}`].select())
      }
    }
  })

  render () {
    const columns = [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        sorter: true,
        ...this.getColumnSearchProps('name')
      },
      {
        title: this.props.intl.formatMessage({ id: 'created at' }),
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: createdAt => moment(createdAt).format('DD.MM.YYYY, HH:mm'),
        sorter: true,
        defaultSortOrder:
          this.state.params.sortField === 'createdAt'
            ? this.state.params.sortOrder
            : 'descend'
      },
      {
        title: this.props.intl.formatMessage({ id: 'created by' }),
        dataIndex: 'createdBy',
        key: 'createdBy',
        render: createdBy =>
          createdBy ? `${createdBy.firstName} ${createdBy.lastName}` : '',
        sorter: true,
        filters: this.state.createdByFilters.map(user => ({
          text: `${user.firstName} ${user.lastName}`,
          value: user._id
        })),
        filteredValue: this.state.params.createdBy
      }
    ]

    if (!this.props.project) {
      columns.push({
        title: this.props.intl.formatMessage({ id: 'project' }),
        key: 'project',
        dataIndex: 'project.projectName'
      })
    }

    columns.push({
      key: 'actions',
      render: (text, record) => (
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'flex-end'
          }}
        >
          <Button onClick={() => this.showDrawer(record)}>
            <Icon
              type={
                category.isVerified || category.isInvalid
                  ? 'info-circle'
                  : 'edit'
              }
            />
          </Button>
        </div>
      )
    })

    const drawerButtonsAdd = [
      <Button key='cancel' onClick={this.handleHideDrawer}>
        <FormattedMessage id='cancel' />
      </Button>,
      <Button key='save' type='primary' onClick={this.handleAddCategory}>
        <FormattedMessage id='save' />
      </Button>
    ]

    const drawerButtonsEdit = [
      <Button key='cancel' onClick={this.handleHideDrawer}>
        <FormattedMessage id='cancel' />
      </Button>,
      <Button
        key='save'
        type='primary'
        onClick={this.handleSaveCategory}
        // disabled={this.state.recording && this.state.recording.isInvalid}
      >
        <FormattedMessage id='save' />
      </Button>
    ]
    if (
      this.props.auth.authUser.scope[0] === 'superadmin' &&
      this.state.category &&
      !this.state.category.isVerified
    ) {
      drawerButtonsEdit.unshift(
        <PopconfirmDelete key={3} onConfirmAction={this.handleDeleteCategory} />
      )
    }

    const {
      categories,
      readOnlyNewProject,
      projects,
      drawer,
      drawerOption,
      category,
      forms,
      loading,
      formLoading
    } = this.state

    const headerButtons = [
      <Button
        key={353258989832}
        type='primary'
        onClick={this.handleShowDrawerAdd}
      >
        <FormattedMessage id='create category' />
      </Button>
    ]

    return (
      <div>
        <FormattedMessage id='head.title.categories'>
          {title => (
            <Helmet>
              <title>{title}</title>
            </Helmet>
          )}
        </FormattedMessage>

        <PageTitle
          title={this.props.intl.formatMessage({ id: 'categories' })}
          buttons={headerButtons}
        />

        <Table
          // eslint-disable-next-line no-return-assign
          ref={ref => (this._table = ref)}
          rowKey={record => record._id}
          columns={columns}
          dataSource={categories}
          loading={loading}
          onChange={this.handleTableChange}
          onRow={record => ({
            onDoubleClick: () => {
              this.showDrawer(record)
            }
          })}
          defaultPageSize={this.state.pagination.defaultPageSize}
          pagination={this.state.pagination}
        />
        <DrawerComponent
          title={
            drawerOption === 'edit'
              ? this.props.intl.formatMessage({ id: 'edit category' })
              : this.props.intl.formatMessage({ id: 'add category' })
          }
          onClose={this.handleHideDrawer}
          visible={!!drawer}
          footerButtons={
            drawerOption === 'edit' ? drawerButtonsEdit : drawerButtonsAdd
          }
        >
          <CategoryForm
            option={drawerOption}
            category={category}
            projects={projects}
            formLoading={formLoading}
            readOnlyNewProject={readOnlyNewProject}
            fetchProjects={this.fetchProjects}
            fetchForms={this.fetchForms}
            updateCategory={this.updateCategory}
            form={this.props.form}
            formOptions={forms}
            edit={drawerOption === 'edit'}
          />
        </DrawerComponent>
      </div>
    )
  }
}

CategoriesPage.propTypes = {
  auth: PropTypes.object,
  history: PropTypes.object.isRequired,
  path: PropTypes.string.isRequired,
  selectedProject: PropTypes.object,
  location: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  project: PropTypes.object,
  intl: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  auth: state.auth,
  selectedProject: state.project.pickedProject,
  project: state.project
})

export default injectIntl(
  Form.create({ name: 'categoriesPage_form' })(
    connect(mapStateToProps)(CategoriesPage)
  )
)
