import React from 'react'

import moment from 'moment'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { injectIntl, FormattedMessage } from 'react-intl'
import { Helmet } from 'react-helmet'
import { Table, notification, Icon, Button, Form, Input, Badge } from 'antd'
import requestService from 'services/requests'
import workOrdersService from 'services/workOrders'
import isEqual from 'lodash/isEqual'
import RequestForm from 'components/Request/Form'
import DrawerComponent from 'components/Drawer'
import PageTitle from 'components/Global/PageTitle'

import './styles.scss'
import queryString from 'query-string'
import { setPickedProject } from '../../../ducks/project'

const statusColors = {
  Green: '#40c353',
  Yellow: '#ddab56',
  Red: '#c53e3d',
  Blue: '#015590'
}

export class RequestsPage extends React.Component {
  state = {
    requests: [],
    drawer: false,
    objectName: '',
    search: {
      name: '',
      aksNumber: ''
    },
    loading: false,
    pagination: {
      defaultPageSize: 10,
      showSizeChanger: true,
      pageSizeOptions: ['10', '20', '30', '50', '100']
    },
    createdByFilters: [],
    request: {},
    params: {}
  }

  getParams = () => {
    const { history } = this.props
    this._isMounted = true
    const searchString = history.location.search
    let searchQuery
    if (searchString.length) {
      searchQuery = queryString.parse(searchString, { arrayFormat: 'bracket' })
      if (searchQuery.page) {
        searchQuery.offset = searchQuery.page
        delete searchQuery.page
      }

      if (!searchQuery.status) {
        searchQuery.status = ['1']
      }

      if (!searchQuery.limit) {
        searchQuery.limit = 10
      }
      if (!searchQuery.sortField && !searchQuery.sortOrder) {
        searchQuery.sortField = 'createdAt'
        searchQuery.sortOrder = 'descend'
      }

      return searchQuery
    }
  }

  setParams = paramsObject => {
    const { path, history, project } = this.props
    if (project && project.project) {
      paramsObject.project = project.project._id
    }

    if (paramsObject.offset) {
      paramsObject.page = paramsObject.offset
      delete paramsObject.offset
    }

    if (paramsObject.limit === 10) {
      delete paramsObject.limit
    }
    if (
      (paramsObject.sortField === 'createdAt' &&
        paramsObject.sortOrder === 'descend') ||
      !paramsObject.sortOrder
    ) {
      delete paramsObject.sortField
      delete paramsObject.sortOrder
    }

    if (isEqual(paramsObject.status, ['1'])) {
      delete paramsObject.status
    }

    this.setState({ params: paramsObject })
    history.push({
      pathname: path,
      search: queryString.stringify(paramsObject, { arrayFormat: 'bracket' })
    })
  }

  componentDidMount () {
    const { path, history } = this.props

    if (!history.location.search) {
      history.push({
        pathname: path,
        search: queryString.stringify(
          { status: [1] },
          { arrayFormat: 'bracket' }
        )
      })
    } else {
      this.fetchRequests()
    }
  }

  componentDidUpdate (prevProps) {
    const {
      location: { search: prevSearch }
    } = prevProps
    const {
      location: { search: nextSearch },
      project,
      projects,
      dispatch
    } = this.props
    const params = { ...this.getParams() }

    if (params.project && !prevProps.projects.length && projects.length) {
      dispatch(
        setPickedProject(projects.find(item => item._id === params.project))
      )
    }

    if (project !== prevProps.project) {
      if (project && project._id) {
        this.setParams({ ...params, project: project._id })
      } else {
        delete params.project
        this.setParams(params)
      }
    }
    if (prevSearch !== nextSearch || this.props.project !== prevProps.project) {
      this.fetchRequests()
    }
  }

  static getDerivedStateFromProps (nextProps, prevState) {
    if (!nextProps.settings.data.buildx) {
      return
    }
    const { objectName } = nextProps.settings.data.buildx
    if (objectName !== prevState.objectName) {
      return {
        objectName: nextProps.settings.data.buildx.objectName
      }
    }
    return null
  }

  updateStateRequest = (field, value) => {
    this.setState({
      request: {
        ...this.state.request,
        [field]: value
      }
    })
  }

  createWorkOrder = async savedRequest => {
    const { name, description } = this.state.request
    this.props.form.validateFields(async err => {
      if (!err) {
        try {
          const payload = {
            name,
            description: description || ''
          }
          await workOrdersService.addWorkOrder(payload)

          notification.success({
            message: this.props.intl.formatMessage({
              id: 'workorder created'
            })
          })

          this.setState({
            drawer: false,
            request: {},
            requests: this.sortList([
              ...this.state.requests.filter(
                request => request._id !== savedRequest._id
              ),
              savedRequest
            ])
          })
        } catch (error) {
          notification.error({
            message: this.props.intl.formatMessage({
              id: 'saving error'
            }),
            description: error.message
          })
        }
      }
    })
  }

  handleApproveRequst = async () => {
    const { _id } = this.state.request
    this.props.form.validateFields(async err => {
      if (!err) {
        try {
          const payload = {
            status: 2
          }
          const savedRequest = await requestService.updateRequest(_id, payload)

          this.createWorkOrder(savedRequest)
          this.fetchRequests()

          this.setState({
            drawer: false,
            request: {},
            requests: this.sortList([
              ...this.state.requests.filter(
                request => request._id !== savedRequest._id
              ),
              savedRequest
            ])
          })
        } catch (error) {
          notification.error({
            message: this.props.intl.formatMessage({
              id: 'saving error'
            }),
            description: error.message
          })
        }
      }
    })
  }

  rejectRequst = async status => {
    const { _id } = this.state.request
    this.props.form.validateFields(async err => {
      if (!err) {
        try {
          const payload = {
            status
          }

          const savedRequest = await requestService.updateRequest(_id, payload)

          this.fetchRequests()
          notification.success({
            message: `${this.props.intl.formatMessage({
              id: 'updated successfully'
            })}`
          })

          this.setState({
            drawer: false,
            request: {},
            requests: this.sortList([
              ...this.state.requests.filter(
                request => request._id !== savedRequest._id
              ),
              savedRequest
            ])
          })
        } catch (error) {
          notification.error({
            message: this.props.intl.formatMessage({
              id: 'saving error'
            }),
            description: error.message
          })
        }
      }
    })
  }

  sortList = list => {
    return list && list.sort(sortByCreatedAt)
  }

  fetchRequests = async () => {
    const params = this.getParams()

    this.setState({
      loading: true
    })
    try {
      const requests = await requestService.listV2({
        ...params
      })

      const pagination = {
        ...this.state.pagination
      }

      pagination.total = requests.totalDocs

      this.setState({
        requests: this.sortList(requests.docs),
        createdByFilters: requests.filterOptions.createdBy,
        loading: false,
        pagination: {
          ...pagination,
          pageSize: requests.limit,
          current: requests.page
        },
        params: { ...params }
      })
    } catch (error) {
      notification.error({
        message: `${this.props.intl.formatMessage({
          id: 'requests'
        })} - download error`
      })

      this.setState({
        drawer: false,
        loading: false
      })
    }
  }

  showDrawer = async request => {
    this.setState({
      drawer: true,
      request
    })
  }

  handleHideDrawer = () => {
    this.setState({
      drawer: false
    })
  }

  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination }
    pager.offset = pagination.current
    const params = {
      limit: pagination.pageSize,
      offset: pagination.current,
      sortField: sorter.field,
      sortOrder: sorter.order,
      ...filters
    }
    this.setParams(params)
  }

  handleSearch = (selectedKeys, field, confirm) => {
    confirm()
    this.setState({
      search: {
        ...this.state.search,
        [field]: selectedKeys[0]
      }
    })
  }

  handleReset = (field, clearFilters) => {
    clearFilters()
    const { params } = this.state
    const outputObject = { ...params }
    delete outputObject[field]
    this.setParams(outputObject)
    this.setState({
      [field + 'Search']: undefined
    })
  }

  setSelectedKeys = (dataIndex, value, setSelectedKeys) => {
    setSelectedKeys(value ? [value] : [])
    this.setState({
      [dataIndex + 'Search']: value
    })
  }

  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this[`searchInput${dataIndex}`] = node
          }}
          placeholder={this.props.intl.formatMessage({ id: 'search' })}
          value={
            this.state[dataIndex + 'Search'] !== undefined
              ? this.state[dataIndex + 'Search']
              : this.state.params[dataIndex]
          }
          onChange={e =>
            this.setSelectedKeys(dataIndex, e.target.value, setSelectedKeys)
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, dataIndex, confirm)
          }
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type='primary'
          onClick={() => this.handleSearch(selectedKeys, dataIndex, confirm)}
          icon='search'
          size='small'
          disabled={!this.state[dataIndex + 'Search']}
          style={{ width: 90, marginRight: 8 }}
        >
          Suchen
        </Button>
        <Button
          onClick={() => this.handleReset(dataIndex, clearFilters)}
          size='small'
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon
        type='search'
        style={{
          color:
            this.state.params[dataIndex] || filtered ? '#1890ff' : undefined
        }}
      />
    ),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this[`searchInput${dataIndex}`].select())
      }
    }
  })

  render () {
    const columns = [
      {
        title: this.props.intl.formatMessage({ id: 'number' }),
        key: 'docNumber',
        dataIndex: 'docNumber',
        sorter: true,
        ...this.getColumnSearchProps('docNumber')
      },
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        sorter: true,
        ...this.getColumnSearchProps('name')
      },
      {
        title: this.state.objectName,
        key: 'objectId',
        dataIndex: 'objectId',
        sorter: true,
        render: (text, record) => {
          if (record.objectId && record.objectId.name) {
            return record.objectId.name
          }

          return ''
        },
        ...this.getColumnSearchProps('objectId')
      },
      {
        title: this.props.intl.formatMessage({ id: 'status' }),
        dataIndex: 'status',
        key: 'status',
        sorter: true,
        render: status => {
          let backgroundColor = ''
          let statusText = ''
          switch (status) {
            case 1: {
              statusText = this.props.intl.formatMessage({ id: 'open' })
              backgroundColor = statusColors.Blue
              break
            }
            case 2: {
              statusText = this.props.intl.formatMessage({
                id: 'approved'
              })
              backgroundColor = statusColors.Green
              break
            }
            case 3: {
              statusText = this.props.intl.formatMessage({
                id: 'rejected'
              })
              backgroundColor = statusColors.Red
              break
            }
            default:
              statusText = this.props.intl.formatMessage({ id: 'open' })
              backgroundColor = statusColors.Blue
          }

          return <Badge count={statusText} style={{ backgroundColor }} />
        },
        filters: [
          {
            text: this.props.intl.formatMessage({ id: 'open' }),
            // value: 'open'
            value: 1
          },
          {
            text: this.props.intl.formatMessage({ id: 'approved' }),
            // value: 'approved'
            value: 2
          },
          {
            text: this.props.intl.formatMessage({ id: 'rejected' }),
            // value: 'rejected'
            value: 3
          }
        ],
        filteredValue: this.state.params.status
      },
      {
        title: this.props.intl.formatMessage({ id: 'created at' }),
        dataIndex: 'createdAt',
        key: 'createdAt',
        sorter: true,
        render: createdAt => moment(createdAt).format('DD.MM.YYYY, HH:mm'),
        defaultSortOrder:
          this.state.params.sortField === 'createdAt'
            ? this.state.params.sortOrder
            : 'descend'
      },
      {
        title: this.props.intl.formatMessage({ id: 'created by' }),
        dataIndex: 'createdBy',
        key: 'createdBy',
        render: createdBy =>
          createdBy ? `${createdBy.firstName} ${createdBy.lastName}` : '',
        filters: this.state.createdByFilters.map(user => ({
          text: `${user.firstName} ${user.lastName}`,
          value: user._id
        })),
        filteredValue: this.state.params.createdBy
      }
    ]

    if (!this.props.project) {
      columns.push({
        title: this.props.intl.formatMessage({ id: 'project' }),
        key: 'project',
        dataIndex: 'project.projectName'
      })
    }

    columns.push({
      key: 'actions',
      render: (text, record) => (
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'flex-end'
          }}
        >
          <Button onClick={() => this.showDrawer(record)}>
            <Icon type='edit' />
          </Button>
        </div>
      )
    })

    const drawerButtons = [
      <Button
        key='cancel'
        onClick={this.handleHideDrawer}
        style={{
          float: 'left'
        }}
      >
        <FormattedMessage id='cancel' />
      </Button>,
      <Button
        className='reject-button'
        key='reject'
        type='danger'
        onClick={() => this.rejectRequst(3)}
        disabled={this.state.request.status > 1}
      >
        <FormattedMessage id='reject' />
      </Button>,
      <Button
        style={{ marginLeft: 8 }}
        className='approve-button'
        key='approve'
        type='primary'
        onClick={this.handleApproveRequst}
        disabled={this.state.request.status > 1}
      >
        <FormattedMessage id='approve' />
      </Button>
    ]

    return (
      <div>
        <FormattedMessage id='head.title.requests'>
          {title => (
            <Helmet>
              <title>{title}</title>
            </Helmet>
          )}
        </FormattedMessage>

        <PageTitle title={this.props.intl.formatMessage({ id: 'requests' })} />

        <Table
          // eslint-disable-next-line no-return-assign
          ref={ref => (this._table = ref)}
          rowKey={record => record._id}
          columns={columns}
          dataSource={this.state.requests}
          loading={this.state.loading}
          onChange={this.handleTableChange}
          onRow={record => ({
            onDoubleClick: () => {
              this.showDrawer(record)
            }
          })}
          pagination={this.state.pagination}
        />
        <DrawerComponent
          title={this.state.request.name}
          onClose={this.handleHideDrawer}
          visible={!!this.state.drawer}
          footerButtons={drawerButtons}
        >
          <RequestForm
            request={this.state.request}
            objectName={this.state.objectName}
            updateRequest={this.updateStateRequest}
          />
        </DrawerComponent>
      </div>
    )
  }
}

const sortByCreatedAt = (a, b) => {
  return new Date(b.createdAt) - new Date(a.createdAt)
}

RequestsPage.propTypes = {
  history: PropTypes.object.isRequired,
  path: PropTypes.string.isRequired,
  project: PropTypes.object,
  location: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  settings: PropTypes.object,
  form: PropTypes.object,
  intl: PropTypes.object.isRequired,
  projects: PropTypes.array.isRequired
}

const mapStateToProps = state => ({
  auth: state.auth,
  settings: state.settings,
  projects: state.project.projectList,
  project: state.project.pickedProject
})

export default injectIntl(
  Form.create({ name: 'requests_form' })(connect(mapStateToProps)(RequestsPage))
)
