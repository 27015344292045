import React from 'react'

import moment from 'moment'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { injectIntl, FormattedMessage } from 'react-intl'
import queryString from 'query-string'
import { withRouter } from 'react-router-dom'
import { Table, Icon, notification, Input, Menu, Dropdown, Tag } from 'antd'

import Button from 'components/Global/Button'
import RecordingStatusIcon from 'components/Global/RecordingStatusIcon'

import {
  fetchRecordings,
  handleRecordingPick,
  setRecordingQueryParams,
  setRecordingDefectCreationData,
  archiveRecording,
  unarchiveRecording,
  downloadPDF
} from '../../../../../ducks/recordings'
import { setPickedProject } from '../../../../../ducks/project'
import { findObject } from '../../../../../ducks/object'
import TableObjectSearch from './components/TableObjectSearch'

export class RecordingsTable extends React.Component {
  state = {
    loading: false,
    pagination: {
      defaultPageSize: 10,
      showSizeChanger: true,
      pageSizeOptions: ['10', '20', '30', '50', '100']
    },
    selectedRowKeys: [],
    statusFilters: [],
    archivedFilters: ['showUnarchived'],
    createdByFilters: [],
    recordingCustomFormDefault: {},
    params: {}
  }

  requestRecordings = async () => {
    const { dispatch } = this.props
    const params = await this.getParams()
    this.setState({
      loading: true
    })
    dispatch(
      fetchRecordings({
        limit: this.state.defaultPageSize,
        ...params
      })
    ).then(data => {
      this.handleRecordingsResponse(data)
    })
  }

  componentDidMount () {
    this.requestRecordings()
    const { history, dispatch } = this.props
    const { objectIdSearch, params } = this.state
    const searchString = history.location.search
    let searchQuery
    if (searchString && searchString.length) {
      searchQuery = queryString.parse(searchString, { arrayFormat: 'bracket' })
    }

    if (searchQuery && searchQuery.objectId) {
      if (!objectIdSearch) {
        dispatch(findObject(searchQuery.objectId[0])).then(response => {
          if (response._id) {
            this.setState({
              objectIdSearch: response
            })
          } else {
            this.setState({
              objectIdSearch: '',
              params: {
                ...params,
                objectId: ''
              }
            })
          }
        })
      }
    }
  }

  componentDidUpdate (prevProps, prevState) {
    const {
      location: { search: prevSearch }
    } = prevProps
    const {
      location: { search: nextSearch },
      dispatch,
      project
    } = this.props
    const params = { ...this.getParams() }

    // if (
    //   params.project &&
    //   !prevProps.project.projectList.length &&
    //   project.projectList.length
    // ) {
    //   dispatch(
    //     setPickedProject(
    //       project.projectList.find(item => item._id === params.project)
    //     )
    //   )
    // }

    if (project.pickedProject !== prevProps.project.pickedProject) {
      if (project && project.pickedProject) {
        this.setParams({ ...params, project: project.pickedProject._id })
      } else {
        delete params.project
        this.setParams(params)
      }
    }

    if (
      prevSearch !== nextSearch ||
      project.pickedProject !== prevProps.project.pickedProject
    ) {
      this.requestRecordings()
    }
  }

  getParams = async () => {
    const { history } = this.props
    const searchString = history.location.search
    let searchQuery
    if (searchString.length) {
      searchQuery = queryString.parse(searchString, { arrayFormat: 'bracket' })
      if (searchQuery.page) {
        searchQuery.offset = searchQuery.page
        delete searchQuery.page
      }
      if (!searchQuery.limit) {
        searchQuery.limit = 10
      }

      if (!searchQuery.sortField && !searchQuery.sortOrder) {
        searchQuery.sortField = 'createdAt'
        searchQuery.sortOrder = 'descend'
      }
      return searchQuery
    }
  }

  setParams = paramsObject => {
    const { history, project } = this.props
    if (project && project.pickedProject) {
      paramsObject.project = project.pickedProject._id
    }
    if (paramsObject.offset) {
      paramsObject.page = paramsObject.offset
      delete paramsObject.offset
    }
    if (paramsObject.limit === 10) {
      delete paramsObject.limit
    }
    if (
      (paramsObject.sortField === 'createdAt' &&
        paramsObject.sortOrder === 'descend') ||
      !paramsObject.sortOrder
    ) {
      delete paramsObject.sortField
      delete paramsObject.sortOrder
    }

    this.setState({ params: paramsObject })
    history.push({
      search: queryString.stringify(paramsObject, { arrayFormat: 'bracket' })
    })
  }

  handleRecordingsResponse = async recordings => {
    const { dispatch } = this.props
    const params = await this.getParams()
    const pagination = {
      ...this.state.pagination
    }
    pagination.total = recordings.totalDocs
    this.setState({
      createdByFilters: recordings.filterOptions.createdBy,
      loading: false,
      pagination: {
        ...pagination,
        pageSize: recordings.limit,
        current: recordings.page
      },
      params: { ...params }
    })
    dispatch(
      setRecordingQueryParams({
        ...params,
        limit: this.state.defaultPageSize
      })
    )
  }

  showDrawer = recording => {
    const { dispatch } = this.props
    dispatch(handleRecordingPick(recording))
  }

  handleArchiveState = (id, method, string) => {
    const { dispatch } = this.props
    dispatch(method(id)).then(response => {
      if (!response.error) {
        notification.success({
          message: `${this.props.intl.formatMessage({
            id: `${string}d successfully`
          })}`
        })
        this.requestRecordings()
      } else {
        notification.error({
          message: this.props.intl.formatMessage({
            id: `${string}d error`
          }),
          description: response.error
        })
      }
    })
  }

  archive = async recording => {
    this.handleArchiveState(recording._id, archiveRecording, 'archive')
  }

  unarchive = async recording => {
    this.handleArchiveState(recording._id, unarchiveRecording, 'unarchive')
  }

  showPDF = recording => {
    const { dispatch } = this.props
    dispatch(downloadPDF(recording))
  }

  setSelectedKeys = (dataIndex, value, setSelectedKeys) => {
    setSelectedKeys(value ? [value] : [])
    this.setState({
      [dataIndex + 'Search']: value
    })
  }

  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => {
      const stateValue = this.state[dataIndex + 'Search']
      const stateParamsValue = this.state.params[dataIndex]
      return (
        <div style={{ padding: 8 }}>
          {dataIndex === 'objectId' && (
            <TableObjectSearch
              value={
                this.state[dataIndex + 'Search'] !== undefined
                  ? stateValue && stateValue.name
                    ? stateValue.name
                    : stateValue
                  : stateParamsValue && stateParamsValue.name
                  ? stateParamsValue.name
                  : stateParamsValue
              }
              dataIndex={dataIndex}
              setSelectedKeys={setSelectedKeys}
              onPick={object =>
                this.setSelectedKeys(dataIndex, object, setSelectedKeys)
              }
            />
          )}
          <Input
            ref={node => {
              this[`searchInput${dataIndex}`] = node
            }}
            placeholder={this.props.intl.formatMessage({ id: 'search' })}
            value={
              this.state[dataIndex + 'Search'] !== undefined
                ? stateValue
                : stateParamsValue
            }
            onChange={e =>
              this.setSelectedKeys(dataIndex, e.target.value, setSelectedKeys)
            }
            onPressEnter={() =>
              this.handleSearch(selectedKeys, dataIndex, confirm)
            }
            style={{
              width: 188,
              marginBottom: 8,
              display: dataIndex === 'objectId' ? 'none' : 'block'
            }}
          />

          <Button
            onClick={() => this.handleSearch(selectedKeys, dataIndex, confirm)}
            icon='search'
            size='small'
            disabled={!this.state[dataIndex + 'Search']}
            value='Suchen'
            style={{ width: 90, marginRight: 8 }}
          />

          <Button
            onClick={() => this.handleReset(dataIndex, clearFilters)}
            size='small'
            type='secondary'
            value='Reset'
            style={{ width: 90 }}
          />
        </div>
      )
    },
    filterIcon: filtered => {
      return (
        <Icon
          type='search'
          style={{
            color:
              this.state.params[dataIndex] || filtered ? '#1890ff' : undefined
          }}
        />
      )
    },
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this[`searchInput${dataIndex}`].select())
      }
    }
  })

  handleSearch = (selectedKeys, field, confirm) => {
    confirm()
    this.setState({
      search: {
        ...this.state.search,
        [field]: selectedKeys[0]
      }
    })
  }

  handleReset = (field, clearFilters) => {
    clearFilters()
    const { params } = this.state
    const outputObject = { ...params }
    delete outputObject[field]
    this.setParams(outputObject)
    this.setState({
      [field + 'Search']: undefined
    })
  }

  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination }
    const stateParams = { ...this.state.params }
    pager.offset = pagination.current
    const params = {
      ...stateParams,
      limit: pagination.pageSize,
      offset: pagination.current,
      sortField: sorter.field,
      sortOrder: sorter.order,
      ...filters
    }
    Object.keys(filters).forEach(key => {
      if (filters[key].length === 0) {
        delete params[key]
      }
      if (key === 'objectId') {
        params.objectId = [filters.objectId[0]._id]
      }
    })
    this.setParams(params)
  }

  createDefect = record => {
    const { dispatch } = this.props
    dispatch(setRecordingDefectCreationData({ income: record, value: {} }))
  }

  render () {
    const { params, pagination, createdByFilters, loading } = this.state
    const {
      intl,
      recordings,
      settings,
      project: { pickedProject }
    } = this.props

    const sapEnabled = settings.data.apps && settings.data.apps.sap
    const objectName = (settings.data.buildx || {}).objectName || ''

    const columns = [
      {
        title: intl.formatMessage({ id: 'status' }),
        key: 'status',
        align: 'center',
        render: (text, record) => {
          let status = record.status || ''
          if (!status) {
            if (record.isVerified) {
              status = 'approved'
            } else if (record.isInvalid) {
              status = 'invalid'
            } else if (record.isRejected) {
              status = 'rejected'
            }
          }

          return <RecordingStatusIcon status={status} />
        },
        filters: [
          {
            text: intl.formatMessage({ id: 'approved' }),
            value: 'approved'
          },
          {
            text: intl.formatMessage({ id: 'opened' }),
            value: 'opened'
          },
          {
            text: intl.formatMessage({ id: 'rejected' }),
            value: 'rejected'
          },
          {
            text: intl.formatMessage({ id: 'invalid' }),
            value: 'invalid'
          }
        ],
        filteredValue: params.status
      },
      {
        title: intl.formatMessage({ id: 'number' }),
        key: 'docNumber',
        dataIndex: 'docNumber',
        ...this.getColumnSearchProps('docNumber')
      },
      {
        title: 'Name',
        key: 'name',
        dataIndex: 'name',
        sorter: true,
        ...this.getColumnSearchProps('name')
      },
      {
        title: objectName,
        key: 'objectId',
        dataIndex: 'objectId',
        sorter: true,
        render: (text, record) => {
          if (record.objectId && record.objectId.name) {
            return record.objectId.name
          }

          return ''
        },
        ...this.getColumnSearchProps('objectId')
      },
      {
        title: intl.formatMessage({ id: 'archived' }),
        key: 'archived',
        dataIndex: 'archived',
        align: 'center',
        render: active =>
          active && <Icon type='check' style={{ color: '#005591' }} />,
        filters: [
          {
            text: intl.formatMessage({
              id: 'show all'
            }),
            value: 'showAll'
          },
          {
            text: intl.formatMessage({
              id: 'show unarchived'
            }),
            value: 'showUnarchived'
          },
          {
            text: intl.formatMessage({
              id: 'show archived'
            }),
            value: 'showArchived'
          }
        ],
        filterMultiple: false,
        filteredValue: params.archived
      },
      {
        title: intl.formatMessage({ id: 'created at' }),
        key: 'createdAt',
        dataIndex: 'createdAt',
        sorter: true,
        render: createdAt => moment(createdAt).format('DD.MM.YYYY, HH:mm'),
        defaultSortOrder:
          params.sortField === 'createdAt' ? params.sortOrder : 'descend'
      },
      {
        title: intl.formatMessage({ id: 'created by' }),
        key: 'createdBy',
        dataIndex: 'createdBy',
        render: createdBy =>
          createdBy ? `${createdBy.firstName} ${createdBy.lastName}` : '',
        filters: createdByFilters.map(user => ({
          text: `${user.firstName} ${user.lastName}`,
          value: user._id
        })),
        filteredValue: params.createdBy
      },
      {
        title: intl.formatMessage({ id: 'reference number' }),
        key: 'submittedRefNr',
        dataIndex: 'submittedRefNr',
        ...this.getColumnSearchProps('submittedRefNr'),
        render: (text, record) => {
          const data = []

          if (record.submittedWorkorder && record.submittedWorkorderId) {
            data.push(
              <span key='1'>
                <Tag color='blue'>
                  <Icon type='check' /> Workorder (
                  {record.submittedWorkorderId.docNumber})
                </Tag>
                &nbsp;&nbsp;
              </span>
            )
          }

          if (record.submittedTask && record.submittedTaskId) {
            data.push(
              <span key='2'>
                <Tag color='blue'>
                  <Icon type='check' /> Task ({record.submittedTaskId.docNumber}
                  )
                </Tag>
                &nbsp;&nbsp;
              </span>
            )
          }

          if (record.submitted) {
            data.push(
              <span key='3'>
                <Tag color='blue'>
                  <Icon type='check' /> SAP ({record.submittedRefNr})
                </Tag>
              </span>
            )
          }

          if (
            record.submissions &&
            record.submissions.some(e => e.name === 'defect')
          ) {
            data.push(
              <span key='4'>
                <Tag color='blue'>
                  <Icon type='check' />
                  &nbsp;
                  <FormattedMessage id='defect' />
                  &nbsp; (
                  {record.submissions.map((a, index) => (
                    <span key={index}>{a.reference}</span>
                  ))}
                  )
                </Tag>
              </span>
            )
          }

          return data
        }
      }
    ]

    if (!pickedProject) {
      columns.push({
        title: intl.formatMessage({ id: 'project' }),
        key: 'project',
        dataIndex: 'project.projectName'
      })
    }

    columns.push({
      key: 'actions',
      render: (text, record) => (
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'flex-end'
          }}
        >
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item key='0' onClick={() => this.showDrawer(record)}>
                  <Icon type={text.status === 'approved' ? 'eye' : 'edit'} />
                  {intl.formatMessage({
                    id: text.status === 'approved' ? 'view' : 'edit'
                  })}
                </Menu.Item>
                <Menu.Item key='1' onClick={() => this.showPDF(record)}>
                  <Icon type='file-pdf' />
                  <FormattedMessage id='generate pdf' />
                </Menu.Item>

                {!record.archived && (
                  <Menu.Item key='2' onClick={() => this.archive(record)}>
                    <Icon type='inbox' />
                    <FormattedMessage id='archive' />
                  </Menu.Item>
                )}

                {record.archived && (
                  <Menu.Item key='2' onClick={() => this.unarchive(record)}>
                    <Icon type='inbox' />
                    <FormattedMessage id='unarchive' />
                  </Menu.Item>
                )}

                <Menu.Divider />

                {sapEnabled && record.submitted && (
                  <Menu.Item key='3' disabled={record.submitted}>
                    <Icon type='check' />
                    &nbsp;
                    <FormattedMessage id='submitted to sap' />
                  </Menu.Item>
                )}

                {record.submissions &&
                  !record.submissions.some(e => e.name === 'defect') &&
                  !record.archived && (
                    <Menu.Item
                      key='5'
                      onClick={() => this.createDefect(record)}
                    >
                      <Icon type='plus' />
                      &nbsp;
                      <FormattedMessage id='create defect mainx' />
                    </Menu.Item>
                  )}

                {record.submissions &&
                  record.submissions.some(e => e.name === 'defect') && (
                    <Menu.Item disabled key='5'>
                      <Icon type='check' />
                      &nbsp;
                      <FormattedMessage id='defect created mainx' />
                    </Menu.Item>
                  )}

                {record.submittedWorkorder && (
                  <Menu.Item disabled key='5'>
                    <Icon type='check' />
                    &nbsp;
                    <FormattedMessage id='mainx workorder created' />
                  </Menu.Item>
                )}
              </Menu>
            }
            trigger={['click']}
          >
            <a className='ant-dropdown-link' href='#'>
              <Icon type='more' style={{ fontSize: '2rem', color: '#444' }} />
            </a>
          </Dropdown>
        </div>
      )
    })

    if (!sapEnabled) {
      const columnIndex = columns.findIndex(x => x.key === 'submittedRefNr')
      if (columnIndex) columns.splice(columnIndex, 1)
    }

    return (
      <Table
        key={JSON.stringify(params)}
        ref={ref => (this._table = ref)}
        rowKey={record => record._id}
        loading={loading}
        columns={columns}
        dataSource={recordings}
        onChange={this.handleTableChange}
        onRow={record => ({ onDoubleClick: () => this.showDrawer(record) })}
        defaultPageSize={pagination.defaultPageSize}
        pagination={pagination}
      />
    )
  }
}

RecordingsTable.propTypes = {
  settings: PropTypes.object.isRequired,
  recordings: PropTypes.array,
  project: PropTypes.object,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  settings: state.settings,
  project: state.project,
  recordings: state.recordings.recordingsData.docs,
  objects: state.objects.searchedObjectsList
})

export default withRouter(
  injectIntl(connect(mapStateToProps, null)(RecordingsTable))
)
