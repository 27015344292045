import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { Tabs, Form, Input, Select, Checkbox, Spin } from 'antd'

const { TabPane } = Tabs

const Option = Select.Option

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
  className: 'formItem'
}

class ProductForm extends React.Component {
  renderSelect = (categoryType, array) => {
    let key = 0
    const selectItems = array.map(category => {
      key += 1
      return (
        <Option
          key={`${category.name}-${key}`}
          id={category._id}
          value={category.name}
        >
          {category.name}
        </Option>
      )
    })
    return (
      <Select
        showSearch
        allowClear
        defaultValue={
          this.props.product[categoryType] &&
          this.props.product[categoryType].name
            ? this.props.product[categoryType].name
            : ''
        }
        placeholder={
          this.props.product[categoryType] === undefined ||
          this.props.product[categoryType] === null
            ? `Choose ${categoryType}`
            : false
        }
        // optionFilterProp="children"
        notFoundContent={
          this.props.formLoading ? (
            <div className='products-spinner-container'>
              <Spin size='small' />
            </div>
          ) : null
        }
        onFocus={() => this.props.handleSearchAutocomplete(categoryType, '')}
        onSearch={value =>
          this.props.handleSearchAutocomplete(categoryType, value)
        }
        onSelect={(value, e) =>
          this.props.updateStateProductObject(categoryType, 'name', value, e)
        }
        // disabled={this.props.readOnly}
      >
        {selectItems}
      </Select>
    )
  }

  render () {
    const {
      name,
      ordernumber,
      description,
      ean,
      width,
      height,
      depth,
      supplierNumber,
      length,
      price,
      project,
      active
    } = this.props.product

    const { getFieldDecorator } = this.props.form

    return (
      <>
        <Tabs defaultActiveKey='informations'>
          <TabPane
            tab={this.props.intl.formatMessage({ id: 'informations' })}
            key='informations'
          >
            <Form className='workOrdersForm'>
              <Form.Item {...formItemLayout} label='Name'>
                {getFieldDecorator('name', {
                  initialValue: name,
                  rules: [
                    {
                      required: true,
                      message: this.props.intl.formatMessage({
                        id: 'name is required'
                      })
                    }
                  ]
                })(
                  <Input
                    onChange={event =>
                      this.props.updateProduct('name', event.target.value)
                    }
                  />
                )}
              </Form.Item>
              <Form.Item
                {...formItemLayout}
                label={this.props.intl.formatMessage({ id: 'project' })}
              >
                <Select
                  showSearch
                  allowClear
                  style={{ width: '100%' }}
                  optionFilterProp='children'
                  notFoundContent={
                    this.props.formLoading ? (
                      <div className='objects-spinner-container'>
                        <Spin size='small' />
                      </div>
                    ) : null
                  }
                  onFocus={() => this.props.fetchProjectsOnSearch('')}
                  onSearch={value => this.props.fetchProjectsOnSearch(value)}
                  onChange={projectID =>
                    this.props.updateProduct('project', projectID)
                  }
                  defaultValue={project ? project.projectName : ''}
                  disabled={this.props.readOnlyNewProject}
                >
                  {this.props.projects.map(project => (
                    <Select.Option key={project._id} value={project._id}>
                      {project.projectName}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                {...formItemLayout}
                label={this.props.intl.formatMessage({ id: 'ordernumber' })}
              >
                {getFieldDecorator('ordernumber', {
                  initialValue: ordernumber,
                  rules: [
                    {
                      required: true,
                      message: this.props.intl.formatMessage({
                        id: 'ordernumber is required'
                      })
                    }
                  ]
                })(
                  <Input
                    onChange={event =>
                      this.props.updateProduct(
                        'ordernumber',
                        event.target.value
                      )
                    }
                  />
                )}
                {/* <Input
                  value={ordernumber}
                  onChange={event =>
                    this.props.updateProduct('ordernumber', event.target.value)
                  }
                /> */}
              </Form.Item>
              <Form.Item
                {...formItemLayout}
                label={this.props.intl.formatMessage({ id: 'description' })}
              >
                <Input.TextArea
                  value={description}
                  onChange={event =>
                    this.props.updateProduct('description', event.target.value)
                  }
                />
              </Form.Item>

              {this.props.project && (
                <Form.Item
                  {...formItemLayout}
                  label={this.props.intl.formatMessage({ id: 'ordernumber' })}
                >
                  <Input
                    value={project}
                    onChange={event =>
                      this.props.updateProduct('project', event.target.value)
                    }
                  />
                </Form.Item>
              )}
              <Form.Item
                {...formItemLayout}
                label={this.props.intl.formatMessage({ id: 'manufacturer' })}
              >
                {this.renderSelect('manufacturer', this.props.manufacturers)}
              </Form.Item>
              <Form.Item
                {...formItemLayout}
                label={this.props.intl.formatMessage({ id: 'category' })}
              >
                {this.renderSelect('category', this.props.categories)}
              </Form.Item>
              <Form.Item
                {...formItemLayout}
                label={this.props.intl.formatMessage({ id: 'price' })}
              >
                <Input
                  className='product-form-price'
                  value={
                    this.props.priceFocused
                      ? price
                      : price
                      ? `CHF ${price}`
                      : 'CHF'
                  }
                  onChange={event =>
                    this.props.updateProduct('price', event.target.value, true)
                  }
                  onBlur={event =>
                    this.props.onBlurNumber('price', event.target.value)
                  }
                  onFocus={event => this.props.onFocusPrice(event.target.value)}
                />
              </Form.Item>
              <Form.Item
                {...formItemLayout}
                label={this.props.intl.formatMessage({ id: 'active' })}
              >
                <Checkbox
                  checked={active}
                  onChange={e =>
                    this.props.updateProduct('active', e.target.checked)
                  }
                />
              </Form.Item>
            </Form>
          </TabPane>
          <TabPane
            tab={this.props.intl.formatMessage({ id: 'settings' })}
            key='settings'
          >
            <Form className='workOrdersForm'>
              <Form.Item {...formItemLayout} label='EAN'>
                <Input
                  value={ean}
                  onChange={event =>
                    this.props.updateProduct('ean', event.target.value)
                  }
                />
              </Form.Item>
              <Form.Item
                {...formItemLayout}
                label={this.props.intl.formatMessage({ id: 'width' })}
              >
                <Input
                  value={width}
                  onChange={event =>
                    this.props.updateProduct('width', event.target.value, true)
                  }
                  onBlur={event =>
                    this.props.onBlurNumber('width', event.target.value)
                  }
                />
              </Form.Item>
              <Form.Item
                {...formItemLayout}
                label={this.props.intl.formatMessage({ id: 'height' })}
              >
                <Input
                  value={height}
                  onChange={event =>
                    this.props.updateProduct('height', event.target.value, true)
                  }
                  onBlur={event =>
                    this.props.onBlurNumber('height', event.target.value)
                  }
                />
              </Form.Item>
              <Form.Item
                {...formItemLayout}
                label={this.props.intl.formatMessage({ id: 'depth' })}
              >
                <Input
                  value={depth}
                  onChange={event =>
                    this.props.updateProduct('depth', event.target.value, true)
                  }
                  onBlur={event =>
                    this.props.onBlurNumber('depth', event.target.value)
                  }
                />
              </Form.Item>
              <Form.Item
                {...formItemLayout}
                label={this.props.intl.formatMessage({ id: 'supplier number' })}
              >
                <Input
                  value={supplierNumber}
                  onChange={event =>
                    this.props.updateProduct(
                      'supplierNumber',
                      event.target.value
                    )
                  }
                />
              </Form.Item>
              <Form.Item
                {...formItemLayout}
                label={this.props.intl.formatMessage({ id: 'length' })}
              >
                <Input
                  value={length}
                  onChange={event =>
                    this.props.updateProduct('length', event.target.value, true)
                  }
                  onBlur={event =>
                    this.props.onBlurNumber('length', event.target.value)
                  }
                />
              </Form.Item>
            </Form>
          </TabPane>
        </Tabs>
      </>
    )
  }
}

ProductForm.propTypes = {
  intl: PropTypes.object.isRequired,
  onBlurNumber: PropTypes.func.isRequired,
  updateProduct: PropTypes.func.isRequired,
  product: PropTypes.object,
  formLoading: PropTypes.bool,
  handleSearchAutocomplete: PropTypes.func.isRequired,
  updateStateProductObject: PropTypes.func.isRequired,
  fetchProjectsOnSearch: PropTypes.func.isRequired,
  readOnlyNewProject: PropTypes.bool,
  form: PropTypes.object,
  project: PropTypes.object,
  projects: PropTypes.array,
  manufacturers: PropTypes.array,
  categories: PropTypes.array,
  priceFocused: PropTypes.bool,
  onFocusPrice: PropTypes.func
}

export default injectIntl(connect()(ProductForm))
