import React from 'react'
import { Menu, Icon } from 'antd'
import { NavLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import './style.scss'

const Sidebar = props => {
  const roles = props.auth.authUser.scope || []
  const nonStandarUser = roles[0] === 'admin' || roles[0] === 'superadmin'

  const openedSubMenus = []
  if (
    [
      '/contract-sections',
      '/forms',
      '/groups',
      '/locations',
      '/modules',
      '/objects',
      '/projects',
      '/rights',
      '/settings',
      '/users',
      '/contacts'
    ].includes(window.location.pathname)
  ) {
    openedSubMenus.push('settingsSubMenu')
  }

  if (['/recordings', '/acceptances'].includes(window.location.pathname)) {
    openedSubMenus.push('buildxSubMenu')
  }

  if (
    [
      '/requests',
      '/workorders',
      '/specialwork',
      '/scheduled-workorders',
      '/protocol',
      '/defects'
    ].includes(window.location.pathname)
  ) {
    openedSubMenus.push('mainxSubMenu')
  }

  if (
    ['/products', '/manufactures', '/categories'].includes(
      window.location.pathname
    )
  ) {
    openedSubMenus.push('dataxSubMenu')
  }

  const modules = {
    buildx: (props.settings.data.buildx || {}).enabled,
    mainx: (props.settings.data.mainx || {}).enabled,
    datax: (props.settings.data.datax || {}).enabled,
    doclink: (props.settings.data.doclink || {}).enabled
  }
  return (
    <>
      <div className='logo'>
        <img src='/images/ddSuite-icon.svg' alt='logo' />
      </div>

      <Menu
        theme='dark'
        mode='inline'
        style={{ marginBottom: 50 }}
        selectedKeys={[window.location.pathname]}
        defaultOpenKeys={openedSubMenus}
      >
        <Menu.Item key='/'>
          <NavLink to='/' className='nav-text'>
            <Icon type='home' />
            <span>Home</span>
          </NavLink>
        </Menu.Item>

        {modules.buildx && (
          <Menu.SubMenu
            title={
              <span>
                <Icon type='tags' />
                <span>BuildX</span>
              </span>
            }
            key='buildxSubMenu'
          >
            <Menu.Item key='/recordings'>
              <NavLink to='/recordings' className='nav-text'>
                <Icon type='solution' />
                <span className='nav-text'>
                  {(props.settings.data.buildx || {}).recordingsName ? (
                    (props.settings.data.buildx || {}).recordingsName
                  ) : (
                    <FormattedMessage id='recordings' />
                  )}
                </span>
              </NavLink>
            </Menu.Item>

            <Menu.Item key='/acceptances'>
              <NavLink to='/acceptances' className='nav-text'>
                <Icon type='safety-certificate' />
                <span className='nav-text'>
                  <FormattedMessage id='acceptances' />
                </span>
              </NavLink>
            </Menu.Item>
          </Menu.SubMenu>
        )}

        {modules.datax && (
          <Menu.SubMenu
            title={
              <span>
                <Icon type='book' />
                <span>DataX</span>
              </span>
            }
            key='dataxSubMenu'
          >
            <Menu.Item key='/products'>
              <NavLink to='/products' className='nav-text'>
                <Icon type='book' />
                <span className='nav-text'>
                  <FormattedMessage id='products' />
                </span>
              </NavLink>
            </Menu.Item>
            <Menu.Item key='/manufactures'>
              <NavLink to='/manufactures' className='nav-text'>
                <Icon type='book' />
                <span className='nav-text'>
                  <FormattedMessage id='manufacturers' />
                </span>
              </NavLink>
            </Menu.Item>
            <Menu.Item key='/categories'>
              <NavLink to='/categories' className='nav-text'>
                <Icon type='book' />
                <span className='nav-text'>
                  <FormattedMessage id='categories' />
                </span>
              </NavLink>
            </Menu.Item>
          </Menu.SubMenu>
        )}

        {modules.doclink && (
          <Menu.Item key='/doclink'>
            <NavLink to='/doclink' className='nav-text'>
              <Icon type='select' />
              <span className='nav-text'>DOClink</span>
            </NavLink>
          </Menu.Item>
        )}

        {modules.mainx && (
          <Menu.SubMenu
            title={
              <span>
                <Icon type='fork' />
                <span>MainX</span>
              </span>
            }
            key='mainxSubMenu'
          >
            <Menu.Item key='/defects'>
              <NavLink to='/defects' className='nav-text'>
                <Icon type='tool' />
                <span className='nav-text'>
                  <FormattedMessage id='defects' />
                </span>
              </NavLink>
            </Menu.Item>

            <Menu.Item key='/workorders'>
              <NavLink to='/workorders' className='nav-text'>
                <Icon type='tool' />
                <span className='nav-text'>
                  <FormattedMessage id='workorders' />
                </span>
              </NavLink>
            </Menu.Item>

            <Menu.Item key='/scheduled-workorders'>
              <NavLink to='/scheduled-workorders' className='nav-text'>
                <Icon type='calendar' />
                <span className='nav-text'>
                  <FormattedMessage id='scheduled-workorders' />
                </span>
              </NavLink>
            </Menu.Item>

            <Menu.Item key='/requests'>
              <NavLink to='/requests' className='nav-text'>
                <Icon type='pull-request' />
                <span className='nav-text'>
                  <FormattedMessage id='requests' />
                </span>
              </NavLink>
            </Menu.Item>

            <Menu.Item key='/specialwork'>
              <NavLink to='/specialwork' className='nav-text'>
                <Icon type='control' />
                <span className='nav-text'>
                  <FormattedMessage id='specialwork' />
                </span>
              </NavLink>
            </Menu.Item>

            <Menu.Item key='/protocol'>
              <NavLink to='/protocol' className='nav-text'>
                <Icon type='audit' />
                <span className='nav-text'>
                  <FormattedMessage id='protocol' />
                </span>
              </NavLink>
            </Menu.Item>
          </Menu.SubMenu>
        )}

        {nonStandarUser && (
          <Menu.SubMenu
            title={
              <span>
                <Icon type='setting' />
                <span>
                  <FormattedMessage id='settings' />
                </span>
              </span>
            }
            key='settingsSubMenu'
          >
            <Menu.Item key='/objects'>
              <NavLink to='/objects' className='nav-text'>
                <Icon type='deployment-unit' />
                <span className='nav-text'>
                  <FormattedMessage id='objects' />
                </span>
              </NavLink>
            </Menu.Item>

            <Menu.Item key='/locations'>
              <NavLink to='/locations' className='nav-text'>
                <Icon type='environment' />
                <span className='nav-text'>
                  <FormattedMessage id='locations' />
                </span>
              </NavLink>
            </Menu.Item>

            <Menu.Item key='/contract-sections'>
              <NavLink to='/contract-sections' className='nav-text'>
                <Icon type='snippets' />
                <span className='nav-text'>
                  <FormattedMessage id='contract sections' />
                </span>
              </NavLink>
            </Menu.Item>

            <Menu.Item key='/users'>
              <NavLink to='/users' className='nav-text'>
                <Icon type='team' />
                <span className='nav-text'>
                  <FormattedMessage id='users' />
                </span>
              </NavLink>
            </Menu.Item>

            <Menu.Item key='/contacts'>
              <NavLink to='/contacts' className='nav-text'>
                <Icon type='team' />
                <span className='nav-text'>
                  <FormattedMessage id='contacts' />
                </span>
              </NavLink>
            </Menu.Item>

            <Menu.Item key='/groups'>
              <NavLink to='/groups' className='nav-text'>
                <Icon type='diff' />
                <span className='nav-text'>
                  <FormattedMessage id='groups' />
                </span>
              </NavLink>
            </Menu.Item>

            <Menu.Item key='/modules'>
              <NavLink to='/modules' className='nav-text'>
                <Icon type='build' />
                <span className='nav-text'>
                  <FormattedMessage id='modules' />
                </span>
              </NavLink>
            </Menu.Item>

            <Menu.Item key='/forms'>
              <NavLink to='/forms' className='nav-text'>
                <Icon type='qrcode' />
                <span className='nav-text'>
                  <FormattedMessage id='forms' />
                </span>
              </NavLink>
            </Menu.Item>

            <Menu.Item key='/settings'>
              <NavLink to='/settings' className='nav-text'>
                <Icon type='setting' />
                <span className='nav-text'>
                  <FormattedMessage id='global settings' />
                </span>
              </NavLink>
            </Menu.Item>

            <Menu.Item key='/projects'>
              <NavLink to='/projects' className='nav-text'>
                <Icon type='project' />
                <span className='nav-text'>
                  <FormattedMessage id='projects' />
                </span>
              </NavLink>
            </Menu.Item>
          </Menu.SubMenu>
        )}
      </Menu>
    </>
  )
}

Sidebar.propTypes = {
  auth: PropTypes.object,
  settings: PropTypes.object.isRequired
}

export default Sidebar
