import React from 'react'

import moment from 'moment'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { injectIntl, FormattedMessage } from 'react-intl'
import { Helmet } from 'react-helmet'
import { Table, notification, Icon, Button, Form, Input } from 'antd'
import protocolService from 'services/protocol'

import PageTitle from 'components/Global/PageTitle'

import queryString from 'query-string'
import { setPickedProject } from '../../../ducks/project'

export class ProtocolPage extends React.Component {
  state = {
    items: [],
    loading: false,
    pagination: {
      defaultPageSize: 10,
      showSizeChanger: true,
      pageSizeOptions: ['10', '20', '30', '50', '100']
    },
    params: {}
  }

  getParams = () => {
    const { history } = this.props
    this._isMounted = true
    const searchString = history.location.search
    let searchQuery
    if (searchString.length) {
      searchQuery = queryString.parse(searchString, { arrayFormat: 'bracket' })
      if (searchQuery.page) {
        searchQuery.offset = searchQuery.page
        delete searchQuery.page
      }
      if (!searchQuery.limit) {
        searchQuery.limit = 10
      }
      if (!searchQuery.sortField && !searchQuery.sortOrder) {
        searchQuery.sortField = 'createdAt'
        searchQuery.sortOrder = 'descend'
      }
      return searchQuery
    }
  }

  setParams = paramsObject => {
    const { path, history, project } = this.props
    if (project) {
      paramsObject.project = project._id
    }
    if (paramsObject.offset) {
      paramsObject.page = paramsObject.offset
      delete paramsObject.offset
    }
    if (paramsObject.limit === 10) {
      delete paramsObject.limit
    }
    if (
      (paramsObject.sortField === 'createdAt' &&
        paramsObject.sortOrder === 'descend') ||
      !paramsObject.sortOrder
    ) {
      delete paramsObject.sortField
      delete paramsObject.sortOrder
    }

    this.setState({ params: paramsObject })
    history.push({
      pathname: path,
      search: queryString.stringify(paramsObject, { arrayFormat: 'bracket' })
    })
  }

  componentDidMount () {
    this.fetchProtocol()
  }

  componentDidUpdate (prevProps) {
    const {
      location: { search: prevSearch }
    } = prevProps
    const {
      location: { search: nextSearch },
      project,
      projects,
      dispatch
    } = this.props
    const params = { ...this.getParams() }

    if (params.project && !prevProps.projects.length && projects.length) {
      dispatch(
        setPickedProject(projects.find(item => item._id === params.project))
      )
    }

    if (project !== prevProps.project) {
      if (project._id) {
        this.setParams({ ...params, project: project._id })
      } else {
        delete params.project
        this.setParams(params)
      }
    }
    if (prevSearch !== nextSearch || project !== prevProps.project) {
      this.fetchProtocol()
    }
  }

  fetchProtocol = async () => {
    const params = this.getParams()

    this.setState({
      loading: true
    })
    try {
      const protocol = await protocolService.listV2({
        ...params
      })

      const pagination = {
        ...this.state.pagination
      }

      pagination.total = protocol.totalDocs

      this.setState({
        items: protocol.docs,
        loading: false,
        pagination: {
          ...pagination,
          pageSize: protocol.limit,
          current: protocol.page
        },
        params: { ...params }
      })
    } catch (error) {
      notification.error({
        message: `${this.props.intl.formatMessage({
          id: 'protocol'
        })} - download error`
      })

      this.setState({
        visibleDrawer: false,
        loading: false
      })
    }
  }

  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination }
    pager.offset = pagination.current
    const params = {
      limit: pagination.pageSize,
      offset: pagination.current,
      sortField: sorter.field,
      sortOrder: sorter.order,
      ...filters
    }
    this.setParams(params)
  }

  handleSearch = (selectedKeys, field, confirm) => {
    confirm()
    this.setState({
      search: {
        ...this.state.search,
        [field]: selectedKeys[0]
      }
    })
  }

  handleReset = (field, clearFilters) => {
    clearFilters()
    const { params } = this.state
    const outputObject = { ...params }
    delete outputObject[field]
    this.setParams(outputObject)
    this.setState({
      [field + 'Search']: undefined
    })
  }

  setSelectedKeys = (dataIndex, value, setSelectedKeys) => {
    setSelectedKeys(value ? [value] : [])
    this.setState({
      [dataIndex + 'Search']: value
    })
  }

  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this[`searchInput${dataIndex}`] = node
          }}
          placeholder={this.props.intl.formatMessage({ id: 'search' })}
          value={
            this.state[dataIndex + 'Search'] !== undefined
              ? this.state[dataIndex + 'Search']
              : this.state.params[dataIndex]
          }
          onChange={e =>
            this.setSelectedKeys(dataIndex, e.target.value, setSelectedKeys)
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, dataIndex, confirm)
          }
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type='primary'
          onClick={() => this.handleSearch(selectedKeys, dataIndex, confirm)}
          icon='search'
          disabled={!this.state[dataIndex + 'Search']}
          size='small'
          style={{ width: 90, marginRight: 8 }}
        >
          Suchen
        </Button>
        <Button
          onClick={() => this.handleReset(dataIndex, clearFilters)}
          size='small'
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon
        type='search'
        style={{
          color:
            this.state.params[dataIndex] || filtered ? '#1890ff' : undefined
        }}
      />
    ),
    // onFilter: (value, record) => String(record[dataIndex]).toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this[`searchInput${dataIndex}`].select())
      }
    }
  })

  render () {
    const columns = [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        sorter: true,
        ...this.getColumnSearchProps('name')
      },
      {
        title: this.props.intl.formatMessage({ id: 'created at' }),
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: createdAt => moment(createdAt).format('DD.MM.YYYY, HH:mm'),
        sorter: true,
        defaultSortOrder:
          this.state.params.sortField === 'createdAt'
            ? this.state.params.sortOrder
            : 'descend'
      },
      {
        title: this.props.intl.formatMessage({ id: 'event' }),
        dataIndex: 'event',
        key: 'event',
        sorter: true
      },
      {
        title: this.props.intl.formatMessage({ id: 'description' }),
        dataIndex: 'description',
        key: 'description',
        sorter: true
      },
      {
        title: this.props.intl.formatMessage({ id: 'endpoint' }),
        dataIndex: 'endpoint',
        key: 'endpoint',
        sorter: true
      },
      {
        title: this.props.intl.formatMessage({ id: 'created by' }),
        dataIndex: 'createdBy',
        key: 'createdBy',
        sorter: true,
        render: (text, record) => {
          if (record.createdBy) {
            return record.createdBy.fullName
          }

          return ''
        }
      }
    ]

    if (!this.props.project) {
      columns.push({
        title: this.props.intl.formatMessage({ id: 'project' }),
        key: 'project',
        dataIndex: 'project.projectName'
      })
    }

    return (
      <div>
        <FormattedMessage id='head.title.protocol'>
          {title => (
            <Helmet>
              <title>{title}</title>
            </Helmet>
          )}
        </FormattedMessage>

        <PageTitle title={this.props.intl.formatMessage({ id: 'protocol' })} />

        <Table
          // eslint-disable-next-line no-return-assign
          ref={ref => (this._table = ref)}
          columns={columns}
          dataSource={this.state.items}
          loading={this.state.loading}
          rowKey={record => record._id}
          onChange={this.handleTableChange}
          pagination={this.state.pagination}
        />
      </div>
    )
  }
}

ProtocolPage.propTypes = {
  dispatch: PropTypes.func.isRequired,
  projects: PropTypes.array,
  history: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  path: PropTypes.string,
  project: PropTypes.object,
  location: PropTypes.object
}

const mapStateToProps = state => ({
  projects: state.project.projectList,
  project: state.project.pickedProject
})

export default injectIntl(
  Form.create({ name: 'specialwork_page' })(
    connect(mapStateToProps)(ProtocolPage)
  )
)
