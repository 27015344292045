import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { Form, Select, Icon, Switch } from 'antd'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import useDebounce from '../../../services/debounce'
import { fetchCategoryList } from '../../../ducks/category'

const Option = Select.Option

const BuildX = props => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const [categoryList, setCategoryList] = useState([])
  const [searchString, setSearchString] = useState('')

  const {
    buildX,
    formItemLayout,
    formOptions,
    apps,
    updateCustomForm,
    updateProductMapping,
    updateApps
  } = props

  // const handleSearch  = debounce((data)=>fetchCategoryData(data), 300)
  const fetchCategoryData = text => {
    dispatch(fetchCategoryList({ 'name[]': text })).then(response => {
      if (response && !response.error) {
        setCategoryList(response.docs)
      }
    })
  }

  const debouncedSearchString = useDebounce(searchString, 300)

  useEffect(() => {
    if (debouncedSearchString) {
      fetchCategoryData(searchString)
    }
  }, [debouncedSearchString])

  const formCategoryList = (value, e) => {
    const outputArray = e.map((item, index) => {
      if (item.props.data) {
        return item.props.data
      } else {
        return buildX.product_mapping.find(
          category => category.name === value[index]
        )
      }
    })
    updateProductMapping('product_mapping', outputArray)
  }

  return (
    <>
      <Form.Item
        {...formItemLayout}
        label={intl.formatMessage({
          id: 'recording form'
        })}
      >
        <Select
          value={(buildX.custom_forms || {}).recording}
          onChange={value => updateCustomForm('recording', value)}
          placeholder={intl.formatMessage({
            id: 'recording form'
          })}
          style={{
            width: '100%'
          }}
        >
          {formOptions}
        </Select>
      </Form.Item>

      <Form.Item
        {...formItemLayout}
        label={intl.formatMessage({
          id: 'acceptance form'
        })}
      >
        <Select
          value={(buildX.custom_forms || {}).acceptance}
          onChange={value => updateCustomForm('acceptance', value)}
          placeholder={intl.formatMessage({
            id: 'acceptance form'
          })}
          style={{
            width: '100%'
          }}
        >
          {formOptions}
        </Select>
      </Form.Item>

      <Form.Item
        {...formItemLayout}
        label={intl.formatMessage({
          id: 'Category mapping'
        })}
      >
        <Select
          mode='multiple'
          style={{ width: '100%' }}
          placeholder='Please select'
          // defaultValue={['a10', 'c12']}
          // optionLabelProp={'name'}
          onFocus={() => fetchCategoryData('')}
          onSearch={setSearchString}
          defaultValue={
            buildX.product_mapping
              ? buildX.product_mapping.map(item => item.name)
              : []
          }
          // value={
          //   buildX.product_mapping
          //     ? buildX.product_mapping.map(item => item.name)
          //     : []
          // }
          onChange={(value, e) => formCategoryList(value, e)}
        >
          {categoryList.map(item => (
            <Option key={item._id} data={item} id={item._id} value={item._id}>
              {item.name}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        {...formItemLayout}
        label={intl.formatMessage({
          id: 'sapEnabled'
        })}
      >
        <Switch
          checkedChildren={<Icon type='check' />}
          unCheckedChildren={<Icon type='close' />}
          checked={(apps || {}).sap}
          onChange={value => updateApps('sap', value)}
        />
      </Form.Item>
    </>
  )
}

BuildX.propTypes = {
  formItemLayout: PropTypes.object,
  buildX: PropTypes.object.isRequired,
  formOptions: PropTypes.array.isRequired,
  apps: PropTypes.object.isRequired,
  updateCustomForm: PropTypes.func.isRequired,
  updateProductMapping: PropTypes.func.isRequired,
  updateApps: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired
}

export default BuildX
