import React from 'react'

import moment from 'moment'
import { connect } from 'react-redux'
import { injectIntl, FormattedMessage } from 'react-intl'
import { Helmet } from 'react-helmet'
import _ from 'lodash'

import { Table, Icon, notification, Form, Input } from 'antd'
import Button from 'components/Global/Button'

import LocationForm from 'components/Location/Form'
import PageTitle from 'components/Global/PageTitle'
import locationService from 'services/location'
import projectService from 'services/project'
import DrawerComponent from 'components/Drawer'
import PopconfirmDelete from 'components/Global/PopconfirmDelete/index'
import queryString from 'query-string'
import { setPickedProject } from '../../../ducks/project'
import PropTypes from 'prop-types'

export class LocationsPage extends React.Component {
  state = {
    locations: [],
    projects: [],
    drawer: false,
    location: {},
    loading: false,
    formLoading: false,
    createdByFilters: [],
    pagination: {
      defaultPageSize: 10,
      showSizeChanger: true,
      pageSizeOptions: ['10', '20', '30', '50', '100']
    },
    activeFilters: [],
    readOnlyNewProject: false,
    params: {}
  }

  getParams = () => {
    const { history } = this.props
    this._isMounted = true
    const searchString = history.location.search
    let searchQuery
    if (searchString.length) {
      searchQuery = queryString.parse(searchString, { arrayFormat: 'bracket' })
      if (searchQuery.page) {
        searchQuery.offset = searchQuery.page
        delete searchQuery.page
      }
      if (!searchQuery.limit) {
        searchQuery.limit = 10
      }
      if (!searchQuery.sortField && !searchQuery.sortOrder) {
        searchQuery.sortField = 'createdAt'
        searchQuery.sortOrder = 'descend'
      }
      return searchQuery
    }
  }

  setParams = paramsObject => {
    const { path, history, project } = this.props
    if (project && project.project) {
      paramsObject.project = project.project._id
    }
    if (paramsObject.offset) {
      paramsObject.page = paramsObject.offset
      delete paramsObject.offset
    }
    if (paramsObject.limit === 10) {
      delete paramsObject.limit
    }
    if (
      (paramsObject.sortField === 'createdAt' &&
        paramsObject.sortOrder === 'descend') ||
      !paramsObject.sortOrder
    ) {
      delete paramsObject.sortField
      delete paramsObject.sortOrder
    }
    this.setState({ params: paramsObject })
    history.push({
      pathname: path,
      search: queryString.stringify(paramsObject, { arrayFormat: 'bracket' })
    })
  }

  componentDidMount () {
    this.fetchLocations()
  }

  componentDidUpdate (prevProps) {
    const {
      location: { search: prevSearch }
    } = prevProps
    const {
      location: { search: nextSearch },
      projects,
      selectedProject,
      dispatch
    } = this.props
    const params = { ...this.getParams() }

    if (params.project && !prevProps.projects.length && projects.length) {
      dispatch(
        setPickedProject(projects.find(item => item._id === params.project))
      )
    }

    if (selectedProject !== prevProps.selectedProject) {
      if (selectedProject._id) {
        this.setParams({ ...params, project: selectedProject._id })
      } else {
        delete params.project
        this.setParams(params)
      }
    }
    if (
      prevSearch !== nextSearch ||
      selectedProject !== prevProps.selectedProject
    ) {
      this.fetchLocations()
    }
  }

  fetchLocations = async () => {
    const params = this.getParams()

    this.setState({
      loading: true
    })
    try {
      const locations = await locationService.listV2({
        ...params
      })

      const pagination = {
        ...this.state.pagination
      }

      pagination.total = locations.totalDocs

      this.setState({
        locations: locations.docs,
        createdByFilters: locations.filterOptions.createdBy,
        loading: false,
        pagination: {
          ...pagination,
          pageSize: locations.limit,
          current: locations.page
        },
        params: { ...params }
      })
    } catch (error) {
      notification.error({
        message: `${this.props.intl.formatMessage({
          id: 'locations'
        })} - download error`
      })

      this.setState({
        locations: [],
        drawer: false,
        loading: false,
        location: {}
      })
    }
  }

  fetchProjects = async value => {
    this.setState({
      formLoading: true
    })
    const timer = setTimeout(async () => {
      try {
        const projects = await projectService.listV2({
          projectName: [value],
          limit: 100
        })

        this.setState({
          projects: projects.docs,
          formLoading: false
        })

        clearTimeout(timer)
      } catch (error) {
        this.setState({
          projects: [],
          formLoading: false
        })
        clearTimeout(timer)
      }
    })
  }

  showDrawer = location => {
    if (_.isEmpty(location, true)) {
      this.setState({
        drawer: true,
        location: {
          ...location,
          project: this.props.selectedProject
            ? this.props.selectedProject
            : null
        },
        readOnlyNewProject: !!this.props.selectedProject._id
      })
    } else {
      this.setState({
        drawer: true,
        location: {
          ...location
        },
        readOnlyNewProject: false
      })
    }
  }

  handleHideDrawer = () => {
    this.setState({
      drawer: false,
      location: {}
    })
  }

  handleSaveLocation = async () => {
    this.props.form.validateFields(async err => {
      if (!err) {
        try {
          const savedLocation = await locationService.save(this.state.location)

          this.fetchLocations()
          if (this.state.location._id) {
            notification.success({
              message: `${this.props.intl.formatMessage({
                id: 'updated successfully'
              })}`
            })
          } else {
            notification.success({
              message: `${this.props.intl.formatMessage({
                id: 'created successfully'
              })}`
            })
          }

          this.setState({
            drawer: false,
            location: {},
            locations: [
              ...this.state.locations.filter(
                location => location._id !== savedLocation._id
              ),
              savedLocation
            ]
          })
        } catch (error) {
          notification.error({
            message: this.props.intl.formatMessage({ id: 'saving error' }),
            description: error.error
          })
        }
      }
    })
  }

  removeLocation = async location => {
    try {
      await locationService.remove(location)

      this.setState({
        drawer: false,
        location: {},
        locations: [
          ...this.state.locations.filter(item => item._id !== location._id)
        ]
      })
    } catch (error) {
      notification.error({
        message: this.props.intl.formatMessage({ id: 'deleting error' }),
        description: error.error
      })
    }
  }

  updateLocation = (field, value) => {
    this.setState(state => ({
      location: {
        ...state.location,
        [field]: value
      }
    }))
  }

  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination }
    pager.offset = pagination.current
    const params = {
      limit: pagination.pageSize,
      offset: pagination.current,
      sortField: sorter.field,
      sortOrder: sorter.order,
      ...filters
    }
    this.setParams(params)
  }

  setSelectedKeys = (dataIndex, value, setSelectedKeys) => {
    setSelectedKeys(value ? [value] : [])
    this.setState({
      [dataIndex + 'Search']: value
    })
  }

  handleReset = (field, clearFilters) => {
    clearFilters()
    const { params } = this.state
    const outputObject = { ...params }
    delete outputObject[field]
    this.setParams(outputObject)
    this.setState({
      [field + 'Search']: undefined
    })
  }

  handleSearch = (selectedKeys, field, confirm) => {
    confirm()
    this.setState({
      search: {
        ...this.state.search,
        [field]: selectedKeys[0]
      }
    })
  }

  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => {
      return (
        <div style={{ padding: 8 }}>
          <Input
            ref={node => {
              this[`searchInput${dataIndex}`] = node
            }}
            placeholder={this.props.intl.formatMessage({ id: 'search' })}
            value={
              this.state[dataIndex + 'Search'] !== undefined
                ? this.state[dataIndex + 'Search']
                : this.state.params[dataIndex]
            }
            onChange={e =>
              this.setSelectedKeys(dataIndex, e.target.value, setSelectedKeys)
            }
            onPressEnter={() =>
              this.handleSearch(selectedKeys, dataIndex, confirm)
            }
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Button
            onClick={() => this.handleSearch(selectedKeys, dataIndex, confirm)}
            icon='search'
            size='small'
            disabled={!this.state[dataIndex + 'Search']}
            value='Suchen'
            style={{ width: 90, marginRight: 8 }}
          />

          <Button
            onClick={() => this.handleReset(dataIndex, clearFilters)}
            size='small'
            type='secondary'
            value='Reset'
            style={{ width: 90 }}
          />
        </div>
      )
    },
    filterIcon: filtered => (
      <Icon
        type='search'
        style={{
          color:
            this.state.params[dataIndex] || filtered ? '#1890ff' : undefined
        }}
      />
    ),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this[`searchInput${dataIndex}`].select())
      }
    }
  })

  render () {
    const {
      locations,
      location,
      projects,
      drawer,
      loading,
      formLoading,
      readOnlyNewProject
    } = this.state

    const columns = [
      {
        title: 'Name',
        key: 'name',
        dataIndex: 'name',
        // defaultSortOrder: 'ascend',
        sorter: true,
        ...this.getColumnSearchProps('name')
      },
      {
        title: this.props.intl.formatMessage({ id: 'created at' }),
        key: 'createdAt',
        dataIndex: 'createdAt',
        sorter: true,
        render: createdAt => moment(createdAt).format('DD.MM.YYYY, HH:mm'),
        defaultSortOrder:
          this.state.params.sortField === 'createdAt'
            ? this.state.params.sortOrder
            : 'descend'
      },
      {
        title: this.props.intl.formatMessage({ id: 'created by' }),
        key: 'createdBy',
        dataIndex: 'createdBy',
        render: createdBy =>
          createdBy ? `${createdBy.firstName} ${createdBy.lastName}` : '',
        filters: this.state.createdByFilters.map(user => ({
          text: `${user.firstName} ${user.lastName}`,
          value: user._id
        })),
        filteredValue: this.state.params.createdBy
      },
      {
        title: this.props.intl.formatMessage({ id: 'active' }),
        key: 'disabled',
        dataIndex: 'disabled',
        align: 'center',
        render: disabled => (
          <>{!disabled && <Icon type='check' style={{ color: '#005591' }} />}</>
        ),
        filters: [
          {
            text: this.props.intl.formatMessage({ id: 'active' }),
            value: false
          },
          {
            text: this.props.intl.formatMessage({ id: 'inactive' }),
            value: true
          }
        ],
        filteredValue: this.state.params.disabled
      }
    ]

    if (!this.props.project) {
      columns.push({
        title: this.props.intl.formatMessage({ id: 'project' }),
        key: 'project',
        dataIndex: 'project.projectName'
      })
    }

    columns.push({
      key: 'actions',
      render: (text, record) => (
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'flex-end'
          }}
        >
          <>
            <Button onClick={() => this.showDrawer(record)}>
              <Icon type='edit' />
            </Button>
          </>
        </div>
      )
    })

    const drawerButtons = [
      <Button
        key={237323536}
        onClick={this.handleHideDrawer}
        style={{ marginRight: 8 }}
      >
        <FormattedMessage id='cancel' />
      </Button>,
      <Button key={238678436} onClick={this.handleSaveLocation} type='primary'>
        {this.props.intl.formatMessage({ id: 'save' })}
      </Button>
    ]

    if (location._id) {
      drawerButtons.unshift(
        <PopconfirmDelete
          key={0}
          onConfirmAction={() => this.removeLocation(this.state.location)}
        />
      )
    }

    const headerButtons = [
      <Button
        key={237323536}
        type='primary'
        onClick={() => this.showDrawer({})}
      >
        <FormattedMessage id='create location' />
      </Button>
    ]

    return (
      <div>
        <FormattedMessage id='head.title.locations'>
          {title => (
            <Helmet>
              <title>{title}</title>
            </Helmet>
          )}
        </FormattedMessage>

        <PageTitle
          title={this.props.intl.formatMessage({ id: 'locations' })}
          buttons={headerButtons}
        />

        <Table
          columns={columns}
          dataSource={locations}
          rowKey={record => record._id}
          loading={loading}
          onRow={record => ({
            onDoubleClick: () => {
              this.showDrawer(record)
            }
          })}
          onChange={this.handleTableChange}
          defaultPageSize={this.state.pagination.defaultPageSize}
          pagination={this.state.pagination}
        />
        <DrawerComponent
          title={
            location._id
              ? location.name
              : this.props.intl.formatMessage({ id: 'create location' })
          }
          onClose={this.handleHideDrawer}
          visible={!!drawer}
          footerButtons={drawerButtons}
        >
          <LocationForm
            projects={projects}
            location={location}
            readOnlyNewProject={readOnlyNewProject}
            formLoading={formLoading}
            fetchProjects={this.fetchProjects}
            updateLocation={this.updateLocation}
            form={this.props.form}
          />
        </DrawerComponent>
      </div>
    )
  }
}

LocationsPage.propTypes = {
  dispatch: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  form: PropTypes.object,
  history: PropTypes.object,
  path: PropTypes.string,
  project: PropTypes.object,
  selectedProject: PropTypes.object,
  projects: PropTypes.array,
  location: PropTypes.object
}

const mapStateToProps = state => ({
  projects: state.project.projectList,
  project: state.project,
  selectedProject: state.project.pickedProject
})

export default injectIntl(
  Form.create({ name: 'location_form' })(
    connect(mapStateToProps)(LocationsPage)
  )
)
