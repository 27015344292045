import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import HeaderRadioButtons from './HeaderRadioButtons'
import { Form, Input, Select, Spin } from 'antd'
import objectService from '../../../../services/object'
import contractSectionService from '../../../../services/contractSection'
import contactService from '../../../../services/contact'

const { Option } = Select

class ContactForm extends React.Component {
  state = {
    formType: null,
    formLoading: false,
    contacts: []
  }

  onFormTypeChange = formType => {
    const { updateContact } = this.props
    this.setState({
      formType
    })
    updateContact('contact_type', formType === 'person' ? 'user' : formType)
  }

  fetchData = async (value, key) => {
    this.setState({
      formLoading: true
    })
    const { projects } = this.props

    const timer = setTimeout(async () => {
      try {
        const data = await contactService.listV2({
          company: [value],
          'contact_type[]': 'company',
          limit: 100
        })
        this.setState({
          [key]: data.docs || data,
          formLoading: false
        })

        clearTimeout(timer)
      } catch (error) {
        this.setState({
          [key]: [],
          formLoading: false
        })
        clearTimeout(timer)
      }
    })
  }

  render () {
    const {
      form: { getFieldDecorator },
      intl,
      contact,
      updateContact,
      action,
      subDrawer
    } = this.props

    const { formType, formLoading, contacts } = this.state
    const formItemLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
      className: 'formItem'
    }

    return (
      <div>
        {action === 'create' && !subDrawer && (
          <HeaderRadioButtons handleFormType={this.onFormTypeChange} />
        )}

        <Form onSubmit={() => {}} className='contactForm'>
          {(formType === 'company' ||
            (action === 'edit' && contact.contact_type === 'company') ||
            (action === 'edit' && contact.company)) && (
            <>
              <Form.Item
                {...formItemLayout}
                label={
                  <span>
                    {intl.formatMessage({ id: 'name' })}
                    <span style={{ color: '#005591' }}>*</span>
                  </span>
                }
                required={false}
              >
                {getFieldDecorator('company', {
                  initialValue: contact.company,
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({
                        id: 'company is required'
                      })
                    }
                  ]
                })(
                  <Input
                    onChange={event =>
                      updateContact('company', event.target.value)
                    }
                  />
                )}
              </Form.Item>

              <Form.Item
                {...formItemLayout}
                label={intl.formatMessage({ id: 'street' })}
              >
                {getFieldDecorator('street', {
                  initialValue: contact.street
                })(
                  <Input
                    onChange={event =>
                      updateContact('street', event.target.value)
                    }
                  />
                )}
              </Form.Item>

              <Form.Item
                {...formItemLayout}
                label={intl.formatMessage({ id: 'zipcode' })}
              >
                {getFieldDecorator('postcode', {
                  initialValue: contact.postcode
                })(
                  <Input
                    onChange={event =>
                      updateContact('postcode', event.target.value)
                    }
                  />
                )}
              </Form.Item>

              <Form.Item
                {...formItemLayout}
                label={intl.formatMessage({ id: 'city' })}
              >
                {getFieldDecorator('city', {
                  initialValue: contact.city
                })(
                  <Input
                    onChange={event =>
                      updateContact('city', event.target.value)
                    }
                  />
                )}
              </Form.Item>

              {/*<Form.Item*/}
              {/*  {...formItemLayout}*/}
              {/*  label={intl.formatMessage({ id: 'website' })}*/}
              {/*>*/}
              {/*  {getFieldDecorator('website', {*/}
              {/*    initialValue: contact.website*/}
              {/*  })(*/}
              {/*    <Input*/}
              {/*      onChange={event =>*/}
              {/*        updateContact('website', event.target.value)*/}
              {/*      }*/}
              {/*    />*/}
              {/*  )}*/}
              {/*</Form.Item>*/}
            </>
          )}

          {(formType === 'person' ||
            (action === 'edit' && contact.contact_type === 'user') ||
            subDrawer) && (
            <>
              <Form.Item
                {...formItemLayout}
                label={intl.formatMessage({ id: 'first name' })}
              >
                {getFieldDecorator('firstName', {
                  initialValue: contact.firstName,
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({
                        id: 'name is required'
                      })
                    }
                  ]
                })(
                  <Input
                    onChange={event =>
                      updateContact('firstName', event.target.value)
                    }
                  />
                )}
              </Form.Item>

              <Form.Item
                {...formItemLayout}
                label={intl.formatMessage({ id: 'last name' })}
              >
                {getFieldDecorator('lastName', {
                  initialValue: contact.lastName
                })(
                  <Input
                    onChange={event =>
                      updateContact('lastName', event.target.value)
                    }
                  />
                )}
              </Form.Item>
            </>
          )}

          {(formType !== null || action === 'edit' || subDrawer) && (
            <>
              <Form.Item
                {...formItemLayout}
                label={intl.formatMessage({ id: 'email' })}
              >
                {getFieldDecorator('email', {
                  initialValue: contact.email,
                  rules: [
                    {
                      required: false,
                      message: intl.formatMessage({
                        id: 'email is required'
                      })
                    }
                  ]
                })(
                  <Input
                    onChange={event =>
                      updateContact('email', event.target.value)
                    }
                  />
                )}
              </Form.Item>

              <Form.Item
                {...formItemLayout}
                label={intl.formatMessage({ id: 'phone' })}
              >
                {getFieldDecorator('phone', {
                  initialValue: contact.phone
                })(
                  <Input
                    onChange={event =>
                      updateContact('phone', event.target.value)
                    }
                  />
                )}
              </Form.Item>
            </>
          )}

          {(formType === 'company' ||
            (action === 'edit' && contact.contact_type === 'company') ||
            (action === 'edit' && contact.company)) && (
            <Form.Item
              {...formItemLayout}
              label={intl.formatMessage({ id: 'website' })}
            >
              {getFieldDecorator('website', {
                initialValue: contact.website
              })(
                <Input
                  onChange={event =>
                    updateContact('website', event.target.value)
                  }
                />
              )}
            </Form.Item>
          )}

          {formType === 'person' && action !== 'edit' && (
            <Form.Item
              {...formItemLayout}
              label={intl.formatMessage({
                id: 'company'
              })}
            >
              {getFieldDecorator('representCompany')(
                <Select
                  showSearch
                  allowClear
                  style={{ width: '100%' }}
                  optionFilterProp='children'
                  notFoundContent={
                    formLoading ? (
                      <div className='objects-spinner-container'>
                        <Spin size='small' />
                      </div>
                    ) : null
                  }
                  onFocus={() => this.fetchData('', 'contacts')}
                  onSearch={value => this.fetchData(value, 'contacts')}
                  onChange={(company, element) =>
                    updateContact(
                      'representCompany',
                      (element && element.props.data) || ''
                    )
                  }
                >
                  {contacts.map(element => (
                    <Option
                      key={element._id}
                      value={element._id}
                      data={element}
                    >
                      {element.contact_type && element.contact_type === 'user'
                        ? `${element.firstName || ''} ${element.lastName || ''}`
                        : element.company}
                    </Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          )}
        </Form>
      </div>
    )
  }
}

ContactForm.propTypes = {
  contact: PropTypes.object.isRequired,
  updateContact: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  action: PropTypes.string,
  subDrawer: PropTypes.bool
}

export default injectIntl(ContactForm)
