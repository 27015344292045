import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { FormattedMessage, injectIntl } from 'react-intl'
import { Spin, Select, Input, Button, Form } from 'antd'
import { fetchObjectsList } from '../../../../../ducks/object'
import { createTask } from '../../../../../ducks/tasks'
import debounce from 'lodash/debounce'
import { updatePickedRecording } from '../../../../../ducks/recordings'

const { Option } = Select

export class CreateTaskDropDown extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      loading: false,
      visible: false,
      objectId: undefined,
      errors: {}
    }
    this.handleInputThrottled = debounce(this.fetchObjectsOnSearch, 300)
  }

  validateFields = () => {
    const { name, objectId } = this.state
    const errors = {}
    if (!name) {
      errors.name = true
    }
    if (!objectId || !Object.keys(objectId).length > 0) {
      errors.objectId = true
    }
    return errors
  }

  fetchObjectsOnSearch = searchQueue => {
    const { dispatch } = this.props
    this.setState({
      loading: true
    })
    dispatch(fetchObjectsList({ 'name[]': searchQueue }, true)).then(
      response => {
        this.setState({
          loading: false
        })
      }
    )
  }

  handleCancel = () => {
    this.setState({
      objectId: undefined,
      name: undefined,
      visible: false,
      errors: {}
    })
  }

  handleFieldChange = (key, value) => {
    const { dispatch } = this.props
    const { errors } = this.state
    delete errors[key]
    this.setState({
      [key]: value,
      errors
    })
    dispatch(updatePickedRecording({ [key]: value }))
  }

  handleDropdownState = () => {
    this.setState({
      visible: !this.state.visible
    })
  }

  handleSubmit = () => {
    const { dispatch, handleTaskCreation, workorder } = this.props
    const { name, objectId } = this.state
    const errors = this.validateFields()
    if (Object.keys(errors).length) {
      this.setState({ errors })
      return
    }
    const payload = { name, objectId: objectId._id }
    if (workorder) {
      payload.workorder = workorder._id
    }
    dispatch(createTask(payload)).then(response => {
      if (!response.error) {
        handleTaskCreation(response)
        this.handleCancel()
      }
    })
  }

  render () {
    const { intl, objects, horizontal } = this.props
    const { loading, visible, errors } = this.state

    return (
      <>
        {visible ? (
          <div
            className={`tasks-dropdown-cover ${
              horizontal ? 'form-horizontal' : ''
            }`}
          >
            <div className='form-cover'>
              <div className='form-item-cover'>
                <Form.Item
                  validateStatus={errors.name ? 'error' : null}
                  help={
                    errors.name
                      ? intl.formatMessage({ id: 'is required' })
                      : null
                  }
                >
                  <Input
                    maxLength={40}
                    placeholder={intl.formatMessage({ id: 'name' })}
                    value={this.state.name}
                    onChange={event =>
                      this.handleFieldChange('name', event.target.value)
                    }
                  />
                </Form.Item>
              </div>

              <div className='form-item-cover'>
                <Form.Item
                  validateStatus={errors.objectId ? 'error' : null}
                  help={
                    errors.objectId
                      ? intl.formatMessage({ id: 'is required' })
                      : null
                  }
                >
                  <Select
                    showSearch
                    allowClear
                    loading={loading}
                    className='certain-category-search'
                    dropdownMatchSelectWidth={false}
                    dropdownStyle={{ width: 200 }}
                    style={{ width: '100%' }}
                    value={
                      this.state.objectId
                        ? this.state.objectId.name
                        : this.state.objectId
                    }
                    placeholder='Select Object'
                    initialValue='Select Object'
                    optionFilterProp='children'
                    notFoundContent={
                      loading ? (
                        <div className='recordings-spinner-container'>
                          <Spin size='small' />
                        </div>
                      ) : null
                    }
                    onFocus={() => this.fetchObjectsOnSearch('')}
                    onSearch={this.handleInputThrottled}
                    onSelect={(value, e) =>
                      this.handleFieldChange('objectId', e.props.data)
                    }
                  >
                    {objects.map(object => (
                      <Option
                        key={object._id}
                        data={object}
                        value={object.name || ''}
                      >
                        {object.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>

              {horizontal && <div className='form-item-cover' />}
            </div>

            <div className='buttons-cover'>
              <Button htmlType='button' onClick={this.handleCancel}>
                <FormattedMessage id='cancel' />
              </Button>
              <Button key='ok' type='primary' onClick={this.handleSubmit}>
                Ok
              </Button>
            </div>
          </div>
        ) : (
          <a className='ant-dropdown-link' onClick={this.handleDropdownState}>
            + {intl.formatMessage({ id: 'create task' })}
          </a>
        )}
      </>
    )
  }
}

CreateTaskDropDown.propTypes = {
  dispatch: PropTypes.func.isRequired,
  workorder: PropTypes.object,
  intl: PropTypes.object.isRequired,
  objects: PropTypes.array.isRequired,
  handleTaskCreation: PropTypes.func.isRequired,
  horizontal: PropTypes.bool
}

const mapStateToProps = state => {
  return {
    objects: state.objects.searchedObjectsList
  }
}

export default injectIntl(connect(mapStateToProps)(CreateTaskDropDown))
