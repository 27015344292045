import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import { Button, Tabs } from 'antd'
import DrawerComponent from 'components/Drawer'
import DefectForm from 'components/Defect/Form'
import RecordingForm from '../../../../components/RecordingForm'

import FileUploadTab from '../../../../components/FileUploadTab'
import ImagesUploadTab from '../../../../components/ImagesUploadTab'
import Updates from '../../../../components/CommentsTab'
import fileService from 'services/file'
import defectService from 'services/defects'
import {
  setPickedReadOnly,
  setPickedRecording
} from '../../../../ducks/recordings'
import ProtocolInfo from './ProtocolInfo'

const { TabPane } = Tabs

class DefectsDrawerTabs extends React.Component {
  onDelete = file => {
    const {
      updateFiles,
      defect: { files }
    } = this.props
    return fileService
      .delete(file._id)
      .then(response => {
        updateFiles(files.filter(item => item._id !== file._id))
        return 'success'
      })
      .catch(() => {
        return 'error'
      })
  }

  onAdd = async image => {
    const { defect, updateFiles } = this.props
    const formData = new FormData()
    formData.append('file', image)
    return defectService
      .addFile(defect._id, formData)
      .then(response => {
        updateFiles([response, ...defect.files])
        return 'success'
      })
      .catch(error => {
        console.log(error)
        return 'error'
      })
  }

  handleHideRecordingDrawer = () => {
    const { dispatch } = this.props
    dispatch(setPickedReadOnly(false))
    dispatch(setPickedRecording({}))
  }

  render () {
    const {
      action,
      workorder,
      defect,
      projects,
      contractSections,
      readOnlyNewProject,
      formLoading,
      fetchProjects,
      fetchContractSections,
      updateItem,
      form,
      handleRefClick,
      defectRecording,
      updateImages,
      pickedRecording
    } = this.props
    const drawerButtons = [
      <Button
        key={65493152}
        onClick={this.handleHideRecordingDrawer}
        style={{ marginRight: 8 }}
      >
        <FormattedMessage id='cancel' />
      </Button>,
      <Button key={237323536} type='primary' disabled>
        <FormattedMessage id='save' />
      </Button>
    ]
    return (
      <Tabs defaultActiveKey='form'>
        <TabPane
          tab={this.props.intl.formatMessage({
            id: 'informations'
          })}
          key='form'
          className='recordings-popup-wrap'
        >
          <DefectForm
            defect={defect}
            projects={projects}
            contractSections={contractSections}
            readOnlyNewProject={readOnlyNewProject}
            formLoading={formLoading}
            fetchProjects={fetchProjects}
            fetchContractSections={fetchContractSections}
            updateItem={updateItem}
            form={form}
            handleRefClick={handleRefClick}
          />
          <DrawerComponent
            title={
              this.props.intl.formatMessage({
                id: 'recording details'
              }) + ` (${defectRecording.name})`
            }
            onClose={this.handleHideRecordingDrawer}
            visible={Object.keys(pickedRecording).length !== 0}
            footerButtons={drawerButtons}
            coverStyle={{ zIndex: '1001' }}
            closable
          >
            <RecordingForm
              // recording={defectRecording}
              // extraFieldsForm={recordingCustomForm}
              // form={this.props.form}
              acceptance={defectRecording.acceptanceId}
              defectsPage
            />
          </DrawerComponent>
        </TabPane>
        <TabPane
          tab={this.props.intl.formatMessage({ id: 'protocol info' })}
          key='protocolInfo'
        >
          <ProtocolInfo
            defect={defect}
            projects={projects}
            contractSections={contractSections}
            readOnlyNewProject={readOnlyNewProject}
            formLoading={formLoading}
            fetchProjects={fetchProjects}
            fetchContractSections={fetchContractSections}
            updateItem={updateItem}
            form={form}
            handleRefClick={handleRefClick}
          />
        </TabPane>

        {defect._id && [
          <TabPane
            tab={this.props.intl.formatMessage({ id: 'files' })}
            key='files'
          >
            <FileUploadTab
              action={action}
              files={defect.files}
              handleDelete={this.onDelete}
              handleAdd={this.onAdd}
            />
          </TabPane>,
          <TabPane
            tab={this.props.intl.formatMessage({ id: 'images' })}
            key='images'
          >
            <ImagesUploadTab
              action={action}
              workorder={workorder}
              updateImages={updateImages}
              defect={defect}
            />
          </TabPane>,
          <TabPane
            tab={this.props.intl.formatMessage({
              id: 'updates'
            })}
            key='updates'
          >
            <Updates
              updateItem={updateItem}
              data={defect}
              service={defectService}
            />
          </TabPane>
        ]}
      </Tabs>
    )
  }
}

DefectsDrawerTabs.propTypes = {
  intl: PropTypes.object.isRequired,
  form: PropTypes.object,
  contractSections: PropTypes.array,
  updateFiles: PropTypes.func.isRequired,
  defect: PropTypes.object,
  workorder: PropTypes.object,
  projects: PropTypes.array,
  readOnlyNewProject: PropTypes.bool,
  formLoading: PropTypes.bool,
  fetchProjects: PropTypes.func.isRequired,
  fetchContractSections: PropTypes.func.isRequired,
  action: PropTypes.func,
  updateItem: PropTypes.func.isRequired,
  handleRefClick: PropTypes.func.isRequired,
  updateImages: PropTypes.func.isRequired,
  defectRecording: PropTypes.object,
  pickedRecording: PropTypes.object,
  dispatch: PropTypes.func.isRequired
}

const mapStateToProps = state => {
  return {
    auth: state.authReducer,
    projects: state.project.projectList,
    pickedRecording: state.recordings.pickedRecording
  }
}

export default injectIntl(connect(mapStateToProps)(DefectsDrawerTabs))
