import { createAction, createReducer } from 'redux-act'
import { notification } from 'antd'
import { SettingsAPI } from '../services'
import { fetchSingleForm } from './forms'

export const REDUCER = 'settings'
const NS = `@@${REDUCER}/`

export const setSettings = createAction(`${NS}SET_SETTINGS`)
export const setLang = createAction(`${NS}SET_LANGUAGE`)
export const setCustomForms = createAction(`${NS}SET_CUSTOM_FORMS`)

export const DEFAULT_LOCALE = 'de'

export const setLanguage = lang => (dispatch, getState) => {
  dispatch(setLang(lang))
  localStorage.setItem('language', lang)
}

const SettingsApi = new SettingsAPI()

export const initialState = {
  data: {},
  lang: localStorage.getItem('language') || DEFAULT_LOCALE,
  customForms: {}
}

export const fetchSettings = () => (dispatch, getState) => {
  SettingsApi.get()
    .then(response => {
      dispatch(setSettings(response))
      if (response && response.buildx && response.buildx.custom_forms) {
        const {
          buildx: { custom_forms } // eslint-disable-line
        } = response
        dispatch(fetchSingleForm(custom_forms.recording)).then(response => {
          const customForms = getState().settings.customForms
          dispatch(setCustomForms({ ...customForms, recording: response }))
        })
        dispatch(fetchSingleForm(custom_forms.acceptance)).then(response => {
          const customForms = getState().settings.customForms
          dispatch(setCustomForms({ ...customForms, acceptance: response }))
        })
      }
    })
    .catch(error => {
      notification.open({
        type: 'error',
        message: error.message
      })
    })
}

export default createReducer(
  {
    [setSettings]: (state, data) => ({ ...state, data }),
    [setLang]: (state, lang) => ({ ...state, lang }),
    [setCustomForms]: (state, customForms) => ({ ...state, customForms })
  },
  initialState
)
