import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import objectService from 'services/object'
import contractSectionService from 'services/contractSection'
import contactService from 'services/contact'
import { Form, Icon, Input, InputNumber, Select, Spin, Tag } from 'antd'

import './styles.scss'
import { connect } from 'react-redux'

const { Option } = Select

const statusOptions = ['open', 'in progress', 'completed']

const groupData = [
  'Kabel',
  'Leittechnik',
  'Materialschaden / falsches Material',
  'falsche Montage',
  'Beschriftung',
  'Brandschottung',
  'Doppelboden',
  'Reinigung',
  'Dokumentation',
  'Schema',
  'Schäden',
  'No-Break',
  'Andere',
  'Zu klären / fertigstellen',
  'Altlasten',
  'Baufehler'
]

class DefectForm extends React.Component {
  state = {
    objects: [],
    projects: [],
    contractSections: [],
    contacts: []
  }

  fetchData = async (value, key) => {
    this.setState({
      formLoading: true
    })
    const { projects } = this.props

    const timer = setTimeout(async () => {
      try {
        let data
        if (key === 'projects') {
          data = projects.filter(
            item =>
              item.projectName
                .toLowerCase()
                .indexOf(value.toString().toLowerCase()) > -1
          )
        } else if (key === 'objects') {
          data = await objectService.listV2({
            name: [value],
            limit: 100
          })
        } else if (key === 'contractSections') {
          data = await contractSectionService.listV2({
            name: [value],
            limit: 100
          })
        } else if (key === 'contacts') {
          data = await contactService.listV2({
            company: [value],
            limit: 100
          })
        }
        this.setState({
          [key]: data.docs || data,
          formLoading: false
        })

        clearTimeout(timer)
      } catch (error) {
        this.setState({
          [key]: [],
          formLoading: false
        })
        clearTimeout(timer)
      }
    })
  }

  render () {
    const {
      form: { getFieldDecorator },
      defect,
      intl,
      updateItem,
      readOnlyNewProject,
      handleRefClick,
      objectName,
      defect: { assignedTo }
    } = this.props

    const {
      objects,
      projects,
      contractSections,
      contacts,
      formLoading
    } = this.state

    const formItemLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
      className: 'formItem'
    }

    return (
      <>
        <Form onSubmit={() => {}} className='defect-form'>
          <Form.Item
            {...formItemLayout}
            label={
              <span>
                Name<span style={{ color: '#005591' }}>*</span>
              </span>
            }
            required={false}
          >
            {getFieldDecorator('name', {
              initialValue: defect.name,
              rules: [
                {
                  required: true,
                  message: intl.formatMessage({ id: 'name is required' })
                }
              ]
            })(
              <Input
                onChange={event => updateItem('name', event.target.value)}
              />
            )}
          </Form.Item>

          <Form.Item
            {...formItemLayout}
            label={intl.formatMessage({ id: 'project' })}
          >
            <Select
              showSearch
              allowClear
              style={{ width: '100%' }}
              optionFilterProp='children'
              notFoundContent={
                formLoading ? (
                  <div className='objects-spinner-container'>
                    <Spin size='small' />
                  </div>
                ) : null
              }
              onFocus={() => this.fetchData('', 'projects')}
              onSearch={value => this.fetchData(value, 'objects')}
              onChange={projectId => updateItem('project', projectId)}
              defaultValue={defect.project ? defect.project.projectName : ''}
              disabled={readOnlyNewProject}
            >
              {projects.map(project => (
                <Option key={project._id} value={project._id}>
                  {project.projectName}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item {...formItemLayout} label={objectName}>
            {getFieldDecorator('objectNumber', {
              initialValue: defect.objectId
                ? defect.objectId.projectName || defect.objectId.name
                : ''
            })(
              <Select
                showSearch
                allowClear
                style={{ width: '100%' }}
                optionFilterProp='children'
                notFoundContent={
                  formLoading ? (
                    <div className='objects-spinner-container'>
                      <Spin size='small' />
                    </div>
                  ) : null
                }
                onFocus={() => this.fetchData('', 'objects')}
                onChange={objectId => updateItem('objectId', objectId)}
                onSearch={value => this.fetchData(value, 'objects')}
              >
                {objects.map(object => (
                  <Option key={object._id} value={object._id}>
                    {object.name}
                  </Option>
                ))}
              </Select>
            )}
          </Form.Item>

          <Form.Item
            {...formItemLayout}
            label={intl.formatMessage({ id: 'contract section' })}
          >
            <Select
              showSearch
              allowClear
              style={{ width: '100%' }}
              optionFilterProp='children'
              notFoundContent={
                formLoading ? (
                  <div className='objects-spinner-container'>
                    <Spin size='small' />
                  </div>
                ) : null
              }
              onFocus={() => this.fetchData('', 'contractSections')}
              onSearch={value => this.fetchData(value, 'contractSections')}
              defaultValue={
                defect.contractSection && defect.contractSection.name
              }
              onChange={contractSectionId =>
                updateItem('contractSection', contractSectionId)
              }
            >
              {contractSections.map(contractSection => (
                <Option key={contractSection._id} value={contractSection._id}>
                  {contractSection.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            label={intl.formatMessage({
              id: 'assignedTo'
            })}
          >
            {getFieldDecorator('assignedTo', {
              initialValue:
                assignedTo && assignedTo.company
                  ? assignedTo && assignedTo.company
                  : `${(assignedTo && assignedTo.firstName) ||
                      ''} ${(assignedTo && assignedTo.lastName) || ''}`
            })(
              <Select
                showSearch
                allowClear
                style={{ width: '100%' }}
                optionFilterProp='children'
                notFoundContent={
                  formLoading ? (
                    <div className='objects-spinner-container'>
                      <Spin size='small' />
                    </div>
                  ) : null
                }
                onFocus={() => this.fetchData('', 'contacts')}
                onSearch={value => this.fetchData(value, 'contacts')}
                onChange={assignedTo => updateItem('assignedTo', assignedTo)}
              >
                {contacts.map(element => (
                  <Option key={element._id} value={element._id}>
                    {element.contact_type && element.contact_type === 'user'
                      ? `${element.firstName || ''} ${element.lastName || ''}`
                      : element.company}
                  </Option>
                ))}
              </Select>
            )}
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            label={intl.formatMessage({
              id: 'group'
            })}
          >
            {getFieldDecorator('group', {
              initialValue: defect.group ? defect.group : ''
            })(
              <Select onChange={group => updateItem('group', group)}>
                {groupData.map((item, index) => (
                  <Option key={index} value={item}>
                    {item}
                  </Option>
                ))}
              </Select>
            )}
          </Form.Item>

          <Form.Item
            {...formItemLayout}
            label={intl.formatMessage({ id: 'actual status' })}
          >
            {getFieldDecorator('actualSituation', {
              initialValue: defect.actualSituation ? defect.actualSituation : ''
            })(
              <Input.TextArea
                autoSize={{ minRows: 2, maxRows: 6 }}
                value={defect.actualSituation}
                onChange={event =>
                  updateItem('actualSituation', event.target.value)
                }
              />
            )}
          </Form.Item>

          <Form.Item
            {...formItemLayout}
            label={intl.formatMessage({ id: 'target status' })}
          >
            {getFieldDecorator('targetSituation', {
              initialValue: defect.targetSituation ? defect.targetSituation : ''
            })(
              <Input.TextArea
                autoSize={{ minRows: 2, maxRows: 6 }}
                value={defect.targetSituation}
                onChange={event =>
                  updateItem('targetSituation', event.target.value)
                }
              />
            )}
          </Form.Item>

          <Form.Item {...formItemLayout} label='Status'>
            {getFieldDecorator('status', {
              initialValue: defect.status ? defect.status : ''
            })(
              <Select
                style={{
                  width: '100%'
                }}
                allowClear
                onChange={status => updateItem('status', status)}
              >
                {statusOptions.map((item, index) => (
                  <Option key={index} value={item}>
                    {item}
                  </Option>
                ))}
              </Select>
            )}
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            label={intl.formatMessage({
              id: 'estimated_cost'
            })}
          >
            {getFieldDecorator('estimated_cost', {
              initialValue: defect.estimated_cost || ''
            })(
              <InputNumber
                onChange={value => updateItem('estimated_cost', value)}
                value={defect.estimated_cost}
                formatter={value =>
                  `CHF ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '')
                }
                // eslint-disable-next-line
                parser={value => value.replace(' ', '').match(/[0-9 , \.]+/g)}
              />
            )}
          </Form.Item>
          {defect.recording && defect.recording._id && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                marginBottom: 12
              }}
            >
              <p
                style={{
                  width: '25%',
                  textAlign: 'right',
                  paddingRight: 10,
                  fontWeight: 400,
                  color: 'rgba(0, 0, 0, 0.85)',
                  marginBottom: 0
                }}
              >
                Ref Link:{' '}
              </p>
              {defect.recording && defect.recording.docNumber && (
                <Tag
                  style={{ cursor: 'pointer' }}
                  onClick={() => handleRefClick(defect)}
                  color='blue'
                >
                  <Icon type='check' />
                  &nbsp;
                  {defect.recording && defect.recording.docNumber}
                </Tag>
              )}
            </div>
          )}
        </Form>
      </>
    )
  }
}

DefectForm.propTypes = {
  updateItem: PropTypes.func.isRequired,
  objectName: PropTypes.string,
  project: PropTypes.object,
  projects: PropTypes.array,
  defect: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  readOnlyNewProject: PropTypes.bool,
  handleRefClick: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired
}

const mapStateToProps = state => {
  return {
    objectName:
      (state.settings.data.buildx && state.settings.data.buildx.objectName) ||
      ''
  }
}

export default injectIntl(connect(mapStateToProps)(DefectForm))
