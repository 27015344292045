import React from 'react'

import moment from 'moment'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { injectIntl, FormattedMessage } from 'react-intl'
import { Table, notification, Icon, Button, Form, Input } from 'antd'
import productService from 'services/products'
import projectService from 'services/project'
import categoriesService from 'services/categories'
import manufacturersService from 'services/manufacturers'
import { findIndexInArray } from 'helpers/utils'

import ProductForm from 'components/Products/Form'
import PageTitle from 'components/Global/PageTitle'
import DrawerComponent from 'components/Drawer'
import PopconfirmDelete from 'components/Global/PopconfirmDelete'
import queryString from 'query-string'
import { setPickedProject } from '../../../ducks/project'

export class ProductsPage extends React.Component {
  state = {
    products: [],
    categories: [],
    manufacturers: [],
    projects: [],
    searchProjects: [],
    selectedRowKeys: [],
    visible: false,
    search: {
      name: ''
    },
    drawerOption: '',
    product: {},
    loading: false,
    formLoading: false,
    pagination: {
      defaultPageSize: 10,
      showSizeChanger: true,
      pageSizeOptions: ['10', '20', '30', '50', '100']
    },
    manufacturerSearchResult: [],
    manufacturerFilters: [],
    categoriesSearchResult: [],
    categoryFilters: [],
    createdByFilters: [],
    priceFocused: false,
    readOnlyNewProject: false,
    params: {}
  }

  getParams = () => {
    const { history } = this.props
    this._isMounted = true
    const searchString = history.location.search
    let searchQuery
    if (searchString.length) {
      searchQuery = queryString.parse(searchString, { arrayFormat: 'bracket' })
      if (searchQuery.page) {
        searchQuery.offset = searchQuery.page
        delete searchQuery.page
      }
      if (!searchQuery.limit) {
        searchQuery.limit = 10
      }
      if (!searchQuery.sortField && !searchQuery.sortOrder) {
        searchQuery.sortField = 'createdAt'
        searchQuery.sortOrder = 'descend'
      }

      return searchQuery
    }
  }

  setParams = paramsObject => {
    const { path, history, selectedProject } = this.props
    if (selectedProject && selectedProject._id) {
      paramsObject.project = selectedProject._id
    }
    if (paramsObject.offset) {
      paramsObject.page = paramsObject.offset
      delete paramsObject.offset
    }

    if (paramsObject.limit === 10) {
      delete paramsObject.limit
    }
    if (
      (paramsObject.sortField === 'createdAt' &&
        paramsObject.sortOrder === 'descend') ||
      !paramsObject.sortOrder
    ) {
      delete paramsObject.sortField
      delete paramsObject.sortOrder
    }

    this.setState({ params: paramsObject })
    history.push({
      pathname: path,
      search: queryString.stringify(paramsObject, { arrayFormat: 'bracket' })
    })
  }

  componentDidMount () {
    this.fetchProducts()
  }

  componentDidUpdate (prevProps) {
    const {
      location: { search: prevSearch }
    } = prevProps
    const {
      location: { search: nextSearch },
      project,
      dispatch
    } = this.props
    const params = { ...this.getParams() }

    if (
      params.project &&
      !prevProps.project.projectList.length &&
      project.projectList.length
    ) {
      dispatch(
        setPickedProject(
          project.projectList.find(item => item._id === params.project)
        )
      )
    }

    if (project.pickedProject !== prevProps.project.pickedProject) {
      if (project && project.pickedProject) {
        this.setParams({ ...params, project: project.pickedProject._id })
      } else {
        delete params.project
        this.setParams(params)
      }
    }

    if (prevSearch !== nextSearch || this.props.project !== prevProps.project) {
      this.fetchProducts()
    }
  }

  fetchProjects = async () => {
    try {
      const projects = await projectService.list()

      this.setState({
        projects
      })
    } catch (error) {
      notification.error({
        message: `${this.props.intl.formatMessage({
          id: 'projects'
        })} - download error`
      })

      this.setState({
        projects: []
      })
    }
  }

  formatDecimals = value => {
    const convertToString = `${value}`
    if (convertToString.includes('.')) {
      if (
        convertToString.substr(convertToString.length - 1) === '0' &&
        convertToString.toString().split('.')[1].length !== 2
      ) {
        return 1
      } else {
        if (convertToString % 1 !== 0) {
          return convertToString.toString().split('.')[1].length
        }
        return 0
      }
    }
  }

  fetchProjectsOnSearch = async value => {
    this.setState({
      formLoading: true
    })
    const timer = setTimeout(async () => {
      try {
        const projects = await projectService.listV2({
          projectName: [value],
          limit: 100
        })

        this.setState({
          projects: projects.docs,
          formLoading: false
        })

        clearTimeout(timer)
      } catch (error) {
        this.setState({
          projects: [],
          formLoading: false
        })
        clearTimeout(timer)
      }
    })
  }

  handleOnFocusPriceHandler = value => {
    this.setState({
      priceFocused: true
    })
  }

  formatAfterDotZeros = (field, value, product) => {
    let parsedValue = value
    const countNumbersAfterDot = this.formatDecimals(
      !product ? this.state.product[field] : product[field]
    )

    if (countNumbersAfterDot === 1) {
      parsedValue += '0'
    }

    if (parsedValue.substr(parsedValue.length - 1) === '.') {
      parsedValue += '00'
    }
    return parsedValue
  }

  handleOnBlurNumberHandler = (field, value) => {
    this.setState(
      {
        priceFocused: false
      },
      () => {
        const finalValue = this.formatAfterDotZeros(field, value)

        this.setState({
          product: {
            ...this.state.product,
            [field]: finalValue
          }
        })
      }
    )
  }

  updateStateProduct = (field, value, numbers) => {
    if (numbers) {
      const onlyDigets = value.replace(/[^0-9.]/g, '')
      const removeMultiplyDots = onlyDigets.replace(/\.+/g, '.')
      const formattedNumber = removeMultiplyDots.replace(
        /(.*\.[0-9][0-9]?).*/g,
        '$1'
      )
      this.setState({
        product: {
          ...this.state.product,
          [field]: formattedNumber
        }
      })
    } else {
      this.setState({
        product: {
          ...this.state.product,
          [field]: value
        }
      })
    }
  }

  onSearchAutocomplete = async (categoryType, value) => {
    const timer = setTimeout(async () => {
      if (categoryType === 'manufacturer') {
        this.setState({
          formLoading: true
        })
        try {
          const response = await manufacturersService.listV2({
            name: [value],
            limit: 100
          })

          this.setState({
            manufacturerSearchResult: response.docs,
            formLoading: false
          })
          clearTimeout(timer)
        } catch (error) {
          this.setState({
            formLoading: false
          })
          console.log('Fetching error')
          clearTimeout(timer)
        }
      } else {
        this.setState({
          formLoading: true
        })
        try {
          const response = await categoriesService.listV2({
            name: [value],
            limit: 100
          })

          this.setState({
            categoriesSearchResult: response.docs,
            formLoading: false
          })
          clearTimeout(timer)
        } catch (error) {
          console.log('Fetching error')
          this.setState({
            formLoading: false
          })
          clearTimeout(timer)
        }
      }
    }, 1000)
  }

  updateStateProductObject = (field, property, value, e) => {
    this.setState({
      product: {
        ...this.state.product,
        [field]: {
          [property]: value,
          id: e.props.id
        }
      }
    })
  }

  fetchProducts = async () => {
    const params = this.getParams()

    this.setState({
      loading: true
    })
    try {
      const products = await productService.listV2({
        ...params
      })

      const pagination = {
        ...this.state.pagination
      }

      pagination.total = products.totalDocs

      this.setState({
        products: products.docs,
        pagination: {
          ...pagination,
          pageSize: products.limit,
          current: products.page
        },
        categoryFilters: products.filterOptions.category,
        manufacturerFilters: products.filterOptions.manufacturer,
        createdByFilters: products.filterOptions.createdBy,
        loading: false,
        params: { ...params }
      })
    } catch (error) {
      notification.error({
        message: `${this.props.intl.formatMessage({
          id: 'products'
        })} - download error`
      })

      this.setState({
        visible: false,
        loading: false
      })
    }
  }

  handleAddProduct = async () => {
    const { product } = this.state

    const payload = {
      name: product.name,
      ordernumber: product.ordernumber,
      description: product.description,
      ean: product.ean,
      width: product.width,
      height: product.height,
      depth: product.depth,
      supplierNumber: product.supplierNumber,
      length: product.length,
      price: product.price ? product.price : -1,
      active: product.active ? product.active : false
    }

    if (product.project) {
      payload.project = product.project._id || product.project || null
    }

    if (product.manufacturer) {
      payload.manufacturer = product.manufacturer.id
    }
    if (product.category) {
      payload.category = product.category.id
    }

    this.props.form.validateFields(async err => {
      if (!err) {
        try {
          const productResponse = await productService.addProduct(payload)

          this.fetchProducts()
          notification.success({
            message: `${this.props.intl.formatMessage({
              id: 'created successfully'
            })}`
          })

          this.setState({
            visible: false,
            product: {},
            products: [...this.state.products, productResponse]
          })
        } catch (error) {
          notification.error({
            message: this.props.intl.formatMessage({
              id: 'saving error'
            }),
            description: error.error
          })
        }
      }
    })
  }

  handleSaveProduct = async () => {
    const { product, products } = this.state
    const payload = {
      name: product.name,
      ordernumber: product.ordernumber,
      description: product.description,
      ean: product.ean && product.ean,
      width: product.width,
      height: product.height,
      depth: product.depth,
      supplierNumber: product.supplierNumber,
      length: product.length,
      price: product.price ? product.price : -1,
      active: product.active ? product.active : false
    }

    if (product.project) {
      payload.project = product.project._id
        ? product.project._id
        : product.project
    }

    if (product.manufacturer) {
      payload.manufacturer = product.manufacturer.id
    } else {
      payload.manufacturer = ''
    }
    if (product.category) {
      payload.category = product.category.id
    } else {
      payload.category = ''
    }

    try {
      await productService.updateProduct(product._id, payload)

      this.fetchProducts()
      notification.success({
        message: `${this.props.intl.formatMessage({
          id: 'updated successfully'
        })}`
      })

      const updateIndex = findIndexInArray(products, product._id)
      const updatedArray = [...products]
      updatedArray[updateIndex] = product

      this.setState({
        visible: false,
        product: {},
        products: [...updatedArray]
      })
    } catch (error) {
      notification.error({
        message: this.props.intl.formatMessage({
          id: 'saving error'
        }),
        description: error.error
      })
    }
  }

  handleDeleteProduct = async () => {
    try {
      await productService.deleteProduct(this.state.product._id)

      notification.success({
        message: this.props.intl.formatMessage({
          id: 'deleted successfully'
        })
      })

      this.setState({
        visible: false,
        product: {},
        products: [
          ...this.state.products.filter(
            product => product._id !== this.state.product._id
          )
        ]
      })
    } catch (error) {
      notification.error({
        message: this.props.intl.formatMessage({
          id: 'deleting error'
        }),
        description: error.message
      })
    }
  }

  renderCategoryFilter = () => {
    const filters = []
    this.state.categoryFilters.map(category => {
      const filterObject = {
        text: category.name,
        value: category._id
      }
      return filters.push(filterObject)
    })
    return filters
  }

  renderManufacturerFilter = () => {
    const filters = []
    this.state.manufacturerFilters.map(manufacturer => {
      const filterObject = {
        text: manufacturer.name,
        value: manufacturer._id
      }
      return filters.push(filterObject)
    })
    return filters
  }

  handleOnTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination }
    pager.offset = pagination.current
    const params = {
      limit: pagination.pageSize,
      offset: pagination.current,
      sortField: sorter.field,
      sortOrder: sorter.order,
      ...filters
    }
    this.setParams(params)
  }

  handleShowDrawer = async product => {
    if (product.ordernumber) {
      this.setState({
        visible: true,
        drawerOption: 'edit',
        product,
        readOnlyNewProject: false
      })
    } else {
      this.setState({
        visible: true,
        product: {
          project: this.props.selectedProject._id
            ? this.props.selectedProject
            : null
        },
        readOnlyNewProject: !!this.props.selectedProject._id
      })
    }
  }

  handleHideDrawer = () => {
    this.props.form.resetFields()
    this.setState({
      visible: false
    })
  }

  onSelectChange = selectedRowKeys => {
    this.setState({
      selectedRowKeys
    })
  }

  handleSearch = (selectedKeys, field, confirm) => {
    confirm()
    this.setState({
      search: {
        ...this.state.search,
        [field]: selectedKeys[0]
      }
    })
  }

  handleReset = (field, clearFilters) => {
    clearFilters()
    const { params } = this.state
    const outputObject = { ...params }
    delete outputObject[field]
    this.setParams(outputObject)
    this.setState({
      [field + 'Search']: undefined
    })
  }

  setSelectedKeys = (dataIndex, value, setSelectedKeys) => {
    setSelectedKeys(value ? [value] : [])
    this.setState({
      [dataIndex + 'Search']: value
    })
  }

  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this[`searchInput${dataIndex}`] = node
          }}
          placeholder={this.props.intl.formatMessage({ id: 'search' })}
          value={
            this.state[dataIndex + 'Search'] !== undefined
              ? this.state[dataIndex + 'Search']
              : this.state.params[dataIndex]
          }
          onChange={e =>
            this.setSelectedKeys(dataIndex, e.target.value, setSelectedKeys)
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, dataIndex, confirm)
          }
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type='primary'
          onClick={() => this.handleSearch(selectedKeys, dataIndex, confirm)}
          icon='search'
          size='small'
          disabled={!this.state[dataIndex + 'Search']}
          style={{ width: 90, marginRight: 8 }}
        >
          Suchen
        </Button>
        <Button
          onClick={() => this.handleReset(dataIndex, clearFilters)}
          size='small'
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon
        type='search'
        style={{
          color:
            this.state.params[dataIndex] || filtered ? '#1890ff' : undefined
        }}
      />
    ),
    // onFilter: (value, record) => String(record[dataIndex])
    // .toString()
    // .toLowerCase()
    // .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this[`searchInput${dataIndex}`].select())
      }
    }
  })

  render () {
    const columns = [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        sorter: true,
        ...this.getColumnSearchProps('name')
      },
      {
        title: this.props.intl.formatMessage({ id: 'ordernumber' }),
        dataIndex: 'ordernumber',
        key: 'ordernumber'
      },
      {
        title: this.props.intl.formatMessage({ id: 'manufacturer' }),
        dataIndex: 'manufacturer',
        key: 'manufacturer',
        filterMultiple: false,
        filters: this.renderManufacturerFilter(),
        render: (manufacturer = '') => {
          if (manufacturer) {
            return manufacturer.name
          }
          return ''
        },
        filteredValue: this.state.params.manufacturer
      },
      {
        title: this.props.intl.formatMessage({ id: 'category' }),
        dataIndex: 'category',
        key: 'category',
        render: (category = '') => {
          if (category) {
            return category.name
          }
          return ''
        },
        filters: this.renderCategoryFilter(),
        sorter: true,
        filteredValue: this.state.params.category
      },
      {
        title: this.props.intl.formatMessage({ id: 'active' }),
        dataIndex: 'active',
        key: 'active',
        render: active =>
          active && <Icon type='check' style={{ color: '#005591' }} />
      },
      {
        title: this.props.intl.formatMessage({ id: 'price' }),
        dataIndex: 'price',
        key: 'price',
        render: price => {
          let parsedValue = price.toString()
          const countNumbersAfterDot = this.formatDecimals(price)

          if (countNumbersAfterDot === 1) {
            parsedValue += '0'
          }

          if (parsedValue.substr(parsedValue.length - 1) === '.') {
            parsedValue += '00'
          }
          return <div style={{ width: 135 }}>{`CHF ${parsedValue}`}</div>
        },
        sorter: true
      },
      {
        title: this.props.intl.formatMessage({ id: 'created at' }),
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: createdAt => moment(createdAt).format('DD.MM.YYYY, HH:mm'),
        sorter: true,
        defaultSortOrder:
          this.state.params.sortField === 'createdAt'
            ? this.state.params.sortOrder
            : 'descend'
      },
      {
        title: this.props.intl.formatMessage({ id: 'created by' }),
        dataIndex: 'createdBy',
        key: 'createdBy',
        render: createdBy =>
          createdBy ? `${createdBy.firstName} ${createdBy.lastName}` : '',
        sorter: true,
        filters: this.state.createdByFilters.map(user => ({
          text: `${user.firstName} ${user.lastName}`,
          value: user._id
        })),
        filteredValue: this.state.params.createdBy
      }
    ]

    if (!this.props.project) {
      columns.push({
        title: this.props.intl.formatMessage({ id: 'project' }),
        key: 'project',
        dataIndex: 'project.projectName'
      })
    }
    columns.push({
      key: 'actions',
      render: (text, record) => (
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'flex-end'
          }}
        >
          <Button onClick={() => this.handleShowDrawer(record)}>
            <Icon type='edit' />
          </Button>
        </div>
      )
    })

    const drawerButtonsAdd = [
      <Button key='cancel' onClick={this.handleHideDrawer}>
        <FormattedMessage id='cancel' />
      </Button>,
      <Button key='save2' type='primary' onClick={this.handleAddProduct}>
        <FormattedMessage id='save' />
      </Button>
    ]

    const drawerButtonsEdit = [
      <Button key='cancel' onClick={this.handleHideDrawer}>
        <FormattedMessage id='cancel' />
      </Button>,
      <Button key='save3' type='primary' onClick={this.handleSaveProduct}>
        <FormattedMessage id='save' />
      </Button>
    ]

    if (
      ['admin', 'superadmin'].includes(this.props.auth.authUser.scope[0]) &&
      this.state.product &&
      !this.state.product.isVerified
    ) {
      drawerButtonsEdit.unshift(
        <PopconfirmDelete key={4} onConfirmAction={this.handleDeleteProduct} />
      )
    }

    const {
      products,
      projects,
      readOnlyNewProject,
      drawerOption,
      visible,
      product,
      categoriesSearchResult,
      manufacturerSearchResult,
      loading,
      formLoading
    } = this.state

    const headerButtons = [
      <Button key={354655589832} type='primary' onClick={this.handleShowDrawer}>
        <FormattedMessage id='create product' />
      </Button>
    ]

    return (
      <div>
        <FormattedMessage id='head.title.products'>
          {title => (
            <Helmet>
              <title>{title}</title>
            </Helmet>
          )}
        </FormattedMessage>

        <PageTitle
          title={this.props.intl.formatMessage({ id: 'products' })}
          buttons={headerButtons}
        />

        <Table
          // eslint-disable-next-line no-return-assign
          ref={ref => (this._table = ref)}
          rowKey={record => record._id}
          columns={columns}
          dataSource={products}
          loading={loading}
          onChange={this.handleOnTableChange}
          onRow={record => ({
            onDoubleClick: () => {
              this.handleShowDrawer(record)
            }
          })}
          defaultPageSize={this.state.pagination.defaultPageSize}
          pagination={this.state.pagination}
        />

        <DrawerComponent
          title={
            drawerOption === 'edit'
              ? this.props.intl.formatMessage({
                  id: 'edit product'
                })
              : this.props.intl.formatMessage({ id: 'add product' })
          }
          visible={visible}
          onClose={this.handleHideDrawer}
          onCancel={this.handleHideDrawer}
          footerButtons={
            drawerOption === 'edit' ? drawerButtonsEdit : drawerButtonsAdd
          }
        >
          <ProductForm
            option={drawerOption}
            product={product}
            projects={projects}
            formLoading={formLoading}
            readOnlyNewProject={readOnlyNewProject}
            fetchProjectsOnSearch={this.fetchProjectsOnSearch}
            categories={categoriesSearchResult}
            manufacturers={manufacturerSearchResult}
            updateProduct={this.updateStateProduct}
            onFocusPrice={this.handleOnFocusPriceHandler}
            priceFocused={this.state.priceFocused}
            onBlurNumber={this.handleOnBlurNumberHandler}
            handleSearchAutocomplete={this.onSearchAutocomplete}
            updateStateProductObject={this.updateStateProductObject}
            form={this.props.form}
          />
        </DrawerComponent>
      </div>
    )
  }
}

ProductsPage.propTypes = {
  auth: PropTypes.object,
  history: PropTypes.object.isRequired,
  path: PropTypes.string.isRequired,
  selectedProject: PropTypes.object.isRequired,
  project: PropTypes.object,
  location: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  auth: state.auth,
  selectedProject: state.project.pickedProject,
  project: state.project
})

export default injectIntl(
  Form.create({ name: 'requests_form' })(connect(mapStateToProps)(ProductsPage))
)
