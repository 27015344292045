import React from 'react'
import { injectIntl } from 'react-intl'
import { Typography } from 'antd'
import SingleTable from './SingleTable'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

const { Title } = Typography

class ProductsTables extends React.Component {
  render () {
    const { recording, categories } = this.props
    let sections = {}

    if (recording && recording.products) {
      recording.products.forEach(item => {
        if (item.extra_fields) {
          item.extra_fields.forEach(extraField => {
            categories.forEach(category => {
              category.custom_form &&
                category.custom_form.fields &&
                category.custom_form.fields.forEach(field => {
                  if (field.id === extraField.id) {
                    extraField.label = field.label
                    extraField.element = field.element
                    extraField.product_id = item._id
                    extraField.options = field.options
                  }
                })
            })
          })
        }

        if (sections[item.product.category._id]) {
          sections = {
            ...sections,
            [item.product.category._id]: [
              ...sections[item.product.category._id],
              item
            ]
          }
        } else {
          sections = {
            ...sections,
            [item.product.category._id]: []
          }
          sections[item.product.category._id].push(item)
        }
      })
    }
    return (
      <>
        {Object.keys(sections).map(key => (
          <div key={sections[key][0].product._id}>
            <Title style={{ marginTop: '20px' }} level={4}>
              {sections[key][0].product.category.name}
            </Title>
            <SingleTable
              products={sections[key]}
              category={categories.find(item => item._id === key)}
              allRecordingProducts={recording.products}
            />
          </div>
        ))}
      </>
    )
  }
}

ProductsTables.propTypes = {
  recording: PropTypes.object,
  categories: PropTypes.array.isRequired
}

const mapStateToProps = state => {
  return {
    recording: state.recordings.pickedRecording,
    products: state.products.productsList,
    categories: state.category.categoryList
  }
}

export default injectIntl(connect(mapStateToProps)(ProductsTables))
