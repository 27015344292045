import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl, FormattedMessage } from 'react-intl'

import moment from 'moment'
import { Icon, Menu, Dropdown, Tag } from 'antd'
import { connect } from 'react-redux'
import { setPickedWorkorder } from '../../../../../ducks/workorder'
import axios from 'axios'
import config from '../../../../../config'

const statusOptions = {
  0: 'none',
  1: 'open',
  2: 'in progress',
  3: 'on hold',
  4: 'complete'
}

const CollapseHeader = ({ workorder, intl, dispatch }) => {
  const handleClick = event => {
    event.stopPropagation()
  }

  const showDrawerEdit = workorder => {
    dispatch(setPickedWorkorder({ income: workorder, value: {} }))
  }

  const showPDF = workorder => {
    axios({
      method: 'get',
      url: `${config.server.url}/workorder/${workorder._id}/pdf`,
      responseType: 'arraybuffer',
      headers: {
        authorization: localStorage.getItem('authToken')
      }
    }).then(function (response) {
      const blob = new Blob([response.data], { type: 'application/pdf' })
      const fileName = `${workorder._id}.pdf`

      if (navigator && navigator.msSaveOrOpenBlob) {
        navigator.msSaveOrOpenBlob(blob, fileName)
      } else {
        const blob = new Blob([response.data], { type: 'application/pdf' })

        const link = document.createElement('a')
        link.setAttribute('href', window.URL.createObjectURL(blob))
        link.setAttribute('download', `${workorder.name}.pdf`)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }
    })
  }

  return (
    <div className='collapse-header'>
      <div className='name-block'>
        <p>
          <b>{intl.formatMessage({ id: 'name' })}:</b> &nbsp; {workorder.name} (
          {workorder.docNumber})
        </p>
        <p>{moment(workorder.createdAt).format('DD.MM.YYYY, HH:mm')} </p>
      </div>
      {workorder.createdBy && (
        <p className='createBy-cover'>
          {workorder.createdBy.fullName || ''} &nbsp;
          {`<${workorder.createdBy.email}>`}
        </p>
      )}

      <div className='tag-cover'>
        <Tag color='blue'>
          {intl.formatMessage({ id: statusOptions[workorder.status] })}
        </Tag>
      </div>

      <div className='action-button-cover' onClick={handleClick}>
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item key='0' onClick={() => showDrawerEdit(workorder)}>
                <Icon type={workorder.status === 'approved' ? 'eye' : 'edit'} />
                {workorder.status === 'approved'
                  ? intl.formatMessage({ id: 'view' })
                  : intl.formatMessage({ id: 'edit' })}
              </Menu.Item>
              <Menu.Item key='1' onClick={() => showPDF(workorder)}>
                <Icon type='file-pdf' />
                <FormattedMessage id='generate pdf' />
              </Menu.Item>
            </Menu>
          }
          trigger={['click']}
        >
          <a className='ant-dropdown-link' href='#'>
            <Icon type='more' style={{ fontSize: '2rem', color: '#444' }} />
          </a>
        </Dropdown>
      </div>
    </div>
  )
}

CollapseHeader.propTypes = {
  workorder: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired
}

export default injectIntl(connect()(CollapseHeader))
