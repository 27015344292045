import React from 'react'
import moment from 'moment'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { injectIntl, FormattedMessage } from 'react-intl'
import { withRouter } from 'react-router-dom'
import { Button, Icon, Table, Form, Input, Dropdown, Menu, Tag } from 'antd'
import queryString from 'query-string'

import { setPickedProject } from '../../../../ducks/project'
import {
  setPickedRecording,
  setRecordingDefectCreationData
} from '../../../../ducks/recordings'
import {
  fetchAcceptance,
  setAcceptanceQueryParams,
  setPickedAcceptance,
  downloadPDF
} from '../../../../ducks/acceptance'

export class AcceptanceTable extends React.Component {
  _isMounted = false
  state = {
    loading: false,
    createdByFilters: [],
    params: {},
    pagination: {
      defaultPageSize: 10,
      showSizeChanger: true,
      pageSizeOptions: ['10', '20', '30', '50', '100']
    },
    recordingsList: []
  }

  getParams = () => {
    const { history } = this.props
    this._isMounted = true
    const searchString = history.location.search
    let searchQuery
    if (searchString.length) {
      searchQuery = queryString.parse(searchString, { arrayFormat: 'bracket' })
      if (searchQuery.page) {
        searchQuery.offset = searchQuery.page
        delete searchQuery.page
      }
      if (!searchQuery.limit) {
        searchQuery.limit = 10
      }
      if (!searchQuery.sortField && !searchQuery.sortOrder) {
        searchQuery.sortField = 'createdAt'
        searchQuery.sortOrder = 'descend'
      }

      return searchQuery
    }
  }

  setParams = paramsObject => {
    const { history, project } = this.props
    if (project && project.project) {
      paramsObject.project = project.project._id
    }
    if (paramsObject.offset) {
      paramsObject.page = paramsObject.offset
      delete paramsObject.offset
    }
    if (paramsObject.limit === 10) {
      delete paramsObject.limit
    }
    if (
      (paramsObject.sortField === 'createdAt' &&
        paramsObject.sortOrder === 'descend') ||
      !paramsObject.sortOrder
    ) {
      delete paramsObject.sortField
      delete paramsObject.sortOrder
    }
    this.setState({ params: paramsObject })
    history.push({
      search: queryString.stringify(paramsObject, { arrayFormat: 'bracket' })
    })
  }

  handleAcceptanceResponse = async acceptance => {
    const { dispatch } = this.props
    const params = this.getParams()
    const pagination = {
      ...this.state.pagination
    }
    pagination.total = acceptance.totalDocs
    this.setState({
      createdByFilters: acceptance.filterOptions.createdBy,
      loading: false,
      pagination: {
        ...pagination,
        pageSize: acceptance.limit,
        current: acceptance.page
      },
      params: { ...params }
    })
    dispatch(
      setAcceptanceQueryParams({
        ...params,
        limit: this.state.defaultPageSize
      })
    )
  }

  requestAcceptances = () => {
    const { dispatch } = this.props
    const params = this.getParams()
    this.setState({
      loading: true
    })
    dispatch(
      fetchAcceptance({
        limit: this.state.defaultPageSize,
        ...params
      })
    ).then(data => {
      this.handleAcceptanceResponse(data)
    })
  }

  componentDidMount () {
    this.requestAcceptances()
  }

  componentDidUpdate (prevProps) {
    const {
      location: { search: prevSearch }
    } = prevProps
    const {
      location: { search: nextSearch },
      project,
      dispatch
    } = this.props

    const params = { ...this.getParams() }

    // if (
    //   params.project &&
    //   !prevProps.project.projectList.length &&
    //   project.projectList.length
    // ) {
    //   dispatch(
    //     setPickedProject(
    //       project.projectList.find(item => item._id === params.project)
    //     )
    //   )
    // }

    if (project.pickedProject !== prevProps.project.pickedProject) {
      if (project && project.pickedProject) {
        this.setParams({ ...params, project: project.pickedProject._id })
      } else {
        delete params.project
        this.setParams(params)
      }
    }

    if (
      prevSearch !== nextSearch ||
      project.pickedProject !== prevProps.project.pickedProject
    ) {
      this.requestAcceptances()
    }
  }

  showAcceptanceDrawer = acceptance => {
    const { dispatch } = this.props
    dispatch(setPickedAcceptance(acceptance))
  }

  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination }
    const stateParams = { ...this.state.params }

    pager.offset = pagination.current
    const params = {
      ...stateParams,
      limit: pagination.pageSize,
      offset: pagination.current,
      sortField: sorter.field,
      sortOrder: sorter.order,
      ...filters
    }
    Object.keys(filters).forEach(key => {
      if (filters[key].length === 0) {
        delete params[key]
      }
    })
    this.setParams(params)
  }

  downloadDocument = (recording, fileType) => {
    const { dispatch } = this.props

    dispatch(downloadPDF(recording, fileType === 'acceptance'))
  }

  setSelectedKeys = (dataIndex, value, setSelectedKeys) => {
    setSelectedKeys(value ? [value] : [])
    this.setState({
      [dataIndex + 'Search']: value
    })
  }

  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this[`searchInput${dataIndex}`] = node
          }}
          placeholder={this.props.intl.formatMessage({ id: 'search' })}
          value={
            this.state[dataIndex + 'Search'] !== undefined
              ? this.state[dataIndex + 'Search']
              : this.state.params[dataIndex]
          }
          onChange={e =>
            this.setSelectedKeys(dataIndex, e.target.value, setSelectedKeys)
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, dataIndex, confirm)
          }
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type='primary'
          onClick={() => this.handleSearch(selectedKeys, dataIndex, confirm)}
          icon='search'
          size='small'
          style={{ width: 90, marginRight: 8 }}
          disabled={!this.state[dataIndex + 'Search']}
        >
          Suchen
        </Button>
        <Button
          onClick={() => this.handleReset(dataIndex, clearFilters)}
          size='small'
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon
        type='search'
        style={{
          color:
            this.state.params[dataIndex] || filtered ? '#1890ff' : undefined
        }}
      />
    ),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this[`searchInput${dataIndex}`].select())
      }
    }
  })

  handleSearch = (selectedKeys, field, confirm) => {
    confirm()
    this.setState({
      search: {
        ...this.state.search,
        [field]: selectedKeys[0]
      }
    })
  }

  handleReset = (field, clearFilters) => {
    clearFilters()
    const { params } = this.state
    const outputObject = { ...params }
    delete outputObject[field]
    this.setParams(outputObject)
    this.setState({
      [field + 'Search']: undefined
    })
  }

  createSingleDefect = data => {
    const { dispatch } = this.props
    dispatch(setRecordingDefectCreationData({ income: data }))
  }

  createMultipleDefects = data => {
    const { dispatch } = this.props
    const recordingsList = data.recordings.filter(
      item =>
        !item.submissions || item.submissions.every(e => e.name !== 'defect')
    )
    const step = {
      index: 0,
      total: recordingsList.length
    }

    const actualSituation =
      (recordingsList[0].images &&
        recordingsList[0].images.map(image => image.comment).join('; ')) ||
      ''
    dispatch(
      setRecordingDefectCreationData({
        income: {
          ...recordingsList[0],
          actualSituation
        },
        step,
        type: 'acceptance',
        list: recordingsList
      })
    )
  }

  showRecordingDrawer = recording => {
    const { dispatch } = this.props
    dispatch(setPickedRecording(recording))
  }

  render () {
    const { loading, pagination, createdByFilters, params } = this.state
    const { acceptances, objectName, intl } = this.props

    const expandedRowRender = acceptance => {
      const recordingColumns = [
        {
          title: 'Number',
          key: 'docNumber',
          dataIndex: 'docNumber',
          render: docNumber => {
            return docNumber || ''
          }
        },
        {
          title: objectName,
          key: 'object',
          dataIndex: 'objectId',
          render: object => {
            return (object && object.name) || ''
          }
        },
        {
          title: intl.formatMessage({
            id: 'reference number'
          }),
          key: 'submittedRefNr',
          dataIndex: 'submittedRefNr',
          render: (text, record) => {
            const data = []

            if (record.submittedWorkorder && record.submittedWorkorderId) {
              data.push(
                <span key={0}>
                  <Tag color='blue'>
                    <Icon type='check' /> Workorder (
                    {record.submittedWorkorderId.docNumber})
                  </Tag>
                  &nbsp;&nbsp;
                </span>
              )
            }

            if (record.submittedTask && record.submittedTaskId) {
              data.push(
                <span key={1}>
                  <Tag color='blue'>
                    <Icon type='check' /> Task (
                    {record.submittedTaskId.docNumber})
                  </Tag>
                  &nbsp;&nbsp;
                </span>
              )
            }

            if (record.submitted) {
              data.push(
                <span key={3}>
                  <Tag color='blue'>
                    <Icon type='check' /> SAP ({record.submittedRefNr})
                  </Tag>
                </span>
              )
            }

            if (
              record.submissions &&
              record.submissions.some(e => e.name === 'defect')
            ) {
              data.push(
                <span key={4}>
                  <Tag color='blue'>
                    <Icon type='check' />
                    &nbsp;
                    <FormattedMessage id='defect' />
                    &nbsp; (
                    {record.submissions.map((a, index) => (
                      <span key={index}>{a.reference}</span>
                    ))}
                    )
                  </Tag>
                </span>
              )
            }
            return data
          }
        },
        {
          key: 'actions',
          render: record => {
            return (
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Dropdown
                  overlay={
                    <Menu>
                      <Menu.Item
                        key='0'
                        onClick={() => this.showRecordingDrawer(record)}
                      >
                        <Icon type='edit' />
                        &nbsp;
                        <FormattedMessage id='edit' />
                      </Menu.Item>
                      <Menu.Item
                        key='1'
                        onClick={() =>
                          this.downloadDocument(record, 'recording')
                        }
                      >
                        <Icon type='file-pdf' />
                        &nbsp;
                        <FormattedMessage id='generate pdf' />
                      </Menu.Item>

                      <Menu.Divider />

                      {((record.submissions &&
                        !record.submissions.some(e => e.name === 'defect')) ||
                        !record.submissions) && (
                        <Menu.Item
                          key='5'
                          onClick={() => this.createSingleDefect(record)}
                        >
                          <Icon type='plus' />
                          &nbsp;
                          <FormattedMessage id='create defect mainx' />
                        </Menu.Item>
                      )}

                      {record.submissions &&
                        record.submissions.some(e => e.name === 'defect') && (
                          <Menu.Item disabled key='5'>
                            <Icon type='check' />
                            &nbsp;
                            <FormattedMessage id='defect created' />
                          </Menu.Item>
                        )}
                    </Menu>
                  }
                  trigger={['click']}
                >
                  <a className='ant-dropdown-link' href='#'>
                    <Icon
                      type='more'
                      style={{ fontSize: '2rem', color: '#444' }}
                    />
                  </a>
                </Dropdown>
              </div>
            )
          }
        }
      ]

      return (
        <Table
          rowKey={record => record._id}
          columns={recordingColumns}
          dataSource={acceptance.recordings}
          onRow={record => ({
            onDoubleClick: () => {
              this.showRecordingDrawer(record)
            }
          })}
          pagination={false}
        />
      )
    }

    const columns = [
      {
        title: intl.formatMessage({ id: 'number' }),
        key: 'docNumber',
        dataIndex: 'docNumber',
        ...this.getColumnSearchProps('docNumber')
      },
      {
        title: 'Name',
        key: 'name',
        dataIndex: 'name',
        sorter: true,
        ...this.getColumnSearchProps('name')
      },
      {
        title: intl.formatMessage({ id: 'created at' }),
        key: 'createdAt',
        dataIndex: 'createdAt',
        render: createdAt => moment(createdAt).format('DD.MM.YYYY, HH:mm'),
        sorter: true,
        defaultSortOrder:
          params.sortField === 'createdAt'
            ? this.state.params.sortOrder
            : 'descend'
      },
      {
        title: intl.formatMessage({ id: 'created by' }),
        key: 'createdBy',
        dataIndex: 'createdBy',
        render: createdBy =>
          createdBy ? `${createdBy.firstName} ${createdBy.lastName}` : '',
        filters: createdByFilters.map(user => ({
          text: `${user.firstName} ${user.lastName}`,
          value: user._id
        })),
        filteredValue: params.createdBy
      },
      {
        key: 'actions',
        render: (text, record) => (
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              justifyContent: 'flex-end'
            }}
          >
            <Dropdown
              overlay={
                <Menu>
                  <Menu.Item
                    key='0'
                    onClick={() => this.showAcceptanceDrawer(record)}
                  >
                    <Icon type='edit' />
                    &nbsp;
                    <FormattedMessage id='edit' />
                  </Menu.Item>
                  <Menu.Item
                    key='1'
                    onClick={() => this.downloadDocument(record, 'acceptance')}
                  >
                    <Icon type='file-pdf' />
                    &nbsp;
                    <FormattedMessage id='generate pdf' />
                  </Menu.Item>

                  <Menu.Divider />
                  {record.recordings &&
                    record.recordings.filter(
                      item =>
                        !item.submissions ||
                        !item.submissions.some(e => e.name === 'defect')
                    ).length > 0 && (
                      <Menu.Item
                        key='5'
                        disabled={record.recordings.length === 0}
                        onClick={() => this.createMultipleDefects(record)}
                      >
                        <Icon type='plus' />
                        &nbsp;
                        <FormattedMessage id='create defects mainx' />
                      </Menu.Item>
                    )}

                  {record.recordings &&
                    record.recordings.length > 0 &&
                    record.recordings.filter(
                      item =>
                        !item.submissions ||
                        !item.submissions.some(e => e.name === 'defect')
                    ).length === 0 && (
                      <Menu.Item disabled key='5'>
                        <Icon type='check' />
                        &nbsp;
                        <FormattedMessage id='defect created' />
                      </Menu.Item>
                    )}
                </Menu>
              }
              trigger={['click']}
            >
              <a className='ant-dropdown-link' href='#'>
                <Icon type='more' style={{ fontSize: '2rem', color: '#444' }} />
              </a>
            </Dropdown>
          </div>
        )
      }
    ]

    return (
      <Table
        columns={columns}
        dataSource={acceptances}
        loading={loading}
        rowKey={record => record._id}
        onChange={this.handleTableChange}
        onRow={record => ({
          onDoubleClick: () => this.showAcceptanceDrawer(record)
        })}
        defaultPageSize={pagination.defaultPageSize}
        pagination={pagination}
        expandedRowRender={expandedRowRender}
      />
    )
  }
}

AcceptanceTable.propTypes = {
  project: PropTypes.object,
  acceptances: PropTypes.array.isRequired,
  objectName: PropTypes.string,
  history: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired
}

const mapStateToProps = state => {
  return {
    project: state.project,
    acceptances: state.acceptance.acceptancesData.docs || [],
    objectName:
      (state.settings.data.buildx && state.settings.data.buildx.objectName) ||
      ''
  }
}

export default withRouter(
  injectIntl(
    Form.create({ name: 'acceptance_form' })(
      connect(mapStateToProps)(AcceptanceTable)
    )
  )
)
