import React from 'react'
import PropTypes from 'prop-types'

import { Form, Upload, Input, Button, Icon } from 'antd'
import { injectIntl, FormattedMessage } from 'react-intl'
import config from 'config'

const BaseX = props => {
  const {
    basex,
    buildX,
    formItemLayout,
    timestamp,
    uploading,
    logoUpload,
    beforeUpload,
    updateBaseX,
    updateBuildX
  } = props
  const { getFieldDecorator } = props.form
  return (
    <>
      <Form.Item {...formItemLayout} label='Logo'>
        <img
          src={`${config.server.url}/uploads/settings/logo.png?${timestamp}`}
          alt='logo'
          style={{
            maxWidth: 200,
            marginBottom: 10
          }}
        />

        <div>
          <Upload
            action={logoUpload}
            showUploadList={false}
            beforeUpload={beforeUpload}
          >
            <Button loading={uploading}>
              <FormattedMessage id='click to upload' />
              <Icon type='upload' />
            </Button>
          </Upload>
        </div>
      </Form.Item>

      <Form.Item
        {...formItemLayout}
        label={
          <span>
            <FormattedMessage id='company name' />
            <span style={{ color: '#005591' }}>*</span>
          </span>
        }
        required={false}
      >
        {getFieldDecorator('companyname', {
          initialValue: basex.companyname,
          rules: [
            {
              required: true,
              message: `${props.intl.formatMessage({
                id: 'company name'
              })} ${props.intl.formatMessage({
                id: 'is required'
              })}`
            }
          ]
        })(
          <Input
            onChange={e => updateBaseX('companyname', e.target.value)}
            placeholder={props.intl.formatMessage({
              id: 'company name'
            })}
          />
        )}
      </Form.Item>

      <Form.Item
        {...formItemLayout}
        label={
          <span>
            <FormattedMessage id='company address' />
            <span style={{ color: '#005591' }}>*</span>
          </span>
        }
        required={false}
      >
        {getFieldDecorator('companyaddress', {
          initialValue: basex.companyaddress,
          rules: [
            {
              required: true,
              message: `${props.intl.formatMessage({
                id: 'company address'
              })} ${props.intl.formatMessage({
                id: 'is required'
              })}`
            }
          ]
        })(
          <Input
            onChange={e => updateBaseX('companyaddress', e.target.value)}
            placeholder={props.intl.formatMessage({
              id: 'company address'
            })}
          />
        )}
      </Form.Item>

      <Form.Item
        {...formItemLayout}
        label={
          <span>
            <FormattedMessage id='zip' />
            <span style={{ color: '#005591' }}>*</span>
          </span>
        }
        required={false}
      >
        {getFieldDecorator('companypostcode', {
          initialValue: basex.companypostcode,
          rules: [
            {
              required: true,
              message: `${props.intl.formatMessage({
                id: 'groups'
              })} ${props.intl.formatMessage({
                id: 'is required'
              })}`
            }
          ]
        })(
          <Input
            onChange={e => updateBaseX('companypostcode', e.target.value)}
            placeholder={props.intl.formatMessage({
              id: 'groups'
            })}
          />
        )}
      </Form.Item>

      <Form.Item
        {...formItemLayout}
        label={
          <span>
            <FormattedMessage id='company location' />
            <span style={{ color: '#005591' }}>*</span>
          </span>
        }
        required={false}
      >
        {getFieldDecorator('companylocation', {
          initialValue: basex.companylocation,
          rules: [
            {
              required: true,
              message: `${props.intl.formatMessage({
                id: 'company location'
              })} ${props.intl.formatMessage({
                id: 'is required'
              })}`
            }
          ]
        })(
          <Input
            onChange={e => updateBaseX('companylocation', e.target.value)}
            placeholder={props.intl.formatMessage({
              id: 'company location'
            })}
          />
        )}
      </Form.Item>

      <Form.Item
        {...formItemLayout}
        label={
          <span>
            E-Mail<span style={{ color: '#005591' }}>*</span>
          </span>
        }
        required={false}
      >
        {getFieldDecorator('companyemail', {
          initialValue: basex.companyemail,
          rules: [
            {
              required: true,
              message: `E-Mail ${props.intl.formatMessage({
                id: 'is required'
              })}`
            },
            {
              type: 'email',
              message: props.intl.formatMessage({
                id: 'email is not valid'
              })
            }
          ]
        })(
          <Input
            onChange={e => updateBaseX('companyemail', e.target.value)}
            placeholder='E-Mail'
          />
        )}
      </Form.Item>

      <Form.Item
        {...formItemLayout}
        label={
          <span>
            <FormattedMessage id='phone' />
            <span style={{ color: '#005591' }}>*</span>
          </span>
        }
        required={false}
      >
        {getFieldDecorator('companyphone', {
          initialValue: basex.companyphone,
          rules: [
            {
              required: true,
              message: `${props.intl.formatMessage({
                id: 'phone'
              })} ${props.intl.formatMessage({
                id: 'is required'
              })}`
            }
          ]
        })(
          <Input
            onChange={e => updateBaseX('companyphone', e.target.value)}
            placeholder={props.intl.formatMessage({
              id: 'phone'
            })}
          />
        )}
      </Form.Item>

      <Form.Item
        {...formItemLayout}
        label={
          <span>
            <FormattedMessage id='object name' />
            <span style={{ color: '#005591' }}>*</span>
          </span>
        }
        required={false}
      >
        {getFieldDecorator('objectName', {
          initialValue: buildX.objectName,
          rules: [
            {
              required: true,
              message: `${props.intl.formatMessage({
                id: 'object name'
              })} ${props.intl.formatMessage({
                id: 'is required'
              })}`
            }
          ]
        })(
          <Input
            onChange={e => updateBuildX('objectName', e.target.value)}
            placeholder={props.intl.formatMessage({
              id: 'object name'
            })}
          />
        )}
      </Form.Item>

      <Form.Item
        {...formItemLayout}
        label={
          <span>
            <FormattedMessage id='recordings name' />
            <span style={{ color: '#005591' }}>*</span>
          </span>
        }
        required={false}
      >
        {getFieldDecorator('recordingsName', {
          initialValue: buildX.recordingsName,
          rules: [
            {
              required: true,
              message: `${props.intl.formatMessage({
                id: 'recordings name'
              })} ${props.intl.formatMessage({
                id: 'is required'
              })}`
            }
          ]
        })(
          <Input
            onChange={e => updateBuildX('recordingsName', e.target.value)}
            placeholder={props.intl.formatMessage({
              id: 'recordings name'
            })}
          />
        )}
      </Form.Item>
    </>
  )
}

BaseX.propTypes = {
  formItemLayout: PropTypes.object,
  basex: PropTypes.object.isRequired,
  buildX: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  timestamp: PropTypes.string.isRequired,
  logoUpload: PropTypes.func.isRequired,
  beforeUpload: PropTypes.func.isRequired,
  updateBaseX: PropTypes.func.isRequired,
  updateBuildX: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  uploading: PropTypes.bool
}

export default injectIntl(BaseX)
