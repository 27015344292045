import React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { Table, Icon, Button, Popconfirm, notification } from 'antd'
import EditableCell from './EditableCell'
import { connect } from 'react-redux'
import { updatePickedRecording } from '../../../../../../ducks/recordings'
import PropTypes from 'prop-types'

const EditableContext = React.createContext()

class SingleTable extends React.Component {
  state = {
    editingItem: '',
    newQuantity: ''
  }

  isEditing = record => {
    return (
      (record.product && record.product._id === this.state.editingItem) ||
      this.state.editingItem === record.id
    )
  }

  onEditingValueChange = (value, record) => {
    this.setState({
      newQuantity: value
    })
  }

  removeProductFromSection = product => {
    const { dispatch } = this.props

    const updatedArray = this.props.allRecordingProducts.filter(p => {
      return p.product._id !== product.product._id
    })
    dispatch(updatePickedRecording({ products: updatedArray }))
  }

  save = (form, product, key) => {
    const { dispatch, allRecordingProducts } = this.props
    const { newQuantity } = this.state

    if (product.product_id) {
      const findProductIndex = this.props.allRecordingProducts.findIndex(p => {
        return p._id === product.product_id
      })
      const newArr = [...allRecordingProducts]
      newArr[findProductIndex].extra_fields = newArr[
        findProductIndex
      ].extra_fields.map(field => {
        if (field.id === product.id) {
          field.value = newQuantity
        }
        return field
      })

      dispatch(updatePickedRecording({ products: newArr }))
      this.setState({ editingItem: '' })
    } else {
      const findProductIndex = allRecordingProducts.findIndex(p => {
        return p._id === product._id
      })
      const newArr = [...allRecordingProducts]
      newArr[findProductIndex].quantity = newQuantity

      if (Number(newQuantity) > 50 || Number(newQuantity) < 1) {
        notification.error({
          message: 'Only numbers 1 - 50'
        })
        return false
      }

      dispatch(updatePickedRecording({ products: newArr }))

      this.setState({ editingItem: '' })
    }
  }

  edit = item => {
    this.setState({
      editingItem: item.product ? item.product._id : item.id,
      newQuantity: item.product ? item.quantity : item.value
    })
  }

  cancel = record => {
    this.setState({
      editingItem: '',
      newQuantity: record.quantity
    })
  }

  expandedRowRender = rowData => {
    const components = {
      body: {
        cell: EditableCell
      }
    }
    const columns = [
      { title: 'Field Name', dataIndex: 'label', key: 'id' },
      {
        title: 'Value',
        dataIndex: 'value',
        key: 'value',
        render: record =>
          record.element === 'Dropdown' && record.options[record.value]
            ? record.options[record.value].text
            : record.value,
        onCell: record => ({
          record,
          inputType: record.element,
          dataIndex: 'value',
          title: 'value',
          editingValue: this.state.newQuantity,
          onEditingValueChange: this.onEditingValueChange,
          editing: this.isEditing(record)
        })
      },
      {
        key: 'actions',
        render: (text, record) => {
          const { editingItem } = this.state
          const editable = this.isEditing(record)
          return editable ? (
            <span>
              <EditableContext.Consumer>
                {form => (
                  <Button onClick={() => this.save(form, record)}>
                    <Icon type='save' />
                  </Button>
                )}
              </EditableContext.Consumer>
              <Button onClick={() => this.cancel(record)}>
                {this.props.intl.formatMessage({ id: 'cancel' })}
              </Button>
            </span>
          ) : (
            <div style={{ width: 150 }}>
              <Button
                onClick={() => this.edit(record)}
                disabled={this.props.readOnly || editingItem !== ''}
              >
                <Icon type='edit' />
              </Button>
            </div>
          )
        }
      }
    ]

    const data = rowData.extra_fields
    return (
      <Table
        columns={columns}
        // eslint-disable-next-line no-return-assign
        ref={ref => (this._table = ref)}
        components={components}
        dataSource={data}
        pagination={false}
      />
    )
  }

  render () {
    const components = {
      body: {
        cell: EditableCell
      }
    }

    const columns = [
      {
        title: this.props.intl.formatMessage({ id: 'name' }),
        dataIndex: 'product',
        key: 'name',
        render: product => product.name
      },
      {
        title: this.props.intl.formatMessage({ id: 'ordernumber' }),
        dataIndex: 'product',
        key: 'ordernumber',
        render: product => product.ordernumber
      },
      {
        title: this.props.intl.formatMessage({ id: 'quantity' }),
        dataIndex: 'quantity',
        key: 'quantity',
        onCell: record => ({
          record,
          inputType: 'number',
          dataIndex: 'quantity',
          title: 'quantity',
          editingValue: this.state.newQuantity,
          onEditingValueChange: this.onEditingValueChange,
          editing: this.isEditing(record)
        })
      },

      {
        key: 'actions',
        render: (text, record) => {
          const { editingItem } = this.state
          const editable = this.isEditing(record)
          return editable ? (
            <span>
              <EditableContext.Consumer>
                {form => (
                  <span
                    onClick={() => this.save(form, record)}
                    style={{
                      marginRight: 8,
                      color: '#005591',
                      backgroundColor: 'transparent',
                      outline: 'none',
                      cursor: 'pointer'
                    }}
                  >
                    {this.props.intl.formatMessage({ id: 'save' })}
                  </span>
                )}
              </EditableContext.Consumer>
              <span
                onClick={() => this.cancel(record)}
                style={{
                  marginRight: 8,
                  color: '#005591',
                  backgroundColor: 'transparent',
                  outline: 'none',
                  cursor: 'pointer'
                }}
              >
                {this.props.intl.formatMessage({ id: 'cancel' })}
              </span>
            </span>
          ) : (
            <div style={{ width: 150 }}>
              <Button
                onClick={() => this.edit(record)}
                disabled={this.props.readOnly || editingItem !== ''}
              >
                <Icon type='edit' />
              </Button>
              {this.props.readOnly ? (
                <Button disabled={this.props.readOnly}>
                  <FormattedMessage id='remove' />
                </Button>
              ) : (
                <Popconfirm
                  key='remove'
                  title={this.props.intl.formatMessage({
                    id: 'do you really want to remove this product?'
                  })}
                  onConfirm={() => this.removeProductFromSection(record)}
                  okText={this.props.intl.formatMessage({ id: 'yes' })}
                  cancelText={this.props.intl.formatMessage({ id: 'no' })}
                >
                  <Button disabled={this.props.readOnly}>
                    <FormattedMessage id='remove' />
                  </Button>
                </Popconfirm>
              )}
            </div>
          )
        }
      }
    ]

    return (
      <EditableContext.Provider value={this.props.form}>
        <Table
          // eslint-disable-next-line no-return-assign
          components={components}
          // eslint-disable-next-line no-return-assign
          ref={ref => (this._table = ref)}
          columns={columns}
          dataSource={this.props.products}
          rowKey={record => record._id}
          pagination={false}
          expandedRowRender={this.expandedRowRender}
        />
      </EditableContext.Provider>
    )
  }
}

SingleTable.propTypes = {
  intl: PropTypes.object,
  readOnly: PropTypes.bool,
  form: PropTypes.bool,
  allRecordingProducts: PropTypes.array,
  products: PropTypes.array,
  dispatch: PropTypes.func
}

export default injectIntl(connect()(SingleTable))
