import React from 'react'

import moment from 'moment'
import { connect } from 'react-redux'
import { injectIntl, FormattedMessage } from 'react-intl'
import { Helmet } from 'react-helmet'
import _ from 'lodash'

import {
  Table,
  Icon,
  Button,
  Modal,
  notification,
  Upload,
  Popconfirm,
  Input,
  Form
} from 'antd'

import ObjectForm from 'components/Object/Form'
import PageTitle from 'components/Global/PageTitle'
import objectService from 'services/object'
import locationService from 'services/location'
import projectService from 'services/project'
import DrawerComponent from 'components/Drawer'
import queryString from 'query-string'
import { setPickedProject } from '../../../ducks/project'
import PropTypes from 'prop-types'

export class ObjectsPage extends React.Component {
  state = {
    objects: [],
    object: {},
    locations: [],
    projects: [],
    drawer: false,
    loading: false,
    formLoading: false,
    pagination: {
      defaultPageSize: 10,
      showSizeChanger: true,
      pageSizeOptions: ['10', '20', '30', '50', '100']
    },
    uploadModal: false,
    uploadFile: null,
    uploading: false,
    force: false,
    createdByFilters: [],
    activeFilters: [],
    search: {
      name: ''
    },
    readOnlyNewProject: false,
    params: {}
  }

  getParams = () => {
    const { history } = this.props
    this._isMounted = true
    const searchString = history.location.search
    let searchQuery
    if (searchString.length) {
      searchQuery = queryString.parse(searchString, { arrayFormat: 'bracket' })
      if (searchQuery.page) {
        searchQuery.offset = searchQuery.page
        delete searchQuery.page
      }
      if (!searchQuery.limit) {
        searchQuery.limit = 10
      }
      if (!searchQuery.sortField && !searchQuery.sortOrder) {
        searchQuery.sortField = 'createdAt'
        searchQuery.sortOrder = 'descend'
      }

      return searchQuery
    }
  }

  setParams = paramsObject => {
    const { path, history, selectedProject } = this.props
    if (selectedProject._id) {
      paramsObject.project = selectedProject._id
    }
    if (paramsObject.offset) {
      paramsObject.page = paramsObject.offset
      delete paramsObject.offset
    }
    if (paramsObject.limit === 10) {
      delete paramsObject.limit
    }
    if (
      (paramsObject.sortField === 'createdAt' &&
        paramsObject.sortOrder === 'descend') ||
      !paramsObject.sortOrder
    ) {
      delete paramsObject.sortField
      delete paramsObject.sortOrder
    }
    this.setState({ params: paramsObject })
    history.push({
      pathname: path,
      search: queryString.stringify(paramsObject, { arrayFormat: 'bracket' })
    })
  }

  componentDidMount () {
    this.fetchObjects()
  }

  componentDidUpdate (prevProps) {
    const {
      location: { search: prevSearch }
    } = prevProps
    const {
      location: { search: nextSearch },
      projects,
      selectedProject,
      dispatch
    } = this.props
    const params = { ...this.getParams() }

    if (params.project && !prevProps.projects.length && projects.length) {
      dispatch(
        setPickedProject(projects.find(item => item._id === params.project))
      )
    }

    if (selectedProject !== prevProps.selectedProject) {
      if (selectedProject._id) {
        this.setParams({ ...params, project: selectedProject._id })
      } else {
        delete params.project
        this.setParams(params)
      }
    }
    if (
      prevSearch !== nextSearch ||
      selectedProject !== prevProps.selectedProject
    ) {
      this.fetchObjects()
      this.fetchLocations()
    }
  }

  fetchProjects = async value => {
    this.setState({
      formLoading: true
    })
    const timer = setTimeout(async () => {
      try {
        const projects = await projectService.listV2({
          projectName: [value],
          limit: 100
        })

        this.setState({
          projects: projects.docs,
          formLoading: false
        })

        clearTimeout(timer)
      } catch (error) {
        this.setState({
          projects: [],
          formLoading: false
        })
        clearTimeout(timer)
      }
    })
  }

  fetchObjects = async () => {
    const params = this.getParams()

    this.setState({
      loading: true
    })

    const outputParams = { ...params }
    if (outputParams && outputParams.disabled) {
      outputParams.disabled = outputParams.disabled.map(
        item => item !== 'active'
      )
    }
    try {
      const pagination = { ...this.state.pagination }

      const objects = await objectService.listV2({
        limit: pagination.defaultPageSize,
        ...outputParams
      })

      pagination.total = objects.totalDocs

      this.setState({
        objects: objects.docs,
        loading: false,
        totalDocs: objects.totalDocs,
        createdByFilters: objects.filterOptions.createdBy,
        pagination: {
          ...pagination,
          pageSize: objects.limit,
          current: objects.page
        },
        params: { ...params }
      })
    } catch (error) {
      notification.error({
        message: `${this.props.intl.formatMessage({
          id: 'objects'
        })} - download error`
      })

      this.setState({
        objects: [],
        drawer: false,
        loading: false,
        object: {}
      })
    }
  }

  fetchLocations = async value => {
    this.setState({
      formLoading: true
    })
    const timer = setTimeout(async () => {
      try {
        const response = await locationService.listV2({
          name: [value],
          limit: 100
        })

        this.setState({
          locations: response.docs,
          formLoading: false
        })
        clearTimeout(timer)
      } catch (error) {
        console.log('Fetching error')
        this.setState({
          formLoading: false
        })
        clearTimeout(timer)
      }
    }, 1000)
  }

  setSelectedKeys = (dataIndex, value, setSelectedKeys) => {
    setSelectedKeys(value ? [value] : [])
    this.setState({
      [dataIndex + 'Search']: value
    })
  }

  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this[`searchInput${dataIndex}`] = node
          }}
          placeholder={this.props.intl.formatMessage({ id: 'search' })}
          value={
            this.state[dataIndex + 'Search'] !== undefined
              ? this.state[dataIndex + 'Search']
              : this.state.params[dataIndex]
          }
          onChange={e =>
            this.setSelectedKeys(dataIndex, e.target.value, setSelectedKeys)
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, dataIndex, confirm)
          }
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type='primary'
          onClick={() => this.handleSearch(selectedKeys, dataIndex, confirm)}
          icon='search'
          disabled={!this.state[dataIndex + 'Search']}
          size='small'
          style={{ width: 90, marginRight: 8 }}
        >
          <FormattedMessage id='search' />
        </Button>
        <Button
          onClick={() => this.handleReset(dataIndex, clearFilters)}
          size='small'
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon
        type='search'
        style={{
          color:
            this.state.params[dataIndex] || filtered ? '#1890ff' : undefined
        }}
      />
    ),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this[`searchInput${dataIndex}`].select())
      }
    }
  })

  handleSearch = (selectedKeys, field, confirm) => {
    confirm()
    this.setState({
      search: {
        ...this.state.search,
        [field]: selectedKeys[0]
      }
    })
  }

  handleReset = (field, clearFilters) => {
    clearFilters()
    const { params } = this.state
    const outputObject = { ...params }
    delete outputObject[field]
    this.setParams(outputObject)
    this.setState({
      [field + 'Search']: undefined
    })
  }

  showDrawer = object => {
    if (_.isEmpty(object, true)) {
      this.setState({
        drawer: true,
        object: {
          ...object,
          project: this.props.selectedProject
            ? this.props.selectedProject
            : null
        },
        readOnlyNewProject: !!this.props.selectedProject._id
      })
    } else {
      this.setState({
        drawer: true,
        object: {
          ...object
        },
        readOnlyNewProject: false
      })
    }
  }

  handleHideDrawer = () => {
    this.setState({
      drawer: false,
      object: {}
    })
  }

  handleSaveObject = async () => {
    this.props.form.validateFields(async err => {
      if (!err) {
        try {
          const savedObject = await objectService.save(this.state.object)

          this.fetchObjects()
          if (this.state.object._id) {
            notification.success({
              message: `${this.props.intl.formatMessage({
                id: 'updated successfully'
              })}`
            })
          } else {
            notification.success({
              message: `${this.props.intl.formatMessage({
                id: 'created successfully'
              })}`
            })
          }

          this.setState({
            drawer: false,
            object: {},
            objects: [
              ...this.state.objects.filter(
                object => object._id !== savedObject._id
              ),
              savedObject
            ]
          })
        } catch (error) {
          notification.error({
            message: this.props.intl.formatMessage({
              id: 'saving error'
            }),
            description: error.error
          })
        }
      }
    })
  }

  updateObject = (field, value) => {
    let name = this.state.object.name

    if (field === 'name') {
      name = value
    }

    this.props.form.setFieldsValue({
      name
    })

    this.setState(state => ({
      object: {
        ...state.object,
        [field]: value || null
      }
    }))
  }

  showUploadModal = () => {
    this.setState({
      uploadModal: true
    })
  }

  handleHideUploadModal = () => {
    this.setState({
      uploadModal: false,
      uploadFile: null,
      uploading: false,
      force: false
    })
  }

  beforeUpload = file => {
    // const isCSV = ['text/csv', 'text/plain'].includes(file.type)
    //
    // if (!isCSV) {
    //   notification.error({
    //     message: 'You can only upload CSV file '
    //   })
    // }

    const isLt2M = file.size / 1024 / 1024 < 2
    if (!isLt2M) {
      notification.error({
        message:
          this.props.intl.formatMessage({
            id: 'image must smaller than'
          }) + ' 2MB'
      })
    }

    if (isLt2M) {
      this.setState({
        uploadFile: file
      })
    }

    return false
  }

  handleCSVUpload = async () => {
    if (!this.state.uploadFile || this.state.uploading) {
      return false
    }

    this.setState({
      uploading: true
    })

    try {
      const uploadReasponse = await objectService.uploadCSV(
        this.state.uploadFile,
        this.state.force
      )

      if (uploadReasponse.success) {
        notification.success({
          message: this.props.intl.formatMessage({
            id: 'file has been imported successfully'
          })
        })

        await this.fetchObjects()

        this.handleHideUploadModal()
      } else {
        this.setState({
          force: true
        })

        notification.error({
          message: uploadReasponse.message
        })
      }
    } catch (error) {
      notification.error({
        message: this.props.intl.formatMessage({
          id: 'uploading error'
        })
      })
    }

    this.setState({
      uploading: false
    })
  }

  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination }
    pager.offset = pagination.current
    const params = {
      limit: pagination.pageSize,
      offset: pagination.current,
      sortField: sorter.field,
      sortOrder: sorter.order,
      ...filters
    }
    this.setParams(params)
  }

  onLimitChange = limit => {
    this.setState(
      {
        pageSize: limit
      },
      () => {
        this.fetchObjects()
      }
    )
  }

  render () {
    const columns = [
      {
        title: 'Name',
        key: 'name',
        dataIndex: 'name',
        // defaultSortOrder: 'ascend',
        sorter: true,
        ...this.getColumnSearchProps('name')
      },
      {
        title: this.props.intl.formatMessage({ id: 'created at' }),
        key: 'createdAt',
        dataIndex: 'createdAt',
        render: createdAt => moment(createdAt).format('DD.MM.YYYY, HH:mm'),
        sorter: true,
        defaultSortOrder:
          this.state.params.sortField === 'createdAt'
            ? this.state.params.sortOrder
            : 'descend'
      },
      {
        title: this.props.intl.formatMessage({ id: 'created by' }),
        key: 'createdBy',
        dataIndex: 'createdBy',
        render: createdBy =>
          createdBy ? `${createdBy.firstName} ${createdBy.lastName}` : '',
        filters: this.state.createdByFilters.map(user => ({
          text: `${user.firstName} ${user.lastName}`,
          value: user._id
        })),
        filteredValue: this.state.params.createdBy
      },
      {
        title: this.props.intl.formatMessage({ id: 'active' }),
        key: 'disabled',
        dataIndex: 'disabled',
        align: 'center',
        render: disabled => (
          <>{!disabled && <Icon type='check' style={{ color: '#005591' }} />}</>
        ),
        filters: [
          {
            text: this.props.intl.formatMessage({ id: 'active' }),
            value: 'active'
          },
          {
            text: this.props.intl.formatMessage({ id: 'inactive' }),
            value: 'inactive'
          }
        ],
        onFilter: (value, record) => {
          if (value === 'active') {
            return !record.disabled
          } else if (value === 'inactive') {
            return !!record.disabled
          }

          return false
        },
        filteredValue: this.state.params.disabled
      }
    ]

    if (!this.props.selectedProject) {
      columns.push({
        title: this.props.intl.formatMessage({ id: 'project' }),
        key: 'project',
        dataIndex: 'project.projectName'
      })
    }

    columns.push({
      key: 'actions',
      render: (text, record) => (
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'flex-end'
          }}
        >
          <Button onClick={() => this.showDrawer(record)}>
            <Icon type='edit' />
          </Button>
        </div>
      )
    })

    const drawerButtons = [
      <Button
        key={637343432}
        onClick={this.handleHideDrawer}
        style={{ marginRight: 8 }}
      >
        <FormattedMessage id='cancel' />
      </Button>,
      <Button key={843262447} onClick={this.handleSaveObject} type='primary'>
        {this.props.intl.formatMessage({ id: 'save' })}
      </Button>
    ]

    const {
      objects,
      // currentPage,
      // pageSize,
      // totalDocs,
      object,
      projects,
      drawer,
      locations,
      uploadModal,
      uploading,
      uploadFile,
      loading,
      formLoading,
      readOnlyNewProject
    } = this.state

    const headerButtons = [
      <Button
        key={8473426652547}
        type='primary'
        onClick={() => this.showUploadModal({})}
      >
        <FormattedMessage id='csv import' />
      </Button>,
      <Button
        key={3256213536742674}
        type='primary'
        style={{ marginLeft: 8 }}
        onClick={() => this.showDrawer({})}
      >
        <FormattedMessage id='create object' />
      </Button>
    ]

    return (
      <div>
        <FormattedMessage id='head.title.objects'>
          {title => (
            <Helmet>
              <title>{title}</title>
            </Helmet>
          )}
        </FormattedMessage>

        <PageTitle
          title={this.props.intl.formatMessage({ id: 'objects' })}
          buttons={headerButtons}
        />

        <Table
          loading={loading}
          columns={columns}
          dataSource={objects}
          rowKey={record => record._id}
          onChange={this.handleTableChange}
          onRow={record => ({
            onDoubleClick: () => {
              this.showDrawer(record)
            }
          })}
          defaultPageSize={this.state.defaultPageSize}
          pagination={this.state.pagination}
        />

        <DrawerComponent
          title={
            object._id
              ? object.name
              : this.props.intl.formatMessage({ id: 'create object' })
          }
          onClose={this.handleHideDrawer}
          visible={!!drawer}
          footerButtons={drawerButtons}
        >
          <ObjectForm
            object={object}
            projects={projects}
            formLoading={formLoading}
            readOnlyNewProject={readOnlyNewProject}
            fetchProjects={this.fetchProjects}
            fetchLocations={this.fetchLocations}
            updateObject={this.updateObject}
            locations={locations}
            objectName={
              (this.props.settings.data.buildx &&
                this.props.settings.data.buildx.objectName) ||
              ''
            }
            form={this.props.form}
          />
        </DrawerComponent>

        <Modal
          title={this.props.intl.formatMessage({ id: 'upload csv' })}
          visible={!!uploadModal}
          onCancel={this.handleHideUploadModal}
          footer={
            this.state.force ? (
              <Popconfirm
                title={this.props.intl.formatMessage({
                  id: 'confirm overwriting'
                })}
                onConfirm={this.handleCSVUpload}
                okText={this.props.intl.formatMessage({
                  id: 'overwrite'
                })}
                cancelText={this.props.intl.formatMessage({
                  id: 'cancel'
                })}
              >
                <Button
                  type='danger'
                  disabled={uploading || !uploadFile}
                  loading={uploading}
                >
                  Force Import
                  <Icon type='upload' />
                </Button>
              </Popconfirm>
            ) : (
              <Button
                type='primary'
                disabled={uploading || !uploadFile}
                loading={uploading}
                onClick={this.handleCSVUpload}
              >
                Import
                <Icon type='upload' />
              </Button>
            )
          }
          destroyOnClose
        >
          <Upload
            beforeUpload={this.beforeUpload}
            fileList={uploadFile ? [uploadFile] : []}
            onRemove={() => this.setState({ uploadFile: null })}
            destroyOnClose
          >
            <Button>Select CSV file</Button>
          </Upload>
        </Modal>
      </div>
    )
  }
}

ObjectsPage.propTypes = {
  dispatch: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  path: PropTypes.string.isRequired,
  projects: PropTypes.array.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  selectedProject: PropTypes.object,
  settings: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  settings: state.settings,
  projects: state.project.projectList,
  selectedProject: state.project.pickedProject
})

export default injectIntl(
  Form.create({ name: 'object_form' })(connect(mapStateToProps)(ObjectsPage))
)
