import React from 'react'
import { Layout, Row, Col } from 'antd'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'

import LangPicker from '../../components/LanguagePicker'

const StyledLangPicker = styled(LangPicker)`
  position: absolute;
  top: 10px;
  right: 15px;
`

const Wrapper = styled.div`
  background: #005591;
  width: 100vw;
  height: 100vh;
  background-image: url('/images/hero_standard.jpg');
  background-size: cover;
  background-repeat: no-repeat;
`

const Card = styled.div`
  width: 400px;
  background: white;
  box-shadow: rgba(12, 52, 75, 0.05) 0px 3px 3px;
  padding: 30px;
  text-align: center;
  padding: 50px;
  height: 100vh;
  padding-top: 100px;
`

const Logo = styled.div`
  padding-bottom: 50px;
`

const LoginLayout = props => (
  <Layout style={{ minHeight: '100vh' }}>
    <Helmet titleTemplate='%s | ddSuite'>
      <title>App</title>
    </Helmet>

    <Layout.Content>
      <Wrapper>
        <Row type='flex' style={{ minHeight: '100vh' }}>
          <Col>
            <StyledLangPicker />
            <Card className='login'>
              <Logo>
                <img src='images/logo.png' alt='logo' width='120' />
              </Logo>
              {props.children}
            </Card>
          </Col>
        </Row>
      </Wrapper>
    </Layout.Content>
  </Layout>
)

LoginLayout.propTypes = {
  children: PropTypes.element.isRequired
}

export default LoginLayout
