import { createReducer } from 'redux-act'
// import { createAction, createReducer } from 'redux-act'
import { notification } from 'antd'
import { FormsAPI } from '../services'

export const REDUCER = 'forms'
// const NS = `@@${REDUCER}/`

// export const setStats = createAction(`${NS}SET_STATS`)

const FormsApi = new FormsAPI()

export const initialState = {
  data: {}
}

export const fetchSingleForm = id => (dispatch, getState) => {
  return FormsApi.get(id)
    .then(response => {
      return response
    })
    .catch(error => {
      notification.open({
        type: 'error',
        message: error.message
      })
    })
}

export default createReducer(
  {
    // [setStats]: (state, data) => ({ ...state, data }),
  },
  initialState
)
