import React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { Button, notification } from 'antd'
import PropTypes from 'prop-types'

import {
  setRecordingDefectCreationData,
  createRecordingDefect
} from '../../ducks/recordings'

import RecordingPreview from './RecordingPreview'
import DrawerComponent from 'components/Drawer'

import DefectForm from './Form'

export class ModalDefectCreation extends React.PureComponent {
  state = {
    formValue: {},
    errors: {},
    confirmLoading: false
  }

  handleNextRecord = () => {
    const { dispatch, defectData } = this.props
    const { formValue } = this.state

    const currentIndex = defectData.step.index
    if (currentIndex + 1 < defectData.step.total) {
      const incomeData = { ...defectData }
      const stateValue = formValue
      const propsValue = defectData.income

      const outputData = { ...incomeData.list[currentIndex + 1] }

      if (stateValue.protocolRefExt || propsValue.protocolRefExt) {
        outputData.protocolRefExt =
          stateValue.protocolRefExt || propsValue.protocolRefExt
      }
      if (stateValue.protocolResponsible || propsValue.protocolResponsible) {
        outputData.protocolResponsible =
          stateValue.protocolResponsible || propsValue.protocolResponsible
      }
      if (stateValue.assignedTo || propsValue.assignedTo) {
        outputData.assignedTo = stateValue.assignedTo || propsValue.assignedTo
      }

      if (stateValue.contractSection || propsValue.contractSection) {
        outputData.contractSection =
          stateValue.contractSection || propsValue.contractSection
      }

      const actualSituation =
        (outputData.images &&
          outputData.images.map(image => image.comment).join('; ')) ||
        ''

      dispatch(
        setRecordingDefectCreationData({
          ...incomeData,
          income: { ...outputData, actualSituation },
          step: { ...defectData.step, index: currentIndex + 1 }
        })
      )
      this.setState({ formValue: {} })
    } else {
      dispatch(setRecordingDefectCreationData({}))
    }
  }

  handleSaveDefect = () => {
    const {
      dispatch,
      defectData: { income },
      defectData
    } = this.props
    const { formValue } = this.state
    const payload = { ...formValue }

    this.setState({
      confirmLoading: true
    })

    if (
      !payload.name ||
      !(payload.name && payload.name.length > 0) ||
      (!payload.objectId && !income.objectId)
    ) {
      this.setState({
        errors: {
          name: !payload.name || payload.name.length === 0,
          objectId:
            !(payload.objectId && payload.objectId.length === 0) &&
            !income.objectId
        },
        confirmLoading: false
      })
      return
    }
    dispatch(createRecordingDefect(payload, formValue)).then(response => {
      this.setState({
        confirmLoading: false
      })
      if (!response.error) {
        notification.success({
          message: `${response.docNumber} ${this.props.intl.formatMessage({
            id: 'created successfully'
          })}`
        })
        if (defectData.type !== 'acceptance') {
          setTimeout(() => dispatch(setRecordingDefectCreationData({})), 5000)
        } else {
          this.handleNextRecord()
        }
      } else {
        notification.error({
          message: response.error
        })
      }
    })
  }

  updateStateDefect = (key, value) => {
    this.setState({
      formValue: {
        ...this.state.formValue,
        [key]: value
      },
      errors: {}
    })
  }

  handleClose = () => {
    const { dispatch } = this.props
    dispatch(setRecordingDefectCreationData({}))
    this.setState({
      formValue: {},
      errors: {},
      confirmLoading: false
    })
  }

  render () {
    const { defectData, intl } = this.props
    const { errors, confirmLoading, formValue } = this.state
    return (
      <DrawerComponent
        title={`${intl.formatMessage({
          id: 'defect creation'
        })} ${
          defectData.step
            ? defectData.step.index + 1 + '/' + defectData.step.total
            : ''
        }`}
        onClose={this.handleClose}
        onCancel={this.handleClose}
        visible={Object.keys(defectData).length > 0}
        hideFooter={defectData.type === 'acceptance'}
        footerButtons={
          <>
            <Button onClick={this.handleClose}>
              <FormattedMessage id='cancel' />
            </Button>
            {defectData.type === 'acceptance' && (
              <Button onClick={this.handleNextRecord}>
                <FormattedMessage id='skip' />
              </Button>
            )}

            <Button
              key='ok'
              type='primary'
              onClick={this.handleSaveDefect}
              loading={confirmLoading}
            >
              {intl.formatMessage({
                id: 'create defect'
              })}
            </Button>
          </>
        }
      >
        <p>{intl.formatMessage({ id: 'completeInfoBelow' })}</p>
        {defectData.income &&
          defectData.income.images &&
          defectData.income.images.length > 0 && (
            <RecordingPreview recording={defectData.income} />
          )}

        <DefectForm
          data={defectData.income || {}}
          updateStateDefect={this.updateStateDefect}
          errors={errors}
          formValue={formValue}
        />
      </DrawerComponent>
    )
  }
}

ModalDefectCreation.propTypes = {
  dispatch: PropTypes.func.isRequired,
  defectData: PropTypes.object,
  intl: PropTypes.object.isRequired
}

const mapStateToProps = state => {
  return {
    defectData: state.recordings.recordingDefectCreationData
  }
}

export default injectIntl(connect(mapStateToProps)(ModalDefectCreation))
