import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage, injectIntl } from 'react-intl'
import { ContactsListItem } from './ContactsListItem'
import './styles.scss'
import { AutoComplete, Button, Dropdown, Icon, Menu, Select, Spin } from 'antd'
import debounce from 'lodash/debounce'
import contactService from '../../../../services/contact'

const { Option } = Select

class ContactsTab extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      loading: false,
      list: [],
      personLinkingShown: false,
      pickedContact: {}
    }
    this.handleInputThrottled = debounce(this.fetchContactsOnSearch, 300)
  }

  handleLinkPerson = () => {
    this.setState({
      personLinkingShown: true
    })
  }

  fetchContactsOnSearch = async searchQueue => {
    this.setState({
      loading: true
    })
    const contacts = await contactService.listV2({ 'company[]': searchQueue })
    this.setState({
      loading: false,
      list: contacts.docs.filter(item => !item.company)
    })
  }

  onPick = pickedContact => {
    this.setState({
      pickedContact
    })
  }

  handleSubmitContact = () => {
    const { updateContact, contactsList } = this.props
    const { pickedContact } = this.state

    updateContact('contacts_sub', [
      ...contactsList.filter(item => item._id !== pickedContact._id),
      pickedContact
    ])
    this.setState({
      personLinkingShown: false,
      pickedContact: {}
    })
  }

  onRemoveContact = contact => {
    const { updateContact, contactsList } = this.props

    updateContact(
      'contacts_sub',
      contactsList.filter(item => item._id !== contact._id)
    )
  }

  render () {
    const { contactsList, handleCreateContact } = this.props
    const { loading, personLinkingShown, list, pickedContact } = this.state

    const menu = (
      <Menu>
        <Menu.Item onClick={handleCreateContact}>
          <Icon type='tool' />
          &nbsp;
          <FormattedMessage id='new person' />
        </Menu.Item>
        <Menu.Item onClick={this.handleLinkPerson}>
          <Icon type='export' />
          &nbsp;
          <FormattedMessage id='link to person' />
        </Menu.Item>
      </Menu>
    )
    return (
      <div className='contact-list-cover'>
        {(contactsList || []).map(item => (
          <ContactsListItem
            key={item._id}
            handleRemove={this.onRemoveContact}
            contactData={item}
          />
        ))}
        <Dropdown
          className='add-contacts-action-button'
          overlay={menu}
          key='actions-dropdown'
        >
          <Button>
            <FormattedMessage id='add person' />
            <Icon type='down' style={{ marginLeft: 16 }} />
          </Button>
        </Dropdown>
        {personLinkingShown && (
          <div className='contact-search-cover'>
            <AutoComplete
              allowClear
              dropdownMatchSelectWidth={false}
              dropdownStyle={{ width: 188 }}
              style={{ display: 'block', flex: 1 }}
              placeholder='Select Contact'
              initialValue='Select Contact'
              optionFilterProp='children'
              notFoundContent={
                loading ? (
                  <div className='recordings-spinner-container'>
                    <Spin size='small' />
                  </div>
                ) : null
              }
              onFocus={() => this.fetchContactsOnSearch('')}
              onSearch={this.handleInputThrottled}
              onSelect={(value, e) => this.onPick(e.props.data || '')}
            >
              {list.map(object => (
                <Option key={object._id} data={object} value={object._id}>
                  {object.company
                    ? object.company
                    : `${object.firstName || ''} ${object.lastName || ''}`}
                </Option>
              ))}
            </AutoComplete>

            <div className='contact-add-buttons-block'>
              <Button
                className='action-button'
                disabled={!pickedContact._id}
                onClick={this.handleSubmitContact}
              >
                <Icon type='check' className='ok-button' />
              </Button>
              <Button
                className='action-button'
                onClick={() =>
                  this.setState({
                    personLinkingShown: false,
                    pickedContact: {}
                  })
                }
              >
                <Icon type='close' className='close-button' />
              </Button>
            </div>
          </div>
        )}
      </div>
    )
  }
}

ContactsTab.propTypes = {
  contactsList: PropTypes.array,
  updateContact: PropTypes.func.isRequired,
  handleCreateContact: PropTypes.func.isRequired
}

export default injectIntl(ContactsTab)
